import * as React from 'react';
import { Field } from 'formik';

import { Input, TextArea } from 'src/components/fields';

export const BillingInfoFields = () => {
  return (
    <>
      <Field name="first_name" label="First Name" component={Input}/>
      <Field name="last_name" label="Last Name" component={Input}/>
      <Field name="telephone" label="Phone number" component={Input} inputType='phone'/>
      <Field name="email" label="Email" component={Input}/>
      <Field name="address" label="Address" style={{ minHeight: '20px', maxHeight: '110px', resize: 'none' }} className="full-size" component={TextArea}/>
    </>
  )
};
