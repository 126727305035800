import { getWidthPercents, getSecondaryColor, getPrimaryColor } from '../../utils';

export const Button = (text: string, link: string) => (index: number) => `
  <mj-column width="${getWidthPercents(index)}" padding="4px 0">
      <mj-button align="left" background-color="${getPrimaryColor()}" color="${getSecondaryColor()}" height="20px" color="#fff" border-radius="24px"
                 font-size="12px"
                 href="${link}"
                 font-weight="bold"
                 padding="0"
                 inner-padding="8px 16px"
                 padding-left="0"
                 padding-top="0"
                 text-align="left"
                 >
          ${text}
      </mj-button>
  </mj-column>
`;
