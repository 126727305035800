import * as React from 'react';
import { Form, Formik } from 'formik';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';
import { Button } from 'src/components/button';
import { useAppSelector } from 'src/stores';
import { AnyObject } from 'src/types';

import {
  BREADCRUMBS_MY_DETAILS,
  MY_DETAILS_VALIDATION,
  ON_SUBMIT_MY_DETAILS,
  MOUNT_MY_DETAILS
} from '../../constants';
import { DetailsFields } from '../../components';
import { mountMyDetails } from '../../services';
import { onSubmitMyDetails } from '../../handlers';

export const MyDetails = () => {
  const userData = useAppSelector(state => state.auth.user?.attributes as any) || {} as AnyObject;

  const initialValues = {
    first_name: userData.first_name || '',
    last_name: userData.last_name || '',
    email: userData.email || ''
  };

  React.useEffect(() => {
    mountMyDetails();
  }, []);

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="My details" to={ROUTES.settings} list={BREADCRUMBS_MY_DETAILS}/>
      <div className="page-content-body">
        <h1>My details</h1>
        <Loader id={MOUNT_MY_DETAILS}>
          <Formik
            initialValues={initialValues}
            validationSchema={MY_DETAILS_VALIDATION}
            enableReinitialize
            onSubmit={onSubmitMyDetails}
          >
            <Form>
              <DetailsFields />
              <div className="button-block full-size">
                <Button
                  loaderId={ON_SUBMIT_MY_DETAILS}
                  htmlType="submit"
                  className="green-button"
                >
                  Save changes
                </Button>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  )
};
