import { store } from "src/stores";
import { AnyObject } from 'src/types';
import { SERVER_URL } from 'src/constants';
import {getPrimaryTextColor} from '../utils';

export const MailFooter = (args: AnyObject) => {

  const { footerContent } = store.getState().editor.newsletterDefault;

  const {
    logo,
    website,
    telephone,
    email,
    school,
    name
  } = args;

  const { primary_color } = store.getState().auth.school;
  const textColor = getPrimaryTextColor();

  return `
  <mj-section padding="12px 20px 20px" width="100%" background-color="#fff">
    <mj-section style="margin-top: 20px" padding-left="15px" padding-top="15px" padding-top="12px" padding-bottom="16px" padding-right="15px" text-align="center" width="100%" text-align="center" border-radius="6px" background-color="${primary_color ? primary_color : '#34c3ee' }" >
    <mj-raw>
        <p text-align="center" style="text-decoration: none; font-size: 14px; color: ${textColor ? textColor : 'white'}; font-family: Ubuntu, sans-serif; font-weight: bold">${footerContent ? footerContent : ''}</p>
        <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap; flex-direction: column; align-items: flex-start;">
        ${website?.length ?
            `<mj-text padding-left="15px" align="left" vertical-align="center" padding-top="4px" width="30%" class="footer-links">
                <img style="width: 20px; height: 21px; padding-top:2px; padding-bottom: 2px; padding-right: 6px;"
                    src="${SERVER_URL}/icons/pionter_white.png"/>
                <a href="${website}"
                style="text-decoration: none; font-size: 14px; color: ${textColor ? textColor : 'white'}; font-family: Ubuntu, sans-serif; font-weight: bold; vertical-align: middle;"
                target="_blank">${website?.length ? website : ''}
                </a>
            </mj-text>` : ''}
            <mj-text padding-left="15px" align="left" vertical-align="center" padding-top="4px" width="30%" class="footer-links">
                <img style="width: 20px; height: 21px; padding-top:2px; padding-bottom: 2px; padding-right: 6px;"
                    src="${SERVER_URL}/icons/email_white.png"/>
                <a href="mailto:${email}"
                style="text-decoration: none; font-size: 14px; color: ${textColor ? textColor : 'white'}; font-family: Ubuntu, sans-serif; font-weight: bold; vertical-align: middle;">
                    ${email?.length ? email : ''}
                </a>
            </mj-text>
            ${telephone?.length ?
            `<mj-text padding-left="15px" align="left" vertical-align="center" padding-top="4px" width="30%" class="footer-links">
                <img style="width: 20px; height: 21px; padding-top:2px; padding-bottom: 2px; padding-right: 6px;"
                    src="${SERVER_URL}/icons/phone_white.png"/>
                <a href="tel: ${telephone}"
                style="text-decoration: none; font-size: 14px; color: ${textColor ? textColor : 'white'}; font-family: Ubuntu, sans-serif; font-weight: bold; vertical-align: middle;">
                    ${telephone?.length ? telephone : ''}
                </a>
            </mj-text>` : ''}
            </div>
        </mj-raw>
        </mj-section>
    </mj-section>`;
}