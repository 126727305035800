export const AVAILABLE_CURRENCY = [{
  id: 'gbp',
  value: 'GBP'
}, {
  id: 'usd',
  value: 'USD'
}];

export const CURRENCY_SYMBOLS = {
  gbp: '£',
  usd: '$'
};
