import axios from 'axios';

import {
  API_BILLING_INFO_UPDATE,
  API_BILLING_INFORMATION,
  ROUTES,
  API_CREATE_SUBSCRIPTION,
  API_CANCEL_SUBSCRIPTION
} from 'src/constants';
import { requestWrapper, loadingWrapper, history, toast } from 'src/utils';
import { store } from 'src/stores';
import { getSchool } from 'src/services';

import { ON_SUBMIT_SCHOOL_SETTINGS, GET_BILLING_INFO, ON_CHOOSE_PLAN } from '../constants';
import { BillingInfoTypeBE } from '../types';
import { subscriptionActions } from '../reducers';

const updateBillingInfoRequest = requestWrapper((id: number, data: BillingInfoTypeBE) => {
  return axios.patch(`${API_BILLING_INFO_UPDATE}/${id}`, data);
});

const getBillingInfoRequest = requestWrapper(() => {
  return axios.get(API_BILLING_INFORMATION);
});

const createSubscriptionRequest = requestWrapper((plan: string) => {
  return axios.post(API_CREATE_SUBSCRIPTION, {
    subscription_plan: plan
  });
})

const cancelSubscriptionRequest = requestWrapper(() => {
  return axios.get(API_CANCEL_SUBSCRIPTION);
});

export const updateBillingInfo = loadingWrapper(
  ON_SUBMIT_SCHOOL_SETTINGS,
  async (data: BillingInfoTypeBE) => {
    const id = store.getState().subscription.billingInfo.id;
    const { success } = await updateBillingInfoRequest(id, data);

    return success;
  }
);

export const getBillingInfo = loadingWrapper(
  GET_BILLING_INFO,
  async () => {
    const { success, response } = await getBillingInfoRequest();

    if (success) {
      const {billing_information} = response.data;
      const billingInformation = billing_information.data.attributes;
      store.dispatch(subscriptionActions.setBillingInfo({
        ...billingInformation,
        first_name: billingInformation.first_name || '',
        last_name: billingInformation.last_name || '',
        email: billingInformation.email || '',
        telephone: billingInformation.telephone || '',
        address: billingInformation.address || '',
      }));
    }
  }
);

export const createSubscription = loadingWrapper(
  ON_CHOOSE_PLAN,
  async (plan: string) => {
    const { success, response } = await createSubscriptionRequest(plan);

    if (success) {
      store.dispatch(subscriptionActions.setClientSecret(response.data.payment_intent_client_secret));
      history.push(ROUTES.billingInfo);
    }
  }
);

export const cancelSubscription = async () => {
  const { success } = await cancelSubscriptionRequest();

  if (success) {
    // toast('Successfully canceled', 'success');
    // history.push(ROUTES.settings);
    return success
  } else {
    return false;
  }
};

export const mountBillingInfo = loadingWrapper(
  GET_BILLING_INFO,
  async () => {
    getBillingInfo();
  }
);
