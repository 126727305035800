import * as Yup from 'yup';

export const INVITE_USER_VALIDATION = Yup.object().shape({
  first_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  last_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  role: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  email: Yup.string().email('A valid email address is required').required('A valid email address is required')
});

export const MY_DETAILS_VALIDATION = Yup.object().shape({
  first_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  last_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  email: Yup.string().email('A valid email address is required').required('A valid email address is required')
});

export const PASSWORDS_VALIDATION = Yup.object().shape({
  existing_password: Yup.string().max(50, 'Password is to long').required('Field is required'),
  password: Yup.string().min(8, 'Password is too short').matches(
    /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase and one number").max(50, 'Password is to long').required('Field is required'),
  confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    .max(50, 'Password is to long').required('Field is required')
});

export const SCHOOL_SETTINGS_VALIDATION = Yup.object().shape({
  name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  phone: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  website: Yup.string().url('Invalid url format, should start with https:// or http://')
    .max(200, 'Limit is 200 symbols').required('Field is required'),
});

export const ONBOARDING_VALIDATION = Yup.object().shape({
  name: Yup.string().max(200, 'Limit is 200 symbols').required('Please enter the name of your school'),
  logo: Yup.string().required('Field is required'),
  email: Yup.string().email('A valid email address is required').required('A valid email address is required'),
  phone: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
});

export const BILLING_INFO_VALIDATION = Yup.object().shape({
  first_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  last_name: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  address: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  telephone: Yup.string().max(200, 'Limit is 200 symbols').required('Field is required'),
  email: Yup.string().email('A valid email address is required').required('A valid email address is required')
});

export const PAYMENT_VALIDATION = Yup.object().shape({
  card: Yup.string().max(8, 'Card contains only 8 numbers').required('Field is required'),
});
