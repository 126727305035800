import { createSlice } from '@reduxjs/toolkit';

import {
  changeSelectedItem,
  changeHeaderState,
  changeSlide,
  changeMobileHeaderState,
  toggleSidebar,
  setAuthType
} from '../actions';
import { HeaderType } from '../types';

const initialState = {
  selectedItem: null,
  simplifyHeader: false,
  simplifyMobileHeader: false,
  currentSlide: 1,
  showSidebar: false,
  showAuth: false,
  authType: 'login'
} as HeaderType;

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    changeSelectedItem,
    changeHeaderState,
    changeMobileHeaderState,
    changeSlide,
    toggleSidebar,
    setAuthType
  }
});

export const landingReducer = landingSlice.reducer;
export const landingActions = landingSlice.actions;
