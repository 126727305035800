import { TEMPLATE_TYPES } from 'src/constants';
import { AnyObject } from 'src/types';

export const CONTENT_ITEM_NAMES = [
  'Heading',
  'Text', // Uncommon parse case
  'Boxed text', // Uncommon parse case
  'Divider', // Uncommon parse case
  'Image',
  'Image group',
  'Social media',
  'Button',
  'Star of the week',
  'Attendance',
  'Video',
  'Download',
  'Event',
  'Footer',
  'Empty space'
];

export const CONTENT_ITEM_NAMES_WITHOUT_STAR = [
  'Heading',
  'Text', // Uncommon parse case
  'Boxed text', // Uncommon parse case
  'Divider', // Uncommon parse case
  'Image',
  'Image group',
  'Social media',
  'Button',
  'Attendance',
  'Video',
  'Download',
  'Event',
  'Footer',
  'Empty space'
];


// Change index everywhere CONTENT_ITEMS use!!!!!!!
export const CONTENT_ITEMS = CONTENT_ITEM_NAMES_WITHOUT_STAR.map(name => {
  const id = name.toLowerCase().split(' ').join('-');
  const img = require(`src/assets/img/editor/${id}@2x.png`).default;
  const displayName = name;

  return { name: displayName, id, img }
});

export const TEMPLATE_TYPES_CLASSES = {
  [TEMPLATE_TYPES.one_column]: 'one_column',
  [TEMPLATE_TYPES.two_column]: 'two_columns',
  [TEMPLATE_TYPES.two_columns_right]: 'two_columns_right',
  [TEMPLATE_TYPES.two_columns_left]: 'two_columns_left',
};
