import { createSlice } from '@reduxjs/toolkit';

import {
  setNewsletters,
  setControl,
} from '../actions';
import { NewsletterStore } from '../types';
import { FILTER_TYPES, ORDER_TYPES, DIRECTION_ORDER_TYPES, PAGINATION } from '../constants';

const initialState = {
  list: [],
  controls: {
    search_string: '',
    page: PAGINATION.page,
    per_page: PAGINATION.per_page,
    filter: FILTER_TYPES.all,
    order: ORDER_TYPES.created_at,
    direction: DIRECTION_ORDER_TYPES.desc
  },
} as NewsletterStore;

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {
    setNewsletters,
    setControl,
  }
});

export const newsletterReducer = newsletterSlice.reducer;
export const newsletterActions = newsletterSlice.actions;
