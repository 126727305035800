import * as React from 'react'
import {Link} from 'react-router-dom';

import {ROUTES} from 'src/constants';
import CookieIcon from 'src/assets/img/cookie.svg';

export const AppFooter = () => {
  const handleCookie = () => {
    localStorage.removeItem('cookieAgreement');
    window.location.reload();
  };

  return (
    <div className="layout-footer">
      <div onClick={handleCookie} className='cookie-icon'>
        <img src={CookieIcon} style={{width: '25px', height: '25px'}} />
      </div>
      <div className="info-wrap">
        <Link to={ROUTES.privacyPolicy}>Privacy Policy</Link>
        <Link to={ROUTES.terms}>Terms & Conditions</Link>
        <Link to={ROUTES.cookies}>Cookies Policy</Link>
      </div>
    </div>
  )
};
