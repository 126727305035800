import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import { ImageField, ColorPicker } from 'src/components/fields';
import { Button } from 'src/components/button';
import { onChangeStep } from '../handlers';

import { STEPPER_ITEMS, ON_SUBMIT_ON_BOARDING } from '../constants';

export const UploadLogo = () => {
  const currentStep = useAppSelector(state => state.onBoarding.currentStep);
  const { values } = useFormikContext<{ name: string }>();

  if (currentStep?.id !== STEPPER_ITEMS[1].id) return null;

  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">2</div>
        {STEPPER_ITEMS[1].question}
      </div>
      <Field
        name="logo"
        component={ImageField}
        aspect={32/9}
      />
      <div className='start-colors-container'>
        <Field name="primary_color" label="Primary colour" component={ColorPicker}/>
        <Field name="secondary_color" label="Secondary colour" component={ColorPicker}/>
      </div>

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="button"
        className="app-button green"
        disabled={!values.name}
        onClick={onChangeStep(STEPPER_ITEMS[2])}
      >
        Confirm
      </Button>
    </>
  )
};
