import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LandingPage } from 'src/modules/landing';
import { PublicRoute, PrivateRoute } from 'src/components/router';
import { ROUTES } from 'src/constants';

import { AppRoutes } from './AppRoutes';
import { Published } from 'src/modules/published';
import {NotFound} from 'src/modules/notFound';

export const Router = () => {
  return (
    <Switch>
      <PublicRoute exact path={ROUTES.published} component={Published} />
      <PrivateRoute path={ROUTES.app} component={AppRoutes} />
      <PublicRoute path={ROUTES.main} component={LandingPage} />
      {/* <PublicRoute exact component={LandingPage} /> */}
      <Route component={NotFound} />
    </Switch>
  )
};
