import * as React from 'react';
import { Tooltip } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import classNames from 'classnames';

import { ROUTES } from 'src/constants';
import { useAppSelector } from 'src/stores';

import { ADMIN_USERS_FILTER } from '../../constants';
import { onDeleteUser } from '../../handlers';

type Props = {
  fisrstname: string
  lastname: string,
  userId: string,
  type: string,
  role: string
};

export const UserItemControls = (props: Props) => {
  const activeFilter = useAppSelector(state => state.manageUsers.activeFilter);
  const { fisrstname, lastname, userId, type, role } = props;
  const [open, setOpen] = React.useState(false);

  const activeRoute = activeFilter === ADMIN_USERS_FILTER ? ROUTES.editUser : ROUTES.editSubs;
  const path = role === 'owner' ? ROUTES.myDetails : generatePath(activeRoute, { id: userId });

  const tooltipContent = (
    <div className="delete-tooltip-content">
      Are you sure you want to delete user
      <span className="bold-text">
        {fisrstname} {lastname}?
      </span>
      <div className="button-block">
        <button className="btn app-button red" onClick={onDeleteUser(Number(userId))}>
          Yes
        </button>
        <button className="btn app-button orange" onClick={() => setOpen(false)}>
          No
        </button>
      </div>
    </div>
  );

  return (
    <div className="button-block">
      <Link to={path} className={classNames('btn app-button orange', {
        disabled: type === 'invite'
      })}>
        Edit
      </Link>
      <Tooltip
        placement="topLeft"
        visible={open}
        trigger="click"
        title={tooltipContent}
        overlayClassName="delete-confirm"
        onVisibleChange={visible => setOpen(visible)}
      >
        <button className="btn app-button red" onClick={() => setOpen(true)}>
          Delete
        </button>
      </Tooltip>
    </div>
  );
};
