import { getSchool } from 'src/services';

import { FormValuesType, LetterValuesType } from '../types';
import { mapSchoolData, mapLetter } from '../utils';
import { createSchool, createNewsletter } from '../services';

export const onSubmit = async (values) => {
  const schoolData = mapSchoolData(values);
  await createSchool(schoolData);
  await getSchool();
};

export const onCreateLetter = (values: LetterValuesType) => {
  const data = mapLetter(values);
  createNewsletter(data)
};
