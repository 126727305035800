import _ from 'lodash';

import { getSchool } from 'src/services';

import { mapSchoolData } from '../utils';
import { createSchool } from '../services';

export const onSubmitMyDetails = async (values, callback) => {
  const schoolData = mapSchoolData(values);
  await createSchool(schoolData);
  await getSchool();
};
