import axios from 'axios';
import { generatePath } from 'react-router-dom';
import { serialize } from 'object-to-formdata';

import { store } from 'src/stores';
import { API_SCHOOL, API_MY_SCHOOL, ROUTES } from 'src/constants';
import { history, loadingWrapper, requestWrapper, toast } from 'src/utils';
import { getSchool as globalGetSchool } from 'src/services';

import { MOUNT_SCHOOL_SETTINGS, ON_SUBMIT_SCHOOL_SETTINGS } from '../constants';
import { schoolActions } from '../reducers';
import { SchoolSettingsBEForm } from '../types';
import { mapFromSchool } from '../utils';

const getSchoolRequest = requestWrapper(() => {
  return axios.get(API_MY_SCHOOL);
});

const getSchool = async () => {
  const { success, response } = await getSchoolRequest();

  if (success) {
    const data = mapFromSchool(response.data.school.data.attributes);
    store.dispatch(schoolActions.setSchoolData(data as any));
  }
};

const changeSchoolSettingsRequest = requestWrapper((id: string, payload: SchoolSettingsBEForm) => {
  const path = generatePath(API_SCHOOL, { id });
  const data = serialize(payload);

  return axios.patch(path, data, {
    headers: {
      'Content-Type': `multipart/form-data;`
    }
  });
});

export const mountSchoolSettings = loadingWrapper(
  MOUNT_SCHOOL_SETTINGS,
  async () => {
    await getSchool();

    return true;
  });

export const changeSchoolSettings = loadingWrapper(
  ON_SUBMIT_SCHOOL_SETTINGS,
  async (values: SchoolSettingsBEForm) => {
    const id = store.getState().school.school.id;
    const { success } = await changeSchoolSettingsRequest(id, values);

    if (success) {
      toast('School successfully updated', 'success');
      globalGetSchool();
      history.push(ROUTES.settings);
    }

    return success
  }
);
