import { matchPath } from 'react-router-dom';

import { loadingWrapper, history } from 'src/utils';
import { ROUTES } from 'src/constants';

import { ON_MOUNT_EDITOR } from '../constants';
import { getNewsletter } from '../services';

export const mount = loadingWrapper(
  ON_MOUNT_EDITOR,
  async () => {
    const { params } = matchPath(history.location.pathname, ROUTES.editor)!;

    await getNewsletter((params as any).id);
  }
);
