import * as React from 'react';

import GrayBlob from 'src/assets/img/pricing/gray-blob.svg';
import BlueBlob from 'src/assets/img/pricing/blue-blob.svg';
import GreenBlob from 'src/assets/img/pricing/green-blob.svg';
import OrangeBlob from 'src/assets/img/pricing/orange-blob.svg';

import GrayBlobMobile from 'src/assets/img/mobile/pricing-blob-bg.svg';

import { CURRENCY_SYMBOLS } from 'src/constants';
import { handleChoosePlan } from '../../handlers';
import { YEARLY_PLAN, MONTHLY_PLAN } from '../../constants';

export const PlansComponent = () => {
  const currencySymbol = CURRENCY_SYMBOLS.gbp;

  return (
    <div className="price-block">
      <div className="billing-info" onClick={handleChoosePlan(MONTHLY_PLAN)}>
        <div className="text-block">
          <div className="price">{currencySymbol}18</div>
          <div className="info">({currencySymbol}15 per month)</div>
          <div className="sub-title">Billed monthly</div>
        </div>

        <img src={BlueBlob} alt="BlueBlob"/>
      </div>
      <div className="billing-info right" onClick={handleChoosePlan(YEARLY_PLAN)}>
        <div className="text-block">
          <div className="price">{currencySymbol}180</div>
          <div className="info">({currencySymbol}15 per month)</div>
          <div className="sub-title">Billed annually</div>
        </div>

        <img src={GreenBlob} alt="GreenBlob"/>
      </div>

      <div className="discount-block">
        <div className="discount">
          <div>SAVE</div>
          <div className="percents">17%</div>
        </div>

        <img src={OrangeBlob} alt="OrangeBlob"/>
      </div>

      <img className="gray-blob hide-mobile" src={GrayBlob} alt="GrayBlob"/>
      <img className="gray-blob hide-desktop" src={GrayBlobMobile} alt="GrayBlobMobile"/>
    </div>
  );
};
