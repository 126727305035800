import * as React from 'react';
import { Layout } from 'antd';

import { Loader } from 'src/components/loader';
import { MOUNT_APP } from 'src/constants';

import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';
import { mount } from '../handlers';

type Props = {
  children?: React.ReactNode
}

export const AppLayout = (props: Props) => {
  const { children } = props;

  React.useEffect(() => {
    mount();
  }, []);

  return (
    <Loader id={MOUNT_APP}>
      <Layout className="layout">
        <AppHeader/>
        <Layout.Content>
          {children}
        </Layout.Content>
        <AppFooter />
      </Layout>
    </Loader>
  )
};
