export enum FILTER_TYPES {
  all = 'all',
  created = 'draft',
  published = 'published'
}

export enum ORDER_TYPES {
  created_at = 'updated_at',
  title = 'title'
}

export enum DIRECTION_ORDER_TYPES {
  asc = 'asc',
  desc = 'desc'
}

export const TYPE_TO_LABEL = {
  [FILTER_TYPES.all]: 'Show all',
  [FILTER_TYPES.created]: 'Show draft',
  [FILTER_TYPES.published]: 'Show published',
  [ORDER_TYPES.created_at]: 'date',
  [ORDER_TYPES.title]: 'title'
};

export type Pagination = {
  page: number;
  per_page: number;
  total_pages: number;
}

export const PAGINATION: Pagination = {
  page: 1,
  per_page: 10,
  total_pages: 1,
}
