import * as React from 'react';
import classNames from 'classnames';

import FilterIcon from 'src/assets/img/filter-icon.png';
import ArrowIcon from 'src/assets/img/chevron@2x.png';
import { Show } from 'src/components/conditions';
import { useAppSelector } from 'src/stores';

import { FILTER_TYPES, ORDER_TYPES, TYPE_TO_LABEL } from '../constants';
import { onSelectControl } from '../handlers';

export const Filters = () => {
  const [showFilters, setShowFilters] = React.useState(false);
  const [showSort, setShowSort] = React.useState(false);

  const handleSetFilters = (show: boolean) => () => {
    setShowFilters(show);
  };

  const handleSetSort = (show: boolean) => () => {
    setShowSort(show);
  };

  const onCloseOverlay = () => {
    setShowFilters(false);
    setShowSort(false);
  };

  return (
    <div className="filters-block">
      <Show if={showFilters || showSort}>
        <div className="controls-overlay" onClick={onCloseOverlay}/>
      </Show>

      <div className={classNames('filter-menu', { active: showFilters })}>
        <div className="filter-menu-head">
          <img className="filter-icon" src={FilterIcon} alt="FilterIcon"/>
          <span>Filter</span>
          <img
            className="filter-icon arrow-up"
            src={ArrowIcon}
            alt="ArrowIcon"
            onClick={handleSetFilters(false)}
          />
        </div>
        <div className="filter-menu-body">
          <DropdownItem
            name="filter"
            value={FILTER_TYPES.all}
            title="Show All"
            onClose={onCloseOverlay}
          />
          <DropdownItem
            name="filter"
            value={FILTER_TYPES.created}
            title="Show draft"
            onClose={onCloseOverlay}
          />
          <DropdownItem
            name="filter"
            value={FILTER_TYPES.published}
            title="Show published"
            onClose={onCloseOverlay}
          />
        </div>
      </div>

      <div className={classNames('filter-menu', { active: showSort })}>
        <div className="filter-menu-head">
          <span>Sort by</span>
          <img
            className="filter-icon arrow-up"
            src={ArrowIcon}
            alt="ArrowIcon"
            onClick={handleSetSort(false)}
          />
        </div>
        <div className="filter-menu-body">
          <DropdownItem
            name="order"
            value={ORDER_TYPES.created_at}
            title="Created at"
            onClose={onCloseOverlay}
          />
          <DropdownItem
            name="order"
            value={ORDER_TYPES.title}
            title="Title"
            onClose={onCloseOverlay}
          />
        </div>
      </div>

      <div className="filters-item" onClick={handleSetFilters(true)}>
        <img className="filter-icon" src={FilterIcon} alt="FilterIcon"/>
        <span>Filter</span>
        <Label name="filter" />
        <img className="filter-icon" src={ArrowIcon} alt="ArrowIcon"/>
      </div>
      <div className="filters-item" onClick={handleSetSort(true)}>
        <span>Sort by</span>
        <Label name="order" />
        <img className="filter-icon" src={ArrowIcon} alt="ArrowIcon"/>
      </div>
    </div>
  )
};

type ItemProps = {
  name: 'filter' | 'order',
  value: FILTER_TYPES | ORDER_TYPES,
  title: string,
  onClose: () => void
};

const DropdownItem: React.FC<ItemProps> = (props) => {
  const { name, value, title, onClose } = props;

  const { filter, order } = useAppSelector(state => state.newsletter.controls);
  const isActive = (value: string) => value === filter || value === order;
  const onSelect = () => {
    onSelectControl(name, value)();
    onClose();
  };

  return (
    <div
      className={classNames('filter-menu-item', {
        active: isActive(value)
      })}
      onClick={onSelect}
    >
      {title}
    </div>
  )
};

type LabelProps = {
  name: 'filter' | 'order'
};

const Label: React.FC<LabelProps> = (props) => {
  const { name } = props;
  const value = useAppSelector(state => state.newsletter.controls[name]);

  return <span className="value">: {TYPE_TO_LABEL[value as FILTER_TYPES & ORDER_TYPES]}</span>
};
