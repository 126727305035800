import { logStart, logSuccess, logFail } from 'src/components/loader';

import { toast } from './toast';
import { logoutFromSystem } from './auth';

export const requestWrapper = (request: Function) => {
  return async (...args: any[]) => {
    try {
      const response: any = await (async () => { return request(...args) })();
      return {
        success: true,
        response
      }
    } catch ({ response }) {
      switch (response?.status) {
        case 500:
          toast('Server error', 'error');
          break;

        case 400:
          const message = response.data.error_message || response.data.message;
          toast(message, 'error');
          break;

        case 401:
          logoutFromSystem();
          break;
      }

      return {
        success: false,
        response
      }
    }
  }
};

export const loadingWrapper = (id: string, callback: Function) => async (...args: any[]) => {
  logStart(id);
  const status = await callback(...args);
  if (status) logSuccess(id);
  else logFail(id);

  return status
};
