import { createSlice } from '@reduxjs/toolkit';

import {
  toggleChooseContent,
  toggleEdit,
  addField,
  setPrevValue,
  deleteField,
  setEvent,
  setNewsletter,
  setPublicUrl,
  swapFields,
  concatFields,
  setFooterContent
} from '../actions';
import { TEMPLATE_TYPES } from 'src/constants';
import { EditorStoreType } from '../types';

const initialState = {
  isOpenContent: false,
  isEditing: null,
  editingType: '',
  event: null,
  fields: [],
  columnsChange: '1',
  prevValue: null,
  newsletterDefault: {
    title: '',
    layout_type: TEMPLATE_TYPES.one_column,
    slug: '',
    footerContent: ''
  },
  publicUrl: '',
  initialFormValues: {}
} as EditorStoreType;

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    toggleChooseContent,
    toggleEdit,
    addField,
    setPrevValue,
    deleteField,
    setEvent,
    setNewsletter,
    setPublicUrl,
    swapFields,
    concatFields,
    setFooterContent
  }
});

export const editorReducer = editorSlice.reducer;
export const editorActions = editorSlice.actions;
