import * as React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import { useAppSelector } from 'src/stores';
import { WhiteCross } from 'src/components/icons';

import LogoAlt from 'src/assets/img/logo-alt.png';

import { toggleSidebar, unmountSidebar } from '../handlers';
import { NavigationMenu, ButtonGroup } from '../components';

export const SidebarMenu = () => {
  const active = useAppSelector(state => state.landing.showSidebar);

  React.useEffect(() => () => unmountSidebar(), []);

  return (
    <div className={classNames('sidebar-menu', { active })}>
      <div className="sidebar-body">
        <div className="heading-block">
          <img className="logo" src={LogoAlt} alt="LogoAlt"/>
          <Button
            type="text"
            size="large"
            shape="circle"
            onClick={toggleSidebar}
            icon={WhiteCross}
          />
        </div>

        <NavigationMenu mobile afterClick={toggleSidebar}/>
        <ButtonGroup />
      </div>
    </div>
  )
};
