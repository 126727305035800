import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import ArrowDownSvg from 'src/assets/img/chevron@2x.png';
import { useAppSelector } from 'src/stores';
import { Show } from 'src/components/conditions';

type UserProps = {
  date: string,
  title: string,
  cost: number,
  downloadLink: string
};

export const InvoiceList = () => {
  const invoices = useAppSelector(state => state.invoices.list);

  return (
    <>
      <div className="user-list">
        {invoices.map(invoice => (
          <InvoiceItem
            key={`invoices-${invoice.attributes.id}`}
            date={invoice.attributes.subscription_start_at}
            title={invoice.attributes.title}
            cost={invoice.attributes.cost || 0}
            downloadLink={invoice.attributes.invoice_pdf_url}
          />
        ))}
      </div>
      {invoices.length === 0 && (
        <h2 className='no-invoices'>No invoices</h2>
      )}
    </>
  );
};

const InvoiceItem = (props: UserProps) => {
  const { date, title, cost, downloadLink } = props;

  const desktopDate = moment(date).format('D MMM YYYY');
  const mobileDate = moment(date).format('DD.MM.YYYY');

  const [open, setOpen] = React.useState(false);

  const desktopItem = (
    <div className="hide-mobile">
      <div className="invoice-item-grid">
        <div className="invoice-date">{desktopDate}</div>

        <div className="name-info">
          <div className="full-name-info">{title}</div>
          <div className={classNames('invoice-status', { status: 'paid' })}>
            PAID
          </div>
        </div>

        <div className="price">£{cost}</div>

        <div className="download-cell">
          <Show if={Boolean(downloadLink)}>
            <a href={downloadLink} target="_blank" rel="noreferrer" className="app-button orange">
              Download
            </a>
          </Show>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames('user-item', { open })}>
      <div className="hide-desktop">
        <div className="user-short-info">
          <div className='truncate-block'>
            <div className="invoice-name">{title}</div>
          </div>
          <div className="price">£{cost}</div>

          <img
            className="arrow-collapse"
            src={ArrowDownSvg}
            alt="arrow-down"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="secondary-info">
          <div className={classNames('invoice-status', { status: 'paid' })}>
            PAID
          </div>
          <div className="invoice-date">{mobileDate}</div>
        </div>
        <div className="collapsible-info">
          <div className="download-wrap">
            <Show if={Boolean(downloadLink)}>
              <a href={downloadLink} target="_blank" rel="noreferrer" className="app-button orange">
                DOWNLOAD
              </a>
            </Show>
          </div>
        </div>
      </div>

      {desktopItem}
    </div>
  );
};
