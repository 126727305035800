import * as React from 'react';

import { getPublicEmail } from '../services';

export const Published = () => {
  const [email, setEmail] = React.useState<string>('');

  React.useEffect(() => {
    (async () => {
      const emailLetter = await getPublicEmail();
      setEmail(emailLetter);
    })()
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: email
      }}
    />
  )
};
