import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

export const Overlay = () => {
  const active = useAppSelector(state => state.overlay.value);

  return <div className={classNames('overlay', { active })}/>
};
