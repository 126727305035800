import axios from 'axios';
import moment from 'moment';

import { store } from 'src/stores';
import { API_MY_SCHOOL } from 'src/constants';
import { requestWrapper } from 'src/utils';

import { authActions } from 'src/stores/auth';
import { modalActions } from 'src/stores/modal';

const getSchoolRequest = requestWrapper(() => {
  return axios.get(API_MY_SCHOOL);
});

export const getSchool = async () => {
  const { success, response } = await getSchoolRequest();

  if (success) {
    const school = response.data.school.data.attributes;
    store.dispatch(authActions.setSchoolData(school));
    // if (school.subscription_status === 'trial' && moment(school.subscription_end_date).isBefore(moment())) {
    //   store.dispatch(modalActions.toggleModal(true));
    // }
  }
};
