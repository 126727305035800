import * as React from 'react';
import { Form, Formik } from 'formik';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';
import { Button } from 'src/components/button';

import {
  BREADCRUMBS_PASSWORDS,
  PASSWORDS_VALIDATION,
  ON_SUBMIT_PASSWORDS,
  MOUNT_PASSWORD_UPDATE,
  PASSWORDS_FORM
} from '../../constants';
import { PasswordFields } from '../../components';
import { onSubmitPasswords } from '../../handlers';

export const ChangePassword = () => {
  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="Passwords settings" to={ROUTES.settings} list={BREADCRUMBS_PASSWORDS}/>
      <div className="page-content-body">
        <h1>Password settings</h1>
        <h5>
          Passwords must be 8 or more characters in length<br/>
          and contain at least one number and one capital letter.
        </h5>
        <Loader id={MOUNT_PASSWORD_UPDATE}>
          <Formik
            initialValues={PASSWORDS_FORM}
            validationSchema={PASSWORDS_VALIDATION}
            onSubmit={onSubmitPasswords}
          >
            <Form>
              <PasswordFields />
              <div className="button-block full-size">
                <Button
                  loaderId={ON_SUBMIT_PASSWORDS}
                  htmlType="submit"
                  className="green-button"
                >
                  Update password
                </Button>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  )
};
