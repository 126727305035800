import * as React from 'react';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';

import { BREADCRUMBS_MANAGE_USERS, GET_MANAGE_USERS } from '../../constants';
import { Controls, UserList } from '../../components';

import { mountUsers } from '../../services';

export const ManageUsers = () => {
  React.useEffect(() => {
    mountUsers();
  }, []);

  return (
    <div className="manage-users">
      <Breadcrumbs title="Settinigs" to={ROUTES.settings} list={BREADCRUMBS_MANAGE_USERS}/>
      <div className="manage-users-body">
        <h1>Manage users</h1>
        <Controls />
        <Loader id={GET_MANAGE_USERS}>
          <UserList />
        </Loader>
      </div>
    </div>
  )
};
