import { ROUTES } from 'src/constants';

export const MENU_ITEMS = [{
  id: 'home-item',
  name: 'Home',
  path: ROUTES.home
}, {
  id: 'how-it-works-item',
  name: 'How it works',
  path: ROUTES.howItWorks
}, {
  id: 'pricing-item',
  name: 'Pricing',
  path: ROUTES.pricing
}];
