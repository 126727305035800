import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import { Input } from 'src/components/fields';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { Popconfirm } from 'antd';


type Props = {
  name: string
};

export const HeadingField: React.FC<Props> = (props) => {
  const { name } = props;

  const { setFieldValue } = useFormikContext();

  return (
    <div className="field-container">
      <div>
        <Field name={name} component={Input} />
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};
