import { matchPath } from 'react-router';

import { AnyObject } from 'src/types';
import { store } from 'src/stores';
import { ROUTES } from 'src/constants';
import { history } from 'src/utils';

import { mapDataFrom } from '../utils';
import { publish, save } from '../services';

export const onSaveLetter = async (data: AnyObject) => {
  const { fields } = store.getState().editor;
  const { params } = matchPath(history.location.pathname, ROUTES.editor) || {} as any;
  const id = Number(params?.id);

  return await save(id, mapDataFrom(fields, data));
};

export const onPublish = () => {
  const { params } = matchPath(history.location.pathname, ROUTES.editor) || {} as any;
  const id = Number(params?.id);

  publish(id);
};
