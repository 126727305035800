import * as React from 'react';
import { Formik, Form } from 'formik';

import { ROUTES } from 'src/constants';
import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Button } from 'src/components/button';
import { useAppSelector } from 'src/stores';
import { Loader } from 'src/components/loader';

import {
  BREADCRUMBS_EDIT_USER,
  INVITE_USER_VALIDATION,
  ON_SUBMIT_INVITE,
  MOUNT_EDIT_ADMIN
} from '../../constants';
import { UserFields } from '../../components';
import { unmountSubsEdit } from '../../handlers';

type Props = {
  onSubmit: (...ars: any[]) => any
  isAdmin?: Boolean
};

export const EditUser: React.FC<Props> = (props) => {
  const { onSubmit, isAdmin } = props;
  const {initialUser, initialValue} = useAppSelector(({auth, manageUsers}) => ({
    initialUser: auth.user?.attributes,
    initialValue: {
      ...manageUsers.initialValues,
      role: 'editor',
    }
  }));
  const initialValues = isAdmin ? {
    email: initialUser.email,
    first_name: initialUser.first_name,
    last_name: initialUser.last_name,
    role: initialUser.role,
  } : initialValue;

  React.useEffect(() => () => {
    unmountSubsEdit();
  }, []);

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="Manage users" to={ROUTES.manageUsers} list={BREADCRUMBS_EDIT_USER}/>
      <div className="page-content-body">
        <h1>Edit user</h1>
        <Loader id={MOUNT_EDIT_ADMIN}>
          <Formik
            initialValues={initialValues}
            validationSchema={INVITE_USER_VALIDATION}
            enableReinitialize
            onSubmit={onSubmit}
          >
            <Form>
              <UserFields />
              <div className="button-block full-size">
                <Button
                  loaderId={ON_SUBMIT_INVITE}
                  htmlType="submit"
                  className="app-button blue"
                >
                  Save changes
                </Button>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  )
};
