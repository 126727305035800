import { loaderActions } from './reducer';
import { store } from 'src/stores';

export const logStart = (id: string) => {
  store.dispatch(loaderActions.startLoad(id));
};

export const logSuccess = (id: string) => {
  store.dispatch(loaderActions.success(id));
};

export const logFail = (id: string) => {
  store.dispatch(loaderActions.failed(id));
};

export const isLoading = (id: string) => {
  const { loader } = store.getState();
  if (!loader[id]) return false;

  return loader[id] === 'pending'
};
