import * as React from 'react';
import { Field } from 'formik';

import { Input, Dropdown } from 'src/components/fields';

export const UserFields = () => {
  return (
    <>
      <Field name="first_name" label="First name" component={Input}/>
      <Field name="last_name" label="Last name" component={Input}/>
      <Field name="role" label="Role" className="full-size custom-dropdown" options={[ { id: 'editor', value: 'Editor' }, { id: 'admin', value: 'Admin' } ]} defaultValue={'Editor'} component={Dropdown}/>
      <Field name="email" label="Email address" className="full-size" component={Input}/>
    </>
  );
};
