import axios from 'axios';

import { store } from 'src/stores';
import { authActions } from 'src/stores/auth';
import { AUTH_STATES, ROUTES } from 'src/constants';
import { newsletterActions } from 'src/modules/newsletters/reducers';

import { history } from '../history';

export const logoutFromSystem = () => {
  const data = {
    value: AUTH_STATES.unauthorized
  };

  axios.defaults.headers.common['Authorization'] = undefined;
  store.dispatch(authActions.setUserData(data));
  store.dispatch(authActions.setSchoolData({}));
  store.dispatch(newsletterActions.setControl({page: 1, total_pages: 1}));
  store.dispatch(newsletterActions.setNewsletters([]));
  localStorage.setItem('session', JSON.stringify(data));
  history.push(ROUTES.home);
};
