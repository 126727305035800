import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ChervonPng from 'src/assets/img/arrow/chevron@2x.png';
import { Show } from 'src/components/conditions';

type Props = {
  title?: string,
  to?: string,

  list: Array<ItemProps>
};

type ItemProps = {
  title: string,
  to?: string
};

export const Breadcrumbs = (props: Props) => {
  const { title, to, list } = props;
  return (
    <div className={classNames('breadcrumb-holder', { 'hide-mobile': !to })}>
      <Link to={to || '/'} className="button-back">
        <img className="arrow-back" src={ChervonPng} alt="ChervonPng"/>
        {title}
      </Link>
      <div className="breadcrumb-list">
        {list.map(item => (
          <BreadcrumbItem key={`breadcrumb-${item.title}`} {...item}/>
        ))}
      </div>
    </div>
  );
};

const BreadcrumbItem = (props: ItemProps) => {
  return (
    <>
      <Link className={classNames({ disabled: !props.to })} to={props.to || ''}>
        {props.title}
      </Link>
      <Show if={Boolean(props.to)}>
        <img className="breadcrumb-arrow" src={ChervonPng} alt="ChervonPng"/>
      </Show>
    </>
  );
};
