import * as React from 'react';
import { Field } from 'formik';

import { Dropdown } from 'src/components/fields';

import { AVAILABLE_CURRENCY } from '../../constants';

export const PricingInfo = () => {
  return (
    <div className="info-block">
      <h1>Simple pricing</h1>
      <h2>Your School Newsletters builder with no contracts, setup or hidden fees</h2>
      <div className="info">
        Our prices exclude VAT, GST, or any other<br/>
        taxes that may be applicable in your region.
      </div>
      <div style={{ display: 'none' }}>
        <Field
          name="currency"
          options={AVAILABLE_CURRENCY}
          component={Dropdown}
        />
        <div className="currency-tooltip">CHANGE CURRENCY</div>
      </div>
    </div>
  );
};
