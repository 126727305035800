import * as React from 'react';
import { CarouselRef } from 'antd/lib/carousel';
import { store } from 'src/stores';
import { landingActions } from '../reducers';
import { CAROUSEL_ITEMS } from '../constants';

export const changeActiveSlide = (carouselRef: React.RefObject<CarouselRef>) => (slideNumber: number) => () => {
  if (!carouselRef?.current) return;

  store.dispatch(landingActions.changeSlide(slideNumber));
  carouselRef.current.goTo(slideNumber - 1)
};

export const moveSlide = (direction: -1 | 1, carouselRef: React.RefObject<CarouselRef>) => () => {
  if (!carouselRef?.current) return;
  let currentSlide = store.getState().landing.currentSlide + direction;

  if (currentSlide === 0 && direction < 0) currentSlide = CAROUSEL_ITEMS.length;
  if (currentSlide === 1 + CAROUSEL_ITEMS.length && direction > 0) currentSlide = 1;

  if (direction < 0) {
    carouselRef.current.prev()
  }

  if (direction > 0) {
    carouselRef.current.next()
  }

  store.dispatch(landingActions.changeSlide(currentSlide));
};
