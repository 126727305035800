import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';
import { Show } from 'src/components/conditions';

import { onChangeStep } from '../handlers';

type StepProps = {
  id: string,
  index: number,
  isLast: boolean,
  stepperList: { id: string, question: string }[]
};

type Props = {
  stepperList: { id: string, question: string }[]
};

export const Stepper: React.FC<Props> = (props) => {
  const { stepperList } = props;
  const hide = useAppSelector(state => !state.onBoarding.currentStep);

  if (hide) return null;

  return (
    <div className="start-stepper">
      {stepperList.map((step, index) => (
        <StepItem
          key={`step-${step.id}`}
          index={index}
          id={step.id}
          isLast={index === stepperList.length - 1}
          stepperList={stepperList}
        />
      ))}
    </div>
  )
};

export const StepItem: React.FC<StepProps> = (props) => {
  const {
    id,
    index,
    isLast,
    stepperList
  } = props;
  const active = useAppSelector(state => state.onBoarding.currentStep?.id === id);

  return (
    <>
      <div className={classNames('step-item', { active })} onClick={onChangeStep(stepperList[index])}>
        {index + 1}
      </div>
      <Show if={!isLast}>
        <div className="step-divider" />
      </Show>
    </>
  )
};
