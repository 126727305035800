import { TAB_LIST } from '../constants';
import { history } from 'src/utils';
import { handleChangeTab } from './';

export const mount = () => {
  const neededMenuItem = TAB_LIST.find(
    item => item.path === history.location.pathname
  ) || { id: TAB_LIST[0].id };
  handleChangeTab({ key: neededMenuItem.id });
};
