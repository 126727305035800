import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import RichTextEditor from 'react-rte';

import { ColorPicker } from 'src/components/fields';
import { AnyObject } from 'src/types';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { Popconfirm } from 'antd';

type Props = {
  name: string
};

export const BorderedTextEditor: React.FC<Props> = (props) => {
  const { name } = props;

  const { setFieldValue, values } = useFormikContext<AnyObject>();
  // const [field] = useField(name);

  // const value = field.value || RichTextEditor.createEmptyValue();
  // const onChange = (value: any) => {
  //   setFieldValue(`${name}.value`, value)
  // };

  React.useEffect(() => {
    if (!values[name]?.background && !values[name]?.font) {
      setFieldValue(`${name}.background`, '#ffffff');
      setFieldValue(`${name}.font`, '#000000');
    }
    setFieldValue(`${name}.break`, 'break-word');
  }, []);

  return (
    <div className="field-container">
      <div>
        <Field
          name={name}
          children={({field, form}) => {
            const handleChange = (value: any) => 
              form.setFieldValue(`${name}.value`, value);

            const handleBlur = () => 
              form.setFieldTouched(name, true);
            return (
              <RichTextEditor
                // {...field}
                toolbarConfig={{
                  // Optionally specify the groups to display (displayed in the order listed).
                  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                  INLINE_STYLE_BUTTONS: [
                    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                    {label: 'Italic', style: 'ITALIC'},
                    {label: 'Underline', style: 'UNDERLINE'}
                  ],
                  BLOCK_TYPE_DROPDOWN: [
                    {label: 'Normal', style: 'unstyled'},
                    {label: 'Heading Large', style: 'header-one'},
                    {label: 'Heading Medium', style: 'header-two'},
                    {label: 'Heading Small', style: 'header-three'}
                  ],
                  BLOCK_TYPE_BUTTONS: [
                    {label: 'UL', style: 'unordered-list-item'},
                    {label: 'OL', style: 'ordered-list-item'}
                  ]
                }}
                className="text-editor"
                value={field.value?.value || RichTextEditor.createEmptyValue()}
                onChange={handleChange}
                onBlur={handleBlur} // REQUIRED, AFTER npm i ADD onBlur?: (e: any) => void; to RichTextEditor lib props!!!!!
              />
            )
          }}
        />
        <div className="color-fields">
          <Field label="Background color" name={`${name}.background`} component={ColorPicker}/>
          <Field label="Text color" name={`${name}.font`} component={ColorPicker}/>
        </div>
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};