import * as React from 'react';
import { Spin } from 'antd';

import { useAppSelector } from 'src/stores';

type Props = {
  id: string,
  absolute?: boolean,
  children?: React.ReactNode
}

export const Loader = (props: Props) => {
  const isLoading = useAppSelector(state => state.loader[props.id] === 'pending');

  return (
    <Spin size="large" spinning={isLoading}>
      {props.absolute && isLoading ? null : props.children}
    </Spin>
  )
};
