import { createSlice } from '@reduxjs/toolkit';

import { toggleModal } from './actions';

export interface ModalState {
  value: boolean
}

const initialState = {
  value: false
} as ModalState;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal
  }
});

export const modalReducer = modalSlice.reducer;
export const modalActions = modalSlice.actions;
