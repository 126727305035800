import * as React from 'react';

import LaptopImg from 'src/assets/img/laptop-image.svg';
import ArrowLeft from 'src/assets/img/arrow-left.svg';
import ArrowRight from 'src/assets/img/mobile/arrow-in-create-block.svg';

import { GetStartedBlock, Footer, TryItButton } from '../../components';
import { changeHeaderType, changeMobileHeaderType, handleToggleAuth } from '../../handlers';

import { KEY_FEATURES_LIST } from '../../constants';

import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'src/utils';

export const HomePage = () => {
  // const location = useLocation();

  // console.log(location.pathname.split('/'));

  // if(location.pathname.split('/').length > 2) {
  //   const { token } = useParams();
  // }

  const { token } = useParams<{ token: string }>();

  React.useEffect(() => {
    if(token?.length) {
      changeHeaderType(true);
      changeMobileHeaderType(true);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      handleToggleAuth(true, 'register')();
      toast('Enter your email address to get access', 'success');
    } else {
      changeHeaderType(false);
      changeMobileHeaderType(false);
    }
  }, [token]);

  const keyFeatures = KEY_FEATURES_LIST.map(item => (
    <div className="feature-card" key={item.id}>
      <img src={item.img} alt={item.id}/>
      <h3 dangerouslySetInnerHTML={{__html: item.title}}/>
      <div className="text">{item.description}</div>
    </div>
  ));

  return (
    <>
      <div className="create-page-block">
        <div className="info-block">
          <h1>Create School Newsletters</h1>
          <h3>Simple. Effortless. Beautiful.</h3>
          <div className="button-block">
            <TryItButton />
            <div className="tip-block">
              Immediate access.<br/>
              No payment details required.
            </div>
            <img className="arrow-left" src={ArrowLeft} alt="ArrowLeft" />
          </div>
        </div>
        <img className="laptop-img" src={LaptopImg} alt="LaptopImg" />

        <div className="button-block mobile">
          <div className="tip-block">
            Immediate access. No payment details<br/>
            required.
          </div>

          <TryItButton />

          <img className="arrow-left" src={ArrowRight} alt="ArrowLeft" />
        </div>

        <div className="underline-left"/>
        <div className="underline-right"/>
      </div>

      <div className="key-features-block">
        <h2>Key features</h2>
        <div className="divider"/>
        <div className="features-wrap">
          {keyFeatures}
        </div>
      </div>

      <GetStartedBlock/>

      <Footer/>
    </>
  )
};
