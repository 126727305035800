import * as React from 'react';
import { Button } from 'antd';

import { handleToggleAuth } from '../handlers';

export const TryItButton = () => {
  const handleClick = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    handleToggleAuth(true, 'register')();
  };
  return (
    <Button onClick={handleClick} className="try-it-btn" shape="round" size="large">
      <span className="try-it">Try it</span>FREE
    </Button>
  )
};
