import * as React from 'react';
import { FieldAttributes, useFormikContext } from 'formik';
import { UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import { Upload } from 'antd';
import axios from 'axios';

import UploadImg from 'src/assets/img/upload@2x.png';
import ScraperImg from 'src/assets/img/scraper@2x.png';
import WhiteCrossSvg from 'src/assets/img/white-cross.svg';
import { API_ATTACHMENTS } from 'src/constants';
import { AnyObject } from 'src/types';
import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  label?: string
};

export const MultipleUpload = (props: Props) => {
  const { className, label, field } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext<AnyObject>();

  const tipText = label || 'Drop file here to upload';

  const handleChange = async (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      const data = [...(field.value || []), info.file.response?.attachment?.data?.attributes?.url];
      setFieldValue(field.name, data);
      setFieldTouched(field.name, true);
    }
  };

  const handleDelete = (indexToDelete: number) => () => {
    setFieldValue(field.name, field.value.filter((_item: string, index: number) => index !== indexToDelete));
  };

  return (
    <div className="form-uploader-wrap">
      <div className="files-grid">
        {(field.value || []).map((item: string, index: number) => (
          <div
            key={`${item}-item-file`}
            className="file-item"
            style={{ backgroundImage: `url(${item})` }}
          >
            <img
              className="delete"
              src={WhiteCrossSvg}
              alt="cross"
              onClick={handleDelete(index)}
            />
          </div>
        ))}
      </div>
      <Show if={(field.value?.length || 0) < 4}>
        <Upload
          multiple
          className={classNames('form-uploader', className)}
          action={API_ATTACHMENTS}
          maxCount={4}
          headers={axios.defaults.headers.common}
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: false,
          }}
          onChange={handleChange}
        >
          <img className="upload-icon" src={UploadImg} alt="UploadImg"/>
          <div className="upload-text">{tipText}</div>
          <div className="upload-text small">or</div>
          <button className="app-button green" type="button">
            <img className="scraper-icon" src={ScraperImg} alt="ScraperImg"/>
            Select file
          </button>
          <p>(Max 4 files)</p>
        </Upload>
      </Show>
    </div>
  );
};
