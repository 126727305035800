import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from 'src/components/router';
import { ROUTES } from 'src/constants';

import { Newsletters } from './Newsletters';
import { CreateNewsletter } from 'src/modules/onBoarding';
import {NotFound} from 'src/modules/notFound';

export const NewsletterRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={ROUTES.createLetter} component={CreateNewsletter}/>
      <PrivateRoute exact path={ROUTES.newsletters} component={Newsletters}/>
      <Route component={NotFound} />
    </Switch>
  );
};
