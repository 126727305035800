import _ from 'lodash';

import { history } from 'src/utils';
import { ROUTES } from 'src/constants';

import { SchoolSettingsForm } from '../types';
import { schoolSettingsMapper } from '../utils';
import { changeSchoolSettings } from '../services';

export const onSubmitSchoolSettings = async (values: SchoolSettingsForm) => {
  const data = schoolSettingsMapper(values);
  if (_.isEmpty(data.school)) {
    history.push(ROUTES.settings);
    return;
  }

  changeSchoolSettings(data)
};
