import * as React from 'react';
import { Checkbox as CheckboxAnt} from 'antd';
import { FieldAttributes } from 'formik';

type Props = FieldAttributes<any>;

export const Checkbox = (props: Props) => {
  return (
    <CheckboxAnt checked={props.field.value} {...props} {...props.field}/>
  );
};
