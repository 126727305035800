import * as React from 'react';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';

import { BREADCRUMBS_BILLING, BREADCRUMBS_SUBSCRIPTION, GET_INVOICES } from '../../constants';
import { InvoiceList, BillingInfoBlock, YearDropdown } from '../../components';

import { mountInvoices } from '../../services';

export const Invoices = () => {
  React.useEffect(() => {
    mountInvoices();
  }, []);

  return (
    <div className="manage-users">
      <Breadcrumbs title="Settinigs" to={ROUTES.settings} list={BREADCRUMBS_BILLING}/>
      <div className="manage-users-body">
        <Loader id={GET_INVOICES}>
          <div className="invoices-container">
            <div className='invoices-wrapper'>
              <div className="header-block">
                <h1>Invoices</h1>
                <YearDropdown />
              </div>
              <InvoiceList />
            </div>
            <BillingInfoBlock />
          </div>
        </Loader>
      </div>
    </div>
  )
};
