import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import shortID from 'shortid';

import { Input, ColorPicker } from 'src/components/fields';
import { AnyObject } from 'src/types';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { WhiteCross } from 'src/components/icons';
import { Popconfirm } from 'antd';

type Props = {
  name: string
};

export const AttendanceField: React.FC<Props> = (props) => {
  const { name } = props;

  const { values, setFieldValue, isValid, touched } = useFormikContext<AnyObject>();

  const handleAdd = () => {
    setFieldValue(name, [...(values[name] || []), { id: shortID(), name: '', percents: 0 }])
  };

  const handleDeleteRow = (rowIndex: number) => () => {
    const list = _.cloneDeep(values[name]);
    _.remove(list, (item, index) => index === rowIndex);

    setFieldValue(name, list);
  };
  const touchedKeys = Object.keys(touched);

  return (
    <div className="field-container">
      <div>
        <div className="field-head">
          <span>Year group / Class name</span>
          <span>Attendance</span>
        </div>
        {values[name]?.map((item: any, index: number) => (
          <FieldSet
            key={`progress-edit-${item.id}`}
            name={name}
            index={index}
            onDelete={handleDeleteRow(index)}
          />
        ))}
        <button type="button" className="add-row-btn app-button green" onClick={handleAdd}>
          Add row
        </button>
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField} disabled={!touchedKeys[0] || !isValid}>
          Save changes
        </button>
      </div>
    </div>
  )
};

type FieldSetProps = {
  name: string,
  index: number,
  onDelete: () => void
};

const FieldSet: React.FC<FieldSetProps> = (props) => {
  const { name, index, onDelete } = props;

  const required = value => {
    return !value || value === '' ? 'Required' : undefined;
  };

  return (
    <>
      <div className="field-row">
        <Field name={`${name}[${index}].name`} component={Input} validate={required} />
        <Field
          className="percents"
          name={`${name}[${index}].percents`}
          type="number"
          min={0}
          max={100}
          component={Input}
          validate={required}
        />
        <Field name={`${name}[${index}].background`} component={ColorPicker}/>
        <div className="delete-button" onClick={onDelete}>
          {WhiteCross}
        </div>
      </div>
    </>
  )
};
