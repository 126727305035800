import { PayloadAction } from '@reduxjs/toolkit';
import { LayoutState } from '../types';

export const toggleHeader = (state: LayoutState, action: PayloadAction<boolean>) => {
  state.withHeader = action.payload;
};

export const changeSelectedTab = (state: LayoutState, action: PayloadAction<string>) => {
  state.selectedTab = action.payload
};
