import * as React from 'react';
import { Formik, Form } from 'formik';

import { ROUTES } from 'src/constants';
import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Button } from 'src/components/button';
import { useAppSelector } from 'src/stores';

import {
  BREADCRUMBS_INVITE_USER,
  INVITE_USER_VALIDATION,
  ON_SUBMIT_INVITE
} from '../../constants';
import { UserFields } from '../../components';
import { onInvite } from '../../handlers';

type Props = {
  onSubmit?: (...ars: any[]) => any
};

export const InviteUser: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const initialValues = useAppSelector(state => ({
    ...state.manageUsers.initialValues,
    role: 'editor',
  }));

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="Manage users" to={ROUTES.manageUsers} list={BREADCRUMBS_INVITE_USER}/>
      <div className="page-content-body">
        <h1>Invite user</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={INVITE_USER_VALIDATION}
          enableReinitialize
          onSubmit={onSubmit || onInvite}
        >
          <Form>
            <UserFields />
            <div className="button-block full-size">
              <Button
                loaderId={ON_SUBMIT_INVITE}
                htmlType="submit"
                className="green-button"
              >
                Send invite
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
};
