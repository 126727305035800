import { PayloadAction } from '@reduxjs/toolkit';
import { AnyObject } from 'src/types';
import { ManageUsersStore, UsersType } from '../types';

export const changeActiveFilter = (state: ManageUsersStore, action: PayloadAction<string>) => {
  state.activeFilter = action.payload;
};

export const addUsers = (state: ManageUsersStore, action: PayloadAction<AnyObject>) => {
  state.users = action.payload.users;
  state.owner = action.payload.owner;
};

export const changeInitialValues = (state: ManageUsersStore, action: PayloadAction<AnyObject>) => {
  state.initialValues = action.payload as any;
};
