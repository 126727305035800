import * as React from 'react';
import { Layout } from 'antd';

import LogoImg from 'src/assets/img/logo.png';
import { useAppSelector } from 'src/stores';

import { TabsBlock, HeaderAdditionalBlock } from '../components';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'src/constants'

export const AppHeader = () => {
  const withHeader = useAppSelector(state => state.layout.withHeader);

  if (!withHeader) return null;

  return (
    <Layout.Header className="header">
      <img className="logo-img" src={LogoImg} alt="logo" />
      {/* <TabsBlock hideMobile/> */}
      <ul className='header-content'>
        <li><NavLink exact to={ROUTES.newsletters}>Newsletters</NavLink></li>
        <li><NavLink exact to={ROUTES.settings}>Settings</NavLink></li>
      </ul>
      <HeaderAdditionalBlock/>
    </Layout.Header>
  )
};
