import { store } from 'src/stores';
import {
  InviteUserType,
  InviteUserFormType,
  ProfileValueType,
  SchoolSettingsForm,
  PasswordsType,
  InviteSubsType,
  BillingInfoType,
  BillingInfoTypeBE
} from '../types';
import { AnyObject } from 'src/types';

export const inviteUserMapper = (values: InviteUserFormType): InviteUserType  => {
  return {
    invite: values
  }
};

export const inviteSubsMapper = (values: InviteUserFormType): InviteSubsType  => {
  return {
    invite: values
  }
};

export const myDetailsMapper = (values: ProfileValueType) => {
  const userData = store.getState().auth.user?.attributes || {} as ProfileValueType;

  const user = Object.keys(values).reduce((prev: AnyObject, current: string) => {
    if (userData[current as keyof ProfileValueType] === values[current as keyof ProfileValueType]) return prev;

    return {
      ...prev,
      [current]: values[current as keyof ProfileValueType]
    }
  }, {});

  return {
    user
  }
};

export const schoolSettingsMapper = (values: SchoolSettingsForm) => {
  const schoolData = store.getState().school.school || {} as SchoolSettingsForm;

  const school = Object.keys(values).reduce((prev: AnyObject, current: string) => {
    if (schoolData[current as keyof SchoolSettingsForm] === values[current as keyof SchoolSettingsForm]) return prev;

    return {
      ...prev,
      [current]: values[current as keyof SchoolSettingsForm]
    }
  }, {});

  return {
    school
  }
};

export const mapPasswords = (values: PasswordsType) => {
  return {
    old_password: values.existing_password,
    password: values.password
  }
};

export const mapBillingInfo = (data: BillingInfoType) => {
  return {
    billing_information: {
      ...data
    }
  }
};

export const mapToEditAdmin = (data: AnyObject) => {
  return {
    email: data?.user?.data.attributes.email,
    first_name: data?.user.data.attributes.first_name,
    last_name: data?.user?.data.attributes.last_name,
    role: data?.user?.data.attributes.role
  }
};

export const mapFromSchool = (data: AnyObject) => {
  return {
    ...data,
    logo: data.logo?.url || ''
  }
};
