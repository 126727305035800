import _ from 'lodash';

import { store } from 'src/stores';
import { TEMPLATE_TYPES } from 'src/constants';
import { CONTENT_ITEMS } from '../constants';

import {
  MailHeader,
  MainLayout,
  MailFooter,
  Column,
  Row,
  Empty,
  EmptySpace,
  Image,
  BorderedText,
  Footer,
  Text,
  Event,
  Heading,
  Divider,
  Download,
  ImageGroup,
  SocialMedia,
  Attendance,
  Button,
  Code,
  Video,
  StarOfWeek
} from '../generator';

import { splitFields } from '../services';

export const generateHTML = (fieldsList: { name: string, type: string, value: any }[]) => {
  const { editor, auth } = store.getState();
  const { title, layout_type } = editor.newsletterDefault;
  const { name, email, logo, phone, website } = auth.school;
  const head = MailHeader({
    name: title,
    school: name,
    telephone: phone,
    website: website,
    logo: logo?.url,
    email
  });


  const body = bodyGenerator(layout_type, fieldsList);
  
  const footer = MailFooter({
    name: title,
    school: name,
    telephone: phone,
    website: website,
    logo: logo?.url,
    email
  });

  return MainLayout(head, body, footer)
};

const bodyGenerator = (layoutType: TEMPLATE_TYPES, fieldsList: { name: string, type: string, value: any }[]) => {

  if(layoutType === TEMPLATE_TYPES.one_column) {
    const chunkSize = layoutType === TEMPLATE_TYPES.one_column ? 1 : 2;
    const list = _.chunk(fieldsList, chunkSize);

    const chunks = list.map(chunk => chunk.map((item, index) => getFieldHTML(item, index)));

    if ((chunks[chunks.length - 1] || []).length === 1) {
      chunks[chunks.length - 1].push(Empty()(1))
    }

    return chunks.map(chunk => Row(chunk.join(''))).join('')
  } else {
    const { leftColumn, rightColumn } = splitFields(fieldsList);
    const leftElements = leftColumn.map((el, i) => getFieldHTML(el, 2));
    const rightElements = rightColumn.map((el, i) => getFieldHTML(el, 3))

    return Column(leftElements.join(''), rightElements.join(''), layoutType)
    // console.log(leftElements);
  }
  
};

const getFieldHTML = ({ type, value }: { name: string, type: string, value: any }, index: number) => {
  let ContentGenerator: Function = () => () => '';

  switch (type) {
    case CONTENT_ITEMS[0].id:
      ContentGenerator = Heading(value);
      break;

    case CONTENT_ITEMS[1].id:
      ContentGenerator = Text(value);
      break;

    case CONTENT_ITEMS[2].id:
      ContentGenerator = BorderedText(value.value, value.background, value.font);
      break;

    case CONTENT_ITEMS[3].id:
      ContentGenerator = Divider(value);
      break;

    case CONTENT_ITEMS[4].id:
      ContentGenerator = Image(value);
      break;

    case CONTENT_ITEMS[5].id:
      ContentGenerator = ImageGroup(value);
      break;

    case CONTENT_ITEMS[6].id:
      ContentGenerator = SocialMedia(value.facebook, value.instagram, value.twitter, value.linkedin, value.youtube);
      break;

    case CONTENT_ITEMS[7].id:
      ContentGenerator = Button(value.btnText, value.url);
      break;

    // case CONTENT_ITEMS[8].id:
    //   ContentGenerator = StarOfWeek(value.student, value.className);
    //   break;

    case CONTENT_ITEMS[8].id:
      ContentGenerator = Attendance(value);
      break;
    
    case CONTENT_ITEMS[9].id:
      ContentGenerator = Video(value);
      break;

    case CONTENT_ITEMS[10].id:
      ContentGenerator = Download(value.name, value.url);
      break;

    case CONTENT_ITEMS[11].id:
      ContentGenerator = Event(value);
      break;

    case CONTENT_ITEMS[12].id:
      ContentGenerator = Footer(value);
      break;

    case CONTENT_ITEMS[13].id:
        ContentGenerator = EmptySpace();
        break;
    default:
      ContentGenerator = () => () => '';
  }

  return ContentGenerator(index)
};
