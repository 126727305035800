import { AnyObject } from 'src/types';
import { getWidthPercents, getWidthEventPercents } from '../../utils';

export const Event = (list: AnyObject[]) => (index: number) => `
<mj-column width="${getWidthPercents(index)}" padding="4px 0" padding-top="4px">
    <mj-text color="#444444" padding="0" padding-bottom="8px">
        <p style="font-size: 19px; font-weight: bold; margin: 0;">Events</p>
    </mj-text>
    ${list.map(item => EventItem(item, index)).join('')}
</mj-column>
`;

const EventItem = (item: AnyObject, index: number) => `
    <mj-section padding="0" padding-bottom="0" border-bottom="1px solid rgba(68,68,68,0.25)" text-align="left" css-class="event-container">
      <mj-column width="60px" padding-bottom="14px" padding-top="8px">
          <mj-text padding="0" color="#fff" css-class="event-date">
              <span style="font-size: 18px;
                font-weight: bold;
                display: table-cell;
                background-color: #f15955;
                width: 60px;
                height: 30px;
                padding-top: 11px;
                margin: 0;
                text-align: center;
                border-radius: 5px 5px 0 0"
              >
                  ${item.date?.format('DD')}
              </span>
              <span padding="0" style="font-size: 14px;
                background-color: #f15955;
                display: block;
                width: 60px;
                margin: 0;
                height: 30px;
                text-align: center;
                border-radius: 0 0 5px 5px"
              >
                  ${item.date?.format('MMM')}
              </span>
          </mj-text>
          ${item.time ? `
            <mj-text css-class="event-time" color="#f15955">
                <span style="padding: 0;
                font-size: 11px;
                font-weight: 300;
                margin: 0" class="event-time">
                    ${item.time?.format('HH:mm')}
                </span>
            </mj-text>
          ` : ''}
      </mj-column>
      <mj-column width="${getWidthEventPercents(index)}" css-class="event-bottom-wrapper">
          <mj-text padding="0 0 0 7.5px" color="#fff" css-class="event-title">
              <span padding="0" style="font-size: 16px; font-weight: bold;
              display: table-cell;
              color: #444444;
              padding: 0;
              margin: 0;
              text-align: left;
              vertical-align: top;">
                  ${item.title}
              </span>
              <span padding="0" style="font-size: 14px; font-weight: normal;
              display: table-row;
              color: #444444;
              padding: 0 0 5px 5px;
              margin: 0;
              height: 25px;
              text-align: left;
              overflow: hidden;
              white-space: pre-line;
              vertical-align: top;">
                  ${item.description}
              </span>
          </mj-text>
      </mj-column>
    </mj-section>
  `;
