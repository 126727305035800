import * as React from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import _ from 'lodash';

import { useAppSelector } from 'src/stores';
import { ROUTES } from 'src/constants';
import { OnBoarding } from 'src/modules/onBoarding';

type Props = {
  exact?: boolean,
  path?: string,
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  children?: React.ReactNode
};

export const NewUserRoute = (props: Props) => {
  const { exact, path, component, children } = props;
  const isAuth = useAppSelector(state => Boolean(state.auth.value));
  const isNewUser = useAppSelector(state => _.isEmpty(state.auth.school));

  if (!isAuth) return (
    <Redirect to={ROUTES.main}/>
  );

  if (isNewUser) return (
    <Route component={OnBoarding}/>
  );

  return (
    <Route exact={exact} path={path} component={component} children={children} />
  )
};
