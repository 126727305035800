import * as React from 'react';
import { FieldAttributes } from 'formik';
import { Link } from 'react-router-dom';

import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  additionalText?: string,
  additionalTextButton?: string,
  textLink?: string,
  label: string,
  onClickAdditional?: () => void;
};

export const Input = (props: Props) => {
  const { id, additionalText, textLink, label, form, additionalTextButton, onClickAdditional, ...rest } = props;
  const error = form.errors[props.field.name];

  return (
    <div className="form-input">
      <div className="label-block">
        <label htmlFor={id}>{label}</label>
        <Show if={Boolean(additionalText)}>
          <Link to={textLink || ''} className="link">{additionalText}</Link>
        </Show>
        <Show if={Boolean(additionalTextButton)}>
          <span onClick={onClickAdditional} className="link">{additionalTextButton}</span>
        </Show>
      </div>
      <input {...rest} {...props.field} />
      <div className="form-error">
        {error}
      </div>
    </div>
  );
};
