import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { loadingWrapper, requestWrapper, history, toast } from 'src/utils';
import { API_NEWSLETTERS, API_PUBLISH, ROUTES } from 'src/constants';
import { store } from 'src/stores';
import { mjmlToHtml } from 'src/services';

import { editorActions } from '../reducers';
import {
  GET_NEWSLETTER,
  PUBLISH,
} from '../constants';
import { mapDataToForm } from '../utils';
import { NewsletterTypeBE } from '../types';

const getNewsletterRequest = requestWrapper((id: number) => {
  return axios.get(`${API_NEWSLETTERS}/${id}`);
});

const publishRequest = requestWrapper((id: number) => {
  return axios.put(`${API_PUBLISH}/${id}`);
});

const saveRequest = requestWrapper((id: number, data: NewsletterTypeBE) => {
  return axios.patch(`${API_NEWSLETTERS}/${id}`, data)
});

export const getNewsletter = loadingWrapper(
  GET_NEWSLETTER,
  async (id: number) => {
    const { success, response } = await getNewsletterRequest(id);

    if (success) {
      store.dispatch(editorActions.setNewsletter(mapDataToForm(response.data)));
    }

    if(response?.status == 404) {
      history.push(ROUTES.newsletters);
    }
  }
);

export const publish = loadingWrapper(
  PUBLISH,
  async (id: number) => {
    const { success } = await publishRequest(id);

    if (success) {
      const path = generatePath(ROUTES.editorPublicUrl, { id });
      history.push(path);
      toast('Newsletter is published', 'success')
    }

    return success;
  }
);

export const save = loadingWrapper(
  GET_NEWSLETTER,
  async (id: number, data: NewsletterTypeBE) => {
    const html = await mjmlToHtml(data.body || '');
    const newData = { ...data, body: html };
    const { success } = await saveRequest(id, newData);

    if (success) {
      toast('Successfully saved', 'success')
    } else {
      toast('error saved', 'error')
    }

    return success
  }
);
