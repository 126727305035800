import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'src/components/fields';
import EditIcon from 'src/assets/img/edit-icon.png';
import SchoolNewsIcon from 'src/assets/img/editor/school-news-icon.png';
import { HeaderAdditionalBlock } from 'src/modules/layout/components';

import { onPublish } from '../handlers';

type Props = {
  withEditorButtons?: boolean
};

export const Header: React.FC<Props> = (props) => {
  const { withEditorButtons } = props;
  return (
    <div className="editor-header">
      <div className="title-wrapper">
        <img
          className="app-logo"
          src={SchoolNewsIcon}
          alt="SchoolNewsIcon"
        />
        <Field
          name="title"
          className="title-field"
          component={Input}
        />
        <img
          className="edit-icon"
          src={EditIcon}
          alt="edit"
        />
      </div>

      <HeaderAdditionalBlock
        withEditorButtons={withEditorButtons}
        onPublish={onPublish}
      />
    </div>
  )
};
