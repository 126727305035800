import * as React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { useAppSelector } from 'src/stores';

export const BillingInfoBlock = () => {
  const {billingInfo} = useAppSelector(state => state.subscription);
  const {
    first_name,
    last_name,
    address = 'None',
    telephone = 'None',
    email = 'None'
  } = billingInfo || {};

  const fullName = first_name && last_name
    ? `${first_name} ${last_name}`
    : 'None';

  return (
    <div className="billing-info-block">
      <div className="billing-info-head">
        <h1>Billing details</h1>
        <Link to={ROUTES.billingInfoUpdate} className="app-button orange">
          Edit
        </Link>
      </div>
      <div className="billing-info-title">Billing contact</div>
      <div className="billing-info-value">{fullName}</div>
      <div className="billing-info-title">Address</div>
      <div className="billing-info-value">{address || 'None'}</div>
      <div className="billing-info-title">Telephone</div>
      <div className="billing-info-value">{telephone || 'None'}</div>
      <div className="billing-info-title">Email</div>
      <div className="billing-info-value">{email || 'None'}</div>
    </div>
  );
};
