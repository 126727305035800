import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'src/components/fields';

export const SchoolFields = () => {
  return (
    <>
      <Field name="name" label="School name" className="full-size" component={Input}/>
      <Field name="email" label="School email" className="full-size" component={Input}/>
      <Field name="phone" label="School phone" component={Input} inputType='phone' />
      <Field name="website" label="School website" component={Input}/>
    </>
  )
};
