import { AnyObject } from 'src/types';
import { SERVER_URL } from 'src/constants';
import { store } from 'src/stores';
import {getPrimaryTextColor} from '../utils';

export const MailHeader = (args: AnyObject) => {
  const {
    logo,
    website,
    telephone,
    email,
    school,
    name
  } = args;
  const { primary_color } = store.getState().auth.school;
  const textColor = getPrimaryTextColor();

  return `
  <mj-section padding="8px 15px" width="100%" background-color="#fff"
                    >
            <mj-group width="100%" style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                <mj-column width="100%" padding-bottom="8px">
                    <mj-image
                        css-class="border-r"
                        padding="0" 
                        background-size="contain" 
                        alt="email_logo" 
                        border="none"
                        src="${SERVER_URL}${logo}"
                        target="_blank"
                        title="header-image"
                        width="auto"
                        ></mj-image>
                </mj-column>
            </mj-group>
            <mj-column  width="100%" background-color="${primary_color ? primary_color : '#34c3ee'}" color="${textColor ? textColor : 'white'}" border-radius="20px 0 20px 0" padding="8px 12px">
                <mj-text color="${textColor ? textColor : 'white'}" font-size="18px" padding-bottom="0" font-weight="bold" align="left" padding="0" font-family="'Averta'">
                    ${school}
                </mj-text>
                <mj-text color="${textColor ? textColor : 'white'}" font-size="16px" align="left" padding="0">
                    ${name}
                </mj-text>
            </mj-column>
        </mj-section>
`
};
