import * as React from 'react';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';

import {
  BREADCRUMBS_BILLING,
  MOUNT_PASSWORD_UPDATE,
} from '../../constants';
import { SubsDetails } from '../../components';
import { NoSub } from '../../components/subscription/NoSub';
import { SubsCanceled } from '../../components/subscription/SubCanceled';

export const SubscriptionSettings = () => {
  return (
    <div className="page-content invite-user settings-subscription">
      <Breadcrumbs title="Subscription" to={ROUTES.settings} list={BREADCRUMBS_BILLING}/>
      <div className="page-content-body">
        <Loader id={MOUNT_PASSWORD_UPDATE}>
          <NoSub/>
          <SubsCanceled/>
          <SubsDetails/>
        </Loader>
      </div>
    </div>
  );
};
