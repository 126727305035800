import * as React from 'react';
import { Field } from 'formik';

import { ColorPicker, ImageField } from 'src/components/fields';

export const BrandingFields = () => {
  return (
    <>
      <Field
        name="logo"
        className="full-size"
        label="Drop image file here to upload"
        component={ImageField}
        aspect={32/9}
        // aspect={4/3}
      />
      <Field name="primary_color" label="Primary colour" component={ColorPicker}/>
      <Field name="secondary_color" label="Secondary colour" component={ColorPicker}/>
    </>
  );
};
