import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {ROUTES} from 'src/constants';
import {Button} from '../button';

export const CookiesPolicyToast = () => {
  const [cookieAgreement, setCookie] = useState(window.localStorage.getItem("cookieAgreement"));

  const handleAccept = () => {
    window.localStorage.setItem('cookieAgreement', 'true');
    setCookie('true');
    window.location.reload();
  };
  const handleDecline = () => {
    window.localStorage.setItem('cookieAgreement', 'false');
    setCookie('false');
    window.location.reload();
  };

  return cookieAgreement ? null : (
    <div className="cookies-toast-container">
      <div className="cookies-toast-wrapper">
        <span>
          We use cookies to give you the best experience possible on our website, including analytics and content personalisation. By continuing to browse this website, you agree to the use of cookies.
          <Link to={ROUTES.cookies}> Find out more</Link>
        </span>
        <div>
          <Button loaderId="" shape="round" size="large" onClick={handleAccept}><span>ACCEPT</span></Button>
          <Button loaderId="" shape="round" size="large" onClick={handleDecline} style={{marginLeft: '16px'}}><span>DECLINE</span></Button>
        </div>
      </div>
    </div>
  );
};
