import queryString from 'query-string';

import { store } from 'src/stores';
import { blockScroll, resolutionIsLessThen, history } from 'src/utils';
import { toggleOverlay } from 'src/components/overlay';

import { landingActions } from '../reducers';
import { changeSidebarState } from './sidebar';
import { LoginFormType, RegisterType, ChangePasswordType } from '../types';
import { mapTo, mapToRegisterApi } from '../utils';
import { login, register, forgotPassword, changePassword } from '../services';

export const handleToggleAuth = (show: boolean, type?: 'login' | 'register' | 'forgotPassword') => () => {
  store.dispatch(landingActions.setAuthType({ show, type }));
};

export const changeAuthContainerState = () => {
  const showAuth = store.getState().landing.showAuth;

  if (showAuth) {
    toggleOverlay(false);
    changeSidebarState(false);
  }

  if (resolutionIsLessThen('medium')) {
    blockScroll(Boolean(showAuth));
  }
};

export const onSubmitLogin = (data: LoginFormType) => {
  store.dispatch(login(mapTo(data)));
};

export const onSubmitRegister = (data: RegisterType) => {
  const invite_token = history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];

  store.dispatch(register(mapToRegisterApi(data, invite_token )));
};

export const onSubmitForgotPassword = (data: RegisterType) => {
  store.dispatch(forgotPassword(data.email));
};

export const onSubmitChangePassword = (data: ChangePasswordType) => {
  store.dispatch(changePassword(data));
};
