import shortID from 'shortid';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';

import { store } from 'src/stores';

import { editorActions } from '../reducers';
import { onDiscard } from './fields';
import { EMAIL_FIELDS_ID } from '../constants';

export const onOpenChooseContent = () => {
  store.dispatch(editorActions.toggleChooseContent(true));
};

export const onBackToLetter = (changeField: (field: string, value: any) => void) => () => {
  onDiscard(changeField)();

  store.dispatch(editorActions.toggleChooseContent(false));
  store.dispatch(editorActions.toggleEdit(null));
};

export const onBackToEventList = () => {
  store.dispatch(editorActions.setEvent(null));
};

export const onCreateField = (type: string) => () => {
  const name = shortID();

  store.dispatch(editorActions.addField({
    name,
    type
  }));
  store.dispatch(editorActions.toggleEdit({ name, type }));
};

export const onEditEvent = (name: string, index: number) => () => {
  store.dispatch(editorActions.setEvent({ name, index }));
};

export const onSaveEvent = () => {
  store.dispatch(editorActions.setEvent(null));
};

export const onSavePdf = () => {
  const element = document.getElementById(EMAIL_FIELDS_ID);
  if (!element) return;

  html2canvas(element)
    .then((canvas) => {
      var pdf = new JSPDF('p', 'pt', [canvas.width, canvas.height]);
      var imgData = canvas.toDataURL("image/png");
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, '', 'NONE');
      // @ts-ignore
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    })
};
