import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import { Input } from 'src/components/fields';
import { Button } from 'src/components/button';

import { STEPPER_ITEMS, ON_SUBMIT_ON_BOARDING } from '../constants';
import { onChangeStep } from '../handlers';

export const SchoolName = () => {
  const currentStep = useAppSelector(state => state.onBoarding.currentStep);
  const { values } = useFormikContext<{ name: string }>();

  if (currentStep?.id !== STEPPER_ITEMS[0].id) return null;

  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">1</div>
        {STEPPER_ITEMS[0].question}
      </div>
      <Field
        name="name"
        className="school-input"
        placeholder="(Your school here)"
        component={Input}
      />

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="button"
        className="app-button green"
        disabled={!values.name}
        onClick={onChangeStep(STEPPER_ITEMS[1])}
      >
        Confirm
      </Button>
    </>
  )
};
