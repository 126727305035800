import * as React from 'react';
import { FieldAttributes, useFormikContext } from 'formik';
import { UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import { Upload } from 'antd';
import axios from 'axios';

import UploadImg from 'src/assets/img/upload@2x.png';
import ScraperImg from 'src/assets/img/scraper@2x.png';
import { API_ATTACHMENTS } from 'src/constants';
import { AnyObject } from 'src/types';
import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  label?: string
};

export const PictureUploader = (props: Props) => {
  const { className, label, field } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext<AnyObject>();

  const tipText = label || 'Drop file here to upload';

  const handleChange = async (info: UploadChangeParam) => {
    if (info.file?.response?.attachment?.data?.attributes?.url) {
      setFieldValue(field.name, info.file?.response?.attachment?.data?.attributes?.url);
      setFieldTouched(field.name, true);
    }
  };

  return (
    <Upload
      className={classNames('form-uploader', className)}
      action={API_ATTACHMENTS}
      showUploadList={{
        showDownloadIcon: false,
        showRemoveIcon: false,
      }}
      headers={axios.defaults.headers.common}
      onChange={handleChange}
      progress={{
        strokeWidth: 3,
        format: percent => `${parseFloat(percent.toFixed(2))}%`,
      }}
    >
      <Show if={field.value}>
        <img className="preview-img" src={field.value} alt="preview"/>
      </Show>
      <Show if={!field.value}>
        <img className="upload-icon" src={UploadImg} alt="UploadImg"/>
        <div className="upload-text">{tipText}</div>
        <div className="upload-text small">or</div>
        <button className="app-button green" type="button">
          <img className="scraper-icon" src={ScraperImg} alt="ScraperImg"/>
          Select file
        </button>
      </Show>
    </Upload>
  );
};
