import { PayloadAction } from '@reduxjs/toolkit';
import { HeaderType } from '../types';

export const changeSelectedItem = (state: HeaderType, action: PayloadAction<string | null>) => {
  state.selectedItem = action.payload
};

export const changeHeaderState = (state: HeaderType, action: PayloadAction<boolean>) => {
  state.simplifyHeader = action.payload
};

export const changeMobileHeaderState = (state: HeaderType, action: PayloadAction<boolean>) => {
  state.simplifyMobileHeader = action.payload
};

