import { Moment } from 'moment';
import { getInvoices } from '../services';

export const onChangeYear = (date: Moment | null) => {
  if (!date) {
    getInvoices();
    return;
  }

  const year = date.format('YYYY');
  getInvoices(year)
};
