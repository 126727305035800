import * as React from 'react';
import { Form, Formik } from 'formik';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';
import { Button } from 'src/components/button';
import { useAppSelector } from 'src/stores';

import {
  BREADCRUMBS_SCHOOL,
  SCHOOL_SETTINGS_VALIDATION,
  ON_SUBMIT_SCHOOL_SETTINGS,
  MOUNT_SCHOOL_SETTINGS
} from '../../constants';
import { SchoolFields } from '../../components';
import { mountSchoolSettings } from '../../services';
import { onSubmitSchoolSettings } from '../../handlers';

export const SchoolSettings = () => {
  const school = useAppSelector(state => state.school.school);

  const initialValues = {
    name: school.name || '',
    phone: school.phone || '',
    website: school.website || '',
    email: school.email || ''
  };

  React.useEffect(() => {
    mountSchoolSettings();
  }, []);

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="School settings" to={ROUTES.settings} list={BREADCRUMBS_SCHOOL}/>
      <div className="page-content-body">
        <h1>School settings</h1>
        <Loader id={MOUNT_SCHOOL_SETTINGS}>
          <Formik
            initialValues={initialValues}
            validationSchema={SCHOOL_SETTINGS_VALIDATION}
            enableReinitialize
            onSubmit={onSubmitSchoolSettings}
          >
            <Form>
              <SchoolFields />
              <div className="button-block full-size">
                <Button
                  loaderId={ON_SUBMIT_SCHOOL_SETTINGS}
                  htmlType="submit"
                  className="green-button"
                >
                  Save changes
                </Button>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  )
};
