import * as React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES, TRIAL_SUBSCRIPTION } from 'src/constants';
import { useAppSelector } from 'src/stores';
import { handleChoosePlan } from '../../handlers';
import { YEARLY_PLAN } from '../../constants';

export const NoSub = () => {
  const isTrial = useAppSelector(state => state.auth?.school?.subscription_status === TRIAL_SUBSCRIPTION);
  const cookieAgreement = localStorage.getItem('cookieAgreement')

  if (!isTrial) return null;

  return (
    <>
      <h1>My subscription</h1>
      <div className="no-subs">
        <div className="no-subs-header">
          You are currently on our 3 months free trial.
        </div>
        <div className="no-subs-content">
          To enjoy the benefits of a premium account,<br />
          please upgrade now!
        </div>
        {cookieAgreement === 'true' && (
          <div onClick={handleChoosePlan(YEARLY_PLAN)} className="app-button green">
            Upgrade to Premium
          </div>
        )}
      </div>
    </>
  );
};
