import axios from 'axios';

import { store } from 'src/stores';
import { authActions } from 'src/stores/auth';
import { API_MY_PROFILE, API_MY_PROFILE_UPDATE, ROUTES } from 'src/constants';
import { history, loadingWrapper, requestWrapper, toast } from 'src/utils';

import { MOUNT_MY_DETAILS, ON_SUBMIT_MY_DETAILS } from '../constants';
import { ProfileBEValueType } from '../types';

const getProfileInfoRequest = requestWrapper(() => {
  return axios.get(API_MY_PROFILE);
});

export const getProfileInfo = async () => {
  const { success, response } = await getProfileInfoRequest();


  if (success) {
    store.dispatch(authActions.setProfileData(response.data));

    if(response.data?.user?.data?.attributes?.need_change_password) {
      history.push(ROUTES.passwords);
    }
  }
};

const changeProfileRequest = requestWrapper((payload: ProfileBEValueType) => {
  return axios.patch(API_MY_PROFILE_UPDATE, payload);
});

export const mountMyDetails = loadingWrapper(
  MOUNT_MY_DETAILS,
  async () => {
    await getProfileInfo();

    return true;
  });

export const changeProfile = loadingWrapper(
  ON_SUBMIT_MY_DETAILS,
  async (values: ProfileBEValueType) => {
    const { success } = await changeProfileRequest(values);

    if (success) {
      toast('Profile successfully updated', 'success');
      history.push(ROUTES.settings);
    }

    return success
  }
);
