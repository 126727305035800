import _ from 'lodash';
import { PayloadAction, current } from '@reduxjs/toolkit';
import { AnyObject } from 'src/types';
import { EditorStoreType } from '../types';
import { pushField, manageColumns } from '../services';
import { TEMPLATE_TYPES_CLASSES } from '../constants';

export const toggleChooseContent = (state: EditorStoreType, action: PayloadAction<boolean>) => {
  state.isOpenContent = action.payload;
};

export const toggleEdit = (state: EditorStoreType, action) => {
  if(action.payload?.name || action.payload?.type) {
    state.isEditing = action.payload?.name || null;
    state.editingType = action.payload?.type || '' ;
  } else {
    state.isEditing = action.payload;
  }
  
  state.isOpenContent = false;
};

export const addField = (state: EditorStoreType, action: PayloadAction<{ name: string, type: string }>) => {
  if(state.newsletterDefault.layout_type === TEMPLATE_TYPES_CLASSES.one_column) {
    state.fields.push(action.payload);
  } else {
    state.fields = pushField(current(state.fields), action.payload);
  }
};

export const setPrevValue = (state: EditorStoreType, action: PayloadAction<any>) => {
  state.prevValue = action.payload
};

export const deleteField = (state: EditorStoreType, action: PayloadAction<string>) => {
  _.remove(state.fields, item => item.name === action.payload);
};

export const setEvent = (state: EditorStoreType, action: PayloadAction<{ name: string, index: number} | null>) => {
  state.event = action.payload
};

export const setNewsletter = (state: EditorStoreType, action: PayloadAction<AnyObject>) => {
  state.newsletterDefault = action.payload as any;
  state.initialFormValues = action.payload.initialFormValues;
  state.fields = action.payload.fields;
};

export const setPublicUrl = (state: EditorStoreType, action: PayloadAction<string>) => {
  state.publicUrl = action.payload;
};

export const swapFields = (state: EditorStoreType, action: PayloadAction<AnyObject>) => {
  const { id1, id2 } = action.payload
  const [reordedItem] = state.fields.splice(id1, 1)
  state.fields.splice(id2, 0, reordedItem)
}


export const concatFields = (state: EditorStoreType, action: PayloadAction<AnyObject>) => {
  const { leftColumn, rightColumn } = action.payload;

  state.fields = manageColumns(leftColumn, rightColumn);
}

export const setColumns = (state: EditorStoreType, action: PayloadAction<{ leftColumn: Array<any>, rightColumn: Array<any> }>) => {
  
}

export const setFooterContent = (state:  EditorStoreType, action: PayloadAction<{ content: string }>) => {
  const { content } = action.payload;
  state.newsletterDefault.footerContent = content;
};



