import { toast as toastify } from 'react-toastify';

type StateType = 'success' | 'error' | 'warn' | 'info';

export const toast = (message: string, state?: StateType): void => {
  if (!!state) {
    toastify[state](message, {
      position: toastify.POSITION.BOTTOM_RIGHT
    });
    return;
  }

  toastify(message, {
    position: toastify.POSITION.BOTTOM_LEFT
  })
};
