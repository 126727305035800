import { PayloadAction } from '@reduxjs/toolkit';
import {AnyObject} from 'src/types';

import { AuthState } from './reducer';
import { AUTH_STATES } from 'src/constants';

export const changeAuthState = (state: AuthState, action: PayloadAction<0 | 1 | 2>) => {
  state.value = action.payload
};

export const setUserData = (state: AuthState, action: PayloadAction<AnyObject>) => {
  state.value = action.payload.value || AUTH_STATES.unauthorized;
  state.accessToken = action.payload.accessToken || null;
  state.user = action.payload.user?.data || {};
};

export const setProfileData = (state: AuthState, action: PayloadAction<AnyObject>) => {
  state.user = action.payload.user.data;
};

export const setSchoolData = (state: AuthState, action: PayloadAction<AnyObject>) => {
  state.school = action.payload;
};
