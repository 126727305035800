import * as React from 'react';
import { useFormikContext, useField } from 'formik';

import { useAppSelector } from 'src/stores';
import { Button } from 'src/components/button';
import { Show } from 'src/components/conditions';
import MarkPng from 'src/assets/img/mark@2x.png';

import { CREATE_LETTER_STEPPER, ON_SUBMIT_ON_BOARDING, LAYOUT_TYPES, LAYOUT_TYPES_WITH_LATEST } from '../constants';

export const Template = () => {
  const {currentStep, newsletters} = useAppSelector(({onBoarding, newsletter}) => ({
    currentStep: onBoarding.currentStep,
    newsletters: newsletter.list,
  }));
  const { setFieldValue } = useFormikContext();
  const [field] = useField('template');

  if (currentStep?.id !== CREATE_LETTER_STEPPER[1].id) return null;

  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">3</div>
        {CREATE_LETTER_STEPPER[1].question}
      </div>
      <div className="choose-template">
        {(newsletters.length > 0 ? LAYOUT_TYPES_WITH_LATEST : LAYOUT_TYPES).map(item => (
          <LayoutType
            key={`${item.id}-type`}
            isActive={field.value === item.id}
            handleClick={() => setFieldValue('template', item.id)}
            img={item.img}
            title={item.title}
            subTitle={item.subTitle}
          />
        ))}
      </div>

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="submit"
        className="app-button green"
      >
        Confirm
      </Button>
    </>
  )
};

type LayoutProps = {
  isActive: boolean,
  img: string,
  title: string,
  subTitle?: string,
  handleClick: (...args: any[]) => void
};

const LayoutType = (props: LayoutProps) => {
  const { isActive, img, title, subTitle, handleClick } = props;
  return (
    <div className="template-item" onClick={handleClick}>
      <Show if={isActive}>
        <img className="mark-icon" src={MarkPng} alt="MarkPng"/>
      </Show>
      <img className="template-img" src={img} alt="Template1Svg"/>
      <p>{title}</p>
      <Show if={Boolean(subTitle)}>
        <p className="sub-text">{subTitle}</p>
      </Show>
    </div>
  )
};
