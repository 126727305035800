import * as React from 'react';
import { FieldAttributes } from 'formik';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  additionalText?: string,
  textLink?: string,
  label: string,
};

export const TextArea = (props: Props) => {
  const { id, additionalText, textLink, label, form, field, className, rows = 5, ...rest } = props;
  const error = form.errors[props.field.name];

  return (
    <div className={classNames('form-input', className)}>
      <div className="label-block">
        <label htmlFor={id}>{label}</label>
        <Show if={Boolean(additionalText)}>
          <Link to={textLink || ''} className="link">{additionalText}</Link>
        </Show>
      </div>
      <textarea {...rest} {...field} value={field.value || ''} rows={rows} />
      {(form.touched[field.name] && !!error) && (
        <div className="form-error">
          {error}
        </div>
      )}
    </div>
  );
};
