import * as React from 'react';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { ROUTES } from 'src/constants';
import { Loader } from 'src/components/loader';

import {
  BREADCRUMBS_SUBSCRIPTION,
} from '../../constants';

import { PlansComponent } from '../../components';
import { ON_CHOOSE_PLAN } from '../../constants';

export const ChoosePlan = () => {
  return (
    <div className="page-content invite-user choose-plan">
      <Breadcrumbs title="Subscription" to={ROUTES.settings} list={BREADCRUMBS_SUBSCRIPTION}/>
      <div className="page-content-body">
        <Loader id={ON_CHOOSE_PLAN}>
          <h1>Choose suitable tariff plan</h1>
          <PlansComponent />
        </Loader>
      </div>
    </div>
  );
};
