import * as React from 'react';
import { useFormikContext, Field } from 'formik';
import RichTextEditor from 'react-rte';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { Popconfirm } from 'antd';

type Props = {
  name: string
};

export const TextEditorField: React.FC<Props> = (props) => {
  const { name } = props;

  const { setFieldValue } = useFormikContext();

  return (
    <div className="field-container">
      <div>
        <Field
          name={name}
          children={({field, form}) => {
            const handleChange = (value) =>
              form.setFieldValue(name, value);

            const handleBlur = () => 
              form.setFieldTouched(name, true);
            return (
              <RichTextEditor
                onBlur={handleBlur} // REQUIRED, AFTER npm i ADD onBlur?: (e: any) => void; to RichTextEditor lib props!!!!!
                value={field.value || RichTextEditor.createEmptyValue()}
                onChange={handleChange}
                toolbarConfig={{
                  // Optionally specify the groups to display (displayed in the order listed).
                  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
                  INLINE_STYLE_BUTTONS: [
                    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
                    {label: 'Italic', style: 'ITALIC'},
                    {label: 'Underline', style: 'UNDERLINE'}
                  ],
                  BLOCK_TYPE_DROPDOWN: [
                    {label: 'Normal', style: 'unstyled'},
                    {label: 'Heading Large', style: 'header-one', className: 'header-one-title'},
                    {label: 'Heading Medium', style: 'header-two'},
                    {label: 'Heading Small', style: 'header-three'}
                  ],
                  BLOCK_TYPE_BUTTONS: [
                    {label: 'UL', style: 'unordered-list-item'},
                    {label: 'OL', style: 'ordered-list-item'}
                  ]
                }}
                className="text-editor"
              />
            )
          }}
        />
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};