import * as Yup from 'yup';
import queryString from 'query-string';

import { history } from 'src/utils';

const getToken = () => {
  const token = queryString.parse(history.location.search).token;

  return token ? token.toString() : ''
};

export const InitialChangePasswordFormValue = {
  token: getToken(),
  password: '',
  password_confirmation: '',
};

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('A valid email address is required').required('A valid email address is required'), //Invalid email format  Field is required
});

export const changePasswordValidationScheme = Yup.object().shape({
  password: Yup.string().min(8, 'Password is too short').matches(
    /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase and one number").max(50, 'Password is to long').required('Field is required'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  .max(50, 'Password is to long').required('Field is required'),
});
