import * as React from 'react';

import { overlayActions } from 'src/stores/overlay';
import { store } from 'src/stores';

export const toggleOverlay = (trigger: boolean) => {
  store.dispatch(overlayActions.toggleOverlay(trigger))
};

export const useOverlay = () => {
  React.useEffect(() => {
    toggleOverlay(true);

    return () => {
      toggleOverlay(false)
    }
  }, [])
};
