import { LoginFormType, RegisterType, UserType } from '../types';

export const mapTo = (data: LoginFormType): UserType => {
  return {
    user: {
      email: data.email,
      password: data.password
    }
  }
};

export const mapToRegisterApi = (data: RegisterType, invite_token?: string) => {
  return {
    user: {
      email: data.email,
      invite_token
    }
  }
};
