export const INVITE_USER_FORM = {
  first_name: '',
  last_name: '',
  role: '',
  email: ''
};

export const PASSWORDS_FORM = {
  existing_password: '',
  password: '',
  confirm_password: ''
};

export const BILLING_INFO_FORM = {
  first_name: '',
  last_name: '',
  address: '',
  telephone: '',
  email: ''
};

export const MONTHLY_PLAN = 'monthly';
export const YEARLY_PLAN = 'yearly';

export const ON_SUBMIT_INVITE = 'ON_SUBMIT_INVITE';
export const GET_MANAGE_USERS = 'GET_MANAGE_USERS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_BILLING_INFO = 'GET_BILLING_INFO';
export const ON_CHOOSE_PLAN = 'ON_CHOOSE_PLAN';
export const ON_SUBMIT_MY_DETAILS = 'ON_SUBMIT_MY_DETAILS';
export const ON_SUBMIT_PASSWORDS = 'ON_SUBMIT_PASSWORDS';
export const ON_SUBMIT_SCHOOL_SETTINGS = 'ON_SUBMIT_SCHOOL_SETTINGS';
export const ON_SUBMIT_BILLING_INFO = 'ON_SUBMIT_BILLING_INFO';
export const ON_EDIT_ADMIN = 'ON_EDIT_ADMIN';
export const ON_LOGIN = 'ON_LOGIN';
export const ON_REGISTER = 'ON_REGISTER';
export const ON_FORGOT = 'ON_FORGOT';
export const ON_CHANGE_PASSWORD = 'ON_CHANGE_PASSWORD';

export const MOUNT_MANAGE_USERS = 'MOUNT_MANAGE_USERS';
export const MOUNT_MY_DETAILS = 'MOUNT_MY_DETAILS';
export const MOUNT_PASSWORD_UPDATE = 'MOUNT_PASSWORD_UPDATE';
export const MOUNT_SCHOOL_SETTINGS = 'MOUNT_SCHOOL_SETTINGS';
export const MOUNT_BILLING_INFO = 'MOUNT_BILLING_INFO';
export const MOUNT_PAYMENT_INFO = 'MOUNT_PAYMENT_INFO';
export const MOUNT_EDIT_ADMIN = 'MOUNT_EDIT_ADMIN';
