import { FormValuesType, LetterValuesType, SchoolType, NewsletterBEType } from '../types';
import { LAYOUT_TYPES_WITH_LATEST } from '../constants';

export const mapSchoolData = (values: FormValuesType): SchoolType => {
  return {
    school: {
      name: values.name,
      logo: values.logo,
      email: values.email,
      phone: values.phone,
      primary_color: values.primary_color,
      secondary_color: values.secondary_color,
    }
  }
};

export const mapLetter = (values: LetterValuesType): NewsletterBEType => {
  return {
    news_letter: {
      title: values.title,
      body: '',
      layout_type: LAYOUT_TYPES_WITH_LATEST.find(item => item.id === values.template)?.value || ''
    }
  }
};
