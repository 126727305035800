import { ROUTES } from 'src/constants';

export const BREADCRUMBS_ON_BOARDING_START = [
  {
    title: 'Dashboard',
    to: ROUTES.newsletters
  }, {
    title: 'Create your new newsletter',
  }
];

export const BREADCRUMBS_ON_BOARDING_STEPPER = (title: string, step: string) => [
  {
    title,
    to: ROUTES.newsletters
  }, {
    title: 'First newsletter',
    to: ROUTES.newsletters
  }, {
    title: step
  }
];

export const BREADCRUMBS_CREATE_LETTER = [
  {
    title: 'Dashboard',
    to: ROUTES.newsletters
  }, {
    title: 'Newsletters',
    to: ROUTES.newsletters
  }, {
    title: 'Create newsletter'
  }
];

export const BREADCRUMBS_SETTINGS = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
  }
];

export const BREADCRUMBS_MANAGE_USERS = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Manage Users',
  }
];

export const BREADCRUMBS_INVITE_USER = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Manage Users',
    to: ROUTES.manageUsers
  }, {
    title: 'Invite user'
  }
];

export const BREADCRUMBS_EDIT_USER = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Manage Users',
    to: ROUTES.manageUsers
  }, {
    title: 'Edit user'
  }
];

export const BREADCRUMBS_MY_DETAILS = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'My details',
  }
];

export const BREADCRUMBS_PASSWORDS = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Password settings',
  }
];

export const BREADCRUMBS_SUBSCRIPTION = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Subscription',
  }
];

export const BREADCRUMBS_SCHOOL = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'School settings',
  }
];

export const BREADCRUMBS_NEWSLETTER_BRANDING = [
  // {
  //   title: 'Dashboard',
  //   to: ROUTES.dashboard
  // }, 
  {
    title: 'Settings',
    to: ROUTES.settings
  }, {
    title: 'Newsletter branding',
  }
];

