import * as React from 'react';
import { useAppSelector } from 'src/stores';
import { ROUTES } from 'src/constants';

export const NotFound = () => {
  const isAuth = useAppSelector(state => Boolean(state.auth.value));
  const handleClick = () => {
    if (isAuth) {
      window.location.pathname = ROUTES.newsletters;
    } else {
      window.location.pathname = ROUTES.home;
    }
  };

  return (
    <div className="page-content newsletters">
      <div className="page-content-body">
        <div className="header-block">
          <h1 className="hide-mobile">Not Found</h1>
        </div>
          <div className="search-wrapper" style={{justifyContent: 'center', padding: '16px'}}>
            <button onClick={handleClick} className="app-button green">
              Return
            </button>
          </div>
      </div>
    </div>
  )
};
