import * as React from 'react';
import {Link} from 'react-router-dom';

import {ROUTES} from 'src/constants';

import EmailIcon from 'src/assets/img/email-icon.svg';
import MessageIcon from 'src/assets/img/telephone-icon.svg';
import TwitterIcon from 'src/assets/img/twitter-icon.svg';

import FirstBackground from 'src/assets/img/landing/footer-background.svg';
import SecondBackground from 'src/assets/img/landing/footer-bg-second.svg';
import SecondBackgroundMobile from 'src/assets/img/mobile/footer-bg.svg';

import CookieIcon from 'src/assets/img/cookie.svg';

import LogoImg from 'src/assets/img/logo.png';

type Props = {
  hideFirstBg?: boolean;
};

export const Footer = (props: Props) => {
  const {hideFirstBg} = props;

  const handleCookie = () => {
    localStorage.removeItem('cookieAgreement');
    window.location.reload();
  };

  return (
    <>
      <div className="landing-footer">
        <div className="footer-content">
          <div className="info-block">
            <div className="info-wrap">
              <img className="logo" src={LogoImg} alt="LogoImg"/>
              {/* <div>
                Furlong House, Willie Snaith Road<br/>
                Newmarket, Suffolk  CB8 7SG
              </div> */}
            </div>
            <div className="info-wrap">
              <div className="contact-item">
                <img src={EmailIcon} alt="EmailIcon" />
                <a href="mailto:hello@eschools.co.uk">hello@eschools.co.uk</a>
              </div>
              <div className="contact-item">
                <img src={MessageIcon} alt="MessageIcon" />
                <a href="tel:0330 380 1425">0330 380 1425</a>
              </div>
              <div className="contact-item">
                <img src={TwitterIcon} alt="TwitterIcon" />
                <a href="https://twitter.com/@eschools">@eschools</a>
              </div>
            </div>
            <div className="info-wrap hide-mobile">
              <Link to={ROUTES.privacyPolicy}>Privacy Policy</Link>
              <Link to={ROUTES.terms}>Terms & Conditions</Link>
              <Link to={ROUTES.cookies}>Cookies Policy</Link>
            </div>
          </div>

          <div className="divider-wrap">
            <div className="divider" />
          </div>
          <div onClick={handleCookie} className='cookie-icon'>
            <img src={CookieIcon} style={{width: '25px', height: '25px'}} />
          </div>
          <div className="other-info hide-mobile">© eSchools.news {`${new Date().getFullYear()}`}, all rights reserved</div>
          <div className="other-info hide-desktop">
            © eSchools.news {`${new Date().getFullYear()}`}, all rights reserved
          </div>
        </div>

        <div className="footer-bg">
          <img className={`hide-mobile ${hideFirstBg ? 'hide-desktop' : ''}`} src={FirstBackground} alt="FirstBackground"/>

          <img className="hide-desktop" src={SecondBackgroundMobile} alt="SecondBackgroundMobile"/>
        </div>
        <div className="second footer-bg">
          <img className="hide-mobile" src={SecondBackground} alt="SecondBackground"/>
        </div>
      </div>
    </>
  )
};
