import * as React from 'react';

import { useAppSelector } from 'src/stores';

import { Login } from './Login';
import { Register } from './Register';
import { ForgotPassword } from './ForgotPassword';

export const AuthWrap = () => {
  const { authType } = useAppSelector(state => ({
    showAuth: state.landing.showAuth,
    authType: state.landing.authType
  }));
  const Container = getContainer(authType);

  return (
    <div className="auth-wrap">
      {Container}
    </div>
  );
};

const getContainer = (type: string | null) => {
  switch (type) {
    case 'login':
      return <Login/>;

    case 'register':
      return <Register/>;

    case 'forgotPassword':
      return <ForgotPassword />;

    default:
      return <Login/>;
  }
};
