import { SERVER_URL } from 'src/constants';

export const Video = (link: string) => (index: number) => `
  <mj-text padding="6px 0">
  <video 
      className="letter-content-img"
      width="100%"
      height="auto"
      src="${SERVER_URL}${link}"
      controls
    ></video>
  </mj-text>
`;
