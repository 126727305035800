import * as React from 'react';

import { Footer } from '../../components';
import { changeHeaderType, changeMobileHeaderType } from '../../handlers';

export const Cookies = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(true);
  }, []);

  return (
    <>
      <div className='privacy-container'>
        <h1>Cookie Policy</h1>
        <p>Last edited: June 2023</p>
        <h2>1. Introduction</h2>
        <p>1.1 This Cookie Policy explains how eSchools News use cookies and similar technologies in our Services. It explains what these technologies are and why we use them, as well as your rights to control their use.</p>
        <p>1.2 Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by online service providers in order to (for example) make their websites or services work, or to work more efficiently, as well as to provide reporting information.</p>
        <p>1.3 eSchools may request cookies to be set on your device. We use cookies to allow our apps to function and let us know when you visit our websites, how you interact with us and to enrich your user experience. The cookies differ depending on the information. You can adapt your cookie preferences, although please note that blocking some types of cookies may impact your experience on our websites and the services we or the school using our sites are able to offer.</p>
        <p>1.4 Cookies set by the website owner or service provider (in this case, eSchools News) are called “first party cookies”. Cookies set by parties other than the website owner are called “third party cookies”. Third party cookies enable third party features or functionality to be provided on or through the website or service you are using (such as advertising, interactive content and analytics). The third parties that set these third party cookies can recognise your computer both when it visits the website or service in question and also when it visits certain other websites or services.</p>
        <p>1.5 Cookies have a limited lifespan. Cookies which only last a short time or end when you close your browser are called session cookies. Cookies which remain on your device for longer are called persistent cookies (these are the type of cookies that allow websites to remember your details when you log back onto them).</p>
        <p>1.6 Cookies are not harmful to your devices (like a virus or malicious code) but some individuals prefer not to share their information (for example, to avoid targeted advertising).</p>
        <p>1.7 We use cookies:</p>
        <ul>
          <li>In our own websites we use to market our product and support our customers</li>
        </ul>
        <h2>2. Different Types of Cookies</h2>
        <p>2.1 Advertising Cookies: Payment tools are covered under advertising cookies. eSchools does not partner with advertisers and ad networks to display advertising on our Websites and/or to manage and serve advertising on other sites.</p>

        <table>
          <tr>
            <th>Category</th>
            <th>Cookie</th>
          </tr>
          <tr>
            <td>Stripe</td>
            <td>__stripe_sid</td>
          </tr>
          <tr>
            <td>We use Stripe to collect payment for those who wish to purchase eSchools News.</td>
            <td>
              __stripe_mid
              <br />
              __stripe_orig_props
              <br />
              machine_identifier
            </td>
          </tr>
          <tr>
            <td>You can view Stripe Cookie Usage information here; <a href='https://stripe.com/gb/legal/cookies-policy' target='_blank'>https://stripe.com/gb/legal/cookies-policy</a></td>
            <td>
              stripe.csrf
              <br />
              private_machine_identifier
            </td>
          </tr>
        </table>

        <h2>3. How can I control cookies?</h2>
        <p>3.1 You have the right to decide whether to accept or reject cookies. Be aware that cookie preferences are set on a per device basis; therefore you may need to set your preferences on each device you use. Click on the cookie icon at the bottom left of our websites to change your cookie settings. Blocking cookies at a later stage does not guarantee the removal of a cookie that has been previously agreed to.</p>
        <p>3.2 Initial cookie pop-up banner: You can exercise preferences about what cookies are served on our Websites by selecting your preference from this modal which appears upon visiting an eSchools website/login screen and dashboard. You can also change your cookie preferences by clicking on the link on the footer of any page.</p>
        <p>3.3 Browser Controls: You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our Websites though your access to some functionality and areas of our Websites may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser’s help menu for more information.</p>
        <p>3.4 Disabling Most Interest Based Advertising: Most advertising networks offer you a way to opt out of Interest Based Advertising. If you would like to find out more information, please visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.</p>
        <p>3.5 Mobile Advertising: You can opt out of having your mobile advertising identifiers used for certain types of Interest Based Advertising, by accessing the settings on your Apple or Android mobile device and following the most recent published instructions. We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing.</p>
        <h2>4. How often will you update this Cookie Statement?</h2>
        <p>4.1 We may update this Cookie Statement from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and related technologies. The date at the top of this Cookie Statement indicates when it was last updated.</p>
        <p>4.2 If you have any questions about our use of cookies or other technologies, please email us at <a href='mailto:support@eschools.co.uk'>support@eschools.co.uk</a></p>
      </div>
      <Footer hideFirstBg />
    </>
  )
};
