import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'src/components/fields';

export const DetailsFields = () => {
  return (
    <>
      <Field name="first_name" label="First name" component={Input}/>
      <Field name="last_name" label="Last name" component={Input}/>
      <Field name="email" label="Email address" className="full-size" component={Input}/>
    </>
  )
};
