import * as React from 'react';

type Props = {
  if: boolean,
  children: any,
};

export const Show: React.FC<Props> = (props) => {
  const { children } = props;

  if (props.if) return <>{children}</>;

  return null;
};
