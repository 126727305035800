import * as React from 'react';

import OnBoardingPng from 'src/assets/img/on-boarding@2x.png';
import { useAppSelector } from 'src/stores';

import { onChangeStep } from '../handlers';
import { STEPPER_ITEMS } from '../constants';

export const GetStarted = () => {
  const show = useAppSelector(state => !state.onBoarding.currentStep);

  if (!show) return null;

  return (
    <div className="get-started">
      <img className="page-img" src={OnBoardingPng} alt="OnBoardingPng"/>
      <h1 className="start-screen-title">Welcome to eSchools News</h1>
      <button className="ant-btn app-button green" onClick={onChangeStep(STEPPER_ITEMS[0])}>
        Get started
      </button>
    </div>
  )
};
