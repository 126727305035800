import shortID from 'shortid';

export const splitFields = (fields) => {
  const leftColumn = [];
  const rightColumn = [];

  fields.forEach((field, index) => {
    if(index % 2 !== 0 && field) {
      rightColumn.push(field);
    } else if(index % 2 == 0 && field) {
      leftColumn.push(field);
    }
  });

  return { leftColumn, rightColumn }

  // return { leftColumn: fields.filter((item, index) => !(index % 2)), rightColumn: fields.filter((item, index) => index % 2)} ;
}

const newEmptySpace = () => ({ name: shortID(), type: 'empty-space', value: 'required' });

const clearEmptySpace = (arr: Array<any>) => {
  let lastElementIndex;

  arr.forEach((item, i) => { 
    if(item?.type !== 'empty-space') {
      lastElementIndex = i;
    }
  });

  // if(lastElementIndex) {
  //   return arr.slice(0, lastElementIndex)
  // } else {
  //   return [];
  // }
  return arr.filter((item, i) => item?.type !== 'empty-space' || i < lastElementIndex );
};

const fillColumnWithEmptySpace = (column: Array<any>) => {
  const result = [];

  const maxValue = column.length < 13 ? 13 : column.length;

  for (let i = 0; i < maxValue; i++) {
    if(column?.[i]) {
      result.push(column[i]);
    } else {
      result.push(newEmptySpace());
    }
  }

  return result;
};

const mergeColumns = (leftColumn: Array<any>, rightColumn : Array<any> ) => {
  const result = [];

  leftColumn.forEach((item, i) => {
      result.push(item, rightColumn?.[i])
    }
  );

  return result;
};

export const manageColumns = (leftColumn: Array<any>, rightColumn : Array<any>) : Array<any> => {
  const leftColumnCleared = [ ...clearEmptySpace(leftColumn) ];
  const rightColumnCleared = [ ...clearEmptySpace(rightColumn) ];

  const finalLeftColumn = [ ...fillColumnWithEmptySpace(leftColumnCleared) ];
  const finalRightColumn = [  ...fillColumnWithEmptySpace(rightColumnCleared) ];

  return [ ...mergeColumns(finalLeftColumn, finalRightColumn) ];
};

export const pushField = (fields, field) => {
  const { leftColumn, rightColumn } = splitFields(fields);

  const leftColumnCleared = [ ...clearEmptySpace(leftColumn)];
  const rightColumnCleared = [ ...clearEmptySpace(rightColumn)];

  if(leftColumnCleared?.length <= rightColumnCleared?.length) {
    leftColumnCleared?.push(field)
  } else if(leftColumnCleared?.length > rightColumnCleared?.length) {
    rightColumnCleared?.push(field)
  } 

  // if(leftColumn?.length >= rightColumn?.length) {
  //   leftColumn?.length ? leftColumn.unshift(field) : leftColumn?.push(field)
  // }

  // if(leftColumn?.length < rightColumn?.length) {
  //   rightColumn?.length ? rightColumn.unshift(field) : rightColumn?.push(field)
  // } 
  
  // const leftColumnCleared = [ ...clearEmptySpace(leftColumn)];
  // const rightColumnCleared = [ ...clearEmptySpace(rightColumn)];

  // if(leftColumnCleared?.length >= rightColumnCleared?.length) {
  //   leftColumnCleared.push(field)
  // } else {
  //   rightColumnCleared.push(field);
  // }

  return [ ...manageColumns(leftColumnCleared, rightColumnCleared)];
};

