import axios from 'axios';

import { MJML_URL } from 'src/constants';
import { requestWrapper } from 'src/utils';

const getHtmlRequest = requestWrapper((mjml: string) => {
  return axios.post(MJML_URL, { mjml }, {
    auth: {
      username: 'ccd39ac1-c86b-451e-bcb7-41e683f8d5ab',
      password: 'c0f74226-ad34-4baf-a7c9-a1c883a37ec8'
    }
  });
});

export const mjmlToHtml = async (mjml: string) => {
  const { success, response } = await getHtmlRequest(mjml);

  if (success) {
    return response.data.html
  }
};
