import { createSlice } from '@reduxjs/toolkit';

import { changeCurrentStep } from '../actions';

export interface OnBoardingState {
  currentStep: {
    id: string,
    question: string
  } | null
}

const initialState = {
  currentStep: null
} as OnBoardingState;

const onBoardingSlice = createSlice({
  name: 'onBoarding',
  initialState,
  reducers: {
    changeCurrentStep
  }
});

export const onBoardingReducer = onBoardingSlice.reducer;
export const onBoardingActions = onBoardingSlice.actions;
