import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { MENU_ITEMS } from '../../constants';
import { handleChangeNavigation } from '../../handlers';

type Props = {
  mobile?: boolean,
  afterClick?: () => void
};

export const NavigationMenu = (props: Props) => {
  const selectedTab = useAppSelector(state => state.landing.selectedItem);
  const selectedKeys = selectedTab ? [selectedTab] : [];

  const items = MENU_ITEMS.map(item => (
    <Menu.Item onClick={props.afterClick} key={item.id}>
      <Link to={item.path}>
        {item.name}
      </Link>
    </Menu.Item>
  ));

  return (
    <Menu
      className={classNames({
        'hide-mobile': !props.mobile
      })}
      onClick={handleChangeNavigation}
      mode={props.mobile ? 'vertical' : 'horizontal'}
      selectedKeys={selectedKeys}
      openKeys={MENU_ITEMS.map(item => item.id)}
    >
      {items}
    </Menu>
  )
};
