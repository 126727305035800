import * as React from 'react';

import { EditUser } from './EditUser';
import { onEditSubs, mountSubsEdit } from '../../handlers';

export const SubscriberEditWrapper = () => {
  React.useEffect(() => {
    mountSubsEdit();
  }, [])
;
  return (
    <EditUser
      onSubmit={onEditSubs}
    />
  )
};
