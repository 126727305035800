import * as React from 'react';

import BlobImg from 'src/assets/img/landing/blob.svg';
import ArrowSvg from 'src/assets/img/mobile/arrow-in-create-block.svg';

import { ICONS } from '../constants';
import { TryItButton } from './TryItButton';

export const GetStartedBlock = () => {
  const iconList = ICONS.map(item => (
    <img
      key={item}
      className={`icon-block ${item}`}
      src={require(`src/assets/img/landing/${item}-icon.svg`).default}
      alt={item}
    />
  ));

  return (
    <>
      <div className="get-started-block">
        <div className="info-block">
          <h2>Get started today</h2>
          <div className="divider" />
          <div className="info">
            Build your first newsletter in minutes.<br/>
            <b>Try it free for 3 months…</b> no payment details required!
          </div>
          <TryItButton />
        </div>

        {iconList}
        <img className="arrow-img hide-desktop" src={ArrowSvg} alt="ArrowSvg"/>

        <div className="blob-bg">
          <img src={BlobImg} alt="BlobImg"/>
        </div>
      </div>
    </>
  )
};
