import { PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionStoreType, BillingInfoType } from '../types';

export const setBillingInfo = (state: SubscriptionStoreType, action: PayloadAction<BillingInfoType>) => {
  state.billingInfo = action.payload;
};

export const setClientSecret = (state: SubscriptionStoreType, action: PayloadAction<string | null>) => {
  state.clientSecret = action.payload;
};
