import * as React from 'react';
import { Layout } from 'antd';
import { Formik, Form } from 'formik';

import { AppFooter } from 'src/modules/layout/containers/AppFooter';
import { useAppSelector } from 'src/stores';
import { Loader } from 'src/components/loader';

import {
  Header,
  ControlsSubHead
} from '../components';
import { LetterContainer } from './LetterContainer';
import { ChooseContent } from './ChooseContent';
import { ActiveField } from './ActiveField';

import { mount } from '../services';
import { onSaveLetter } from '../handlers';
import { GET_NEWSLETTER } from '../constants';
import { getValidation } from '../services';

export const Editor = () => {
  const {initialFormValues, isEditing, editingType} = useAppSelector(state => state.editor);

  React.useEffect(() => {
    mount()
  }, []);

  const validate = (values: any): object => {
    let errors = {};

    errors = getValidation(editingType, isEditing, values);

    return errors;
  };

  return (
    <div className="newsletter-editor">
      <Loader id={GET_NEWSLETTER}>
        <Formik
          initialValues={initialFormValues}
          enableReinitialize
          onSubmit={onSaveLetter}
          validate={validate}
        >
          <Form>
            <Layout className="layout">
              <Header withEditorButtons/>
              <ControlsSubHead />
              <Layout.Content>
                <LetterContainer />
                <ChooseContent />
                <ActiveField />
              </Layout.Content>
              <AppFooter />
            </Layout>
          </Form>
        </Formik>
      </Loader>
    </div>
  )
};
