import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { LetterHeading, LetterContent } from '../components';
import { onOpenChooseContent } from '../handlers';
import { EMAIL_FIELDS_ID } from '../constants';
import LetterFooter from '../components/LetterFooter';

export const LetterContainer = () => {
  const hide = useAppSelector(
    state => state.editor.isOpenContent || state.editor.isEditing
  );

  return (
    <div className={classNames('letter-container-wrap', { 'hide-mobile': hide })}>
      <div className="letter-container" id={EMAIL_FIELDS_ID}>
        <LetterHeading />
        <div className="app-button add-content hide-desktop" onClick={onOpenChooseContent}>
          ADD CONTENT
        </div>
        <LetterContent />
        <LetterFooter />
      </div>
      <div className={classNames('controls-buttons-holder', { hide })}>
        <button className="app-button blue">
          Save draft
        </button>
      </div>
    </div>
  )
};
