import { store } from 'src/stores';

import { editorActions } from '../reducers';

export const onSaveField = () => {
  store.dispatch(editorActions.toggleEdit(null));
  store.dispatch(editorActions.setPrevValue(null));
};

export const onDiscard = (changeField: (field: string, value: any) => void) => () => {
  const { prevValue, isEditing } = store.getState().editor;

  changeField(isEditing!, prevValue);

  store.dispatch(editorActions.toggleEdit(null));
  store.dispatch(editorActions.setPrevValue(null));
};

export const onEditField = (name: string, type: string) => () => {
  store.dispatch(editorActions.toggleEdit({ name, type }));
};

export const onDelete = (name: string) => () => {
  store.dispatch(editorActions.deleteField(name));
  store.dispatch(editorActions.toggleEdit(null));
};
