import { createSlice } from '@reduxjs/toolkit';

import {
  setBillingInfo,
  setClientSecret
} from '../actions';
import { SubscriptionStoreType } from '../types';
import { BILLING_INFO_FORM } from '../constants';

const initialState = {
  billingInfo: null,
  clientSecret: null
} as SubscriptionStoreType;

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setBillingInfo,
    setClientSecret
  }
});

export const subscriptionReducer = subscriptionSlice.reducer;
export const subscriptionActions = subscriptionSlice.actions;
