import * as React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';
import NewsLetterImg from 'src/assets/img/newsletter.png';

import { NEWSLETTER_STATUSES, STATUS_TO_LABEL } from '../constants';
import { Options } from './Options';

import { Link } from 'react-router-dom';

export const NewsletterList: React.FC<{}> = () => {
  const list = useAppSelector(state => state.newsletter.list);

  const getDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY')
  };

  const items = list.map(item => (
    <div className="newsletters-item" key={`newsletter-${item.id}`}>
      <div className="image-block">
        <img src={NewsLetterImg} alt="NewsLetterImg"/>
        <Status mobile value={item.status} />
      </div>
      <div className="info-block">
        <div className="title">
          <Link style={{ color: 'inherit' }} to={`/app/newsletters/editor/${item.id}`}>{item.title}</Link>
          <Status value={item.status} />
        </div>
        <div className="last-seen">
          Last updated:
          <span className="date">{getDate(item.updated_at)}</span>
        </div>
      </div>
      <Options letterId={item.id} status={item.status}/>
    </div>
  ));

  return <>{items}</>
};

const Status = (props: { mobile?: boolean, value: NEWSLETTER_STATUSES }) => {
  const { mobile, value } = props;
  return (
    <div className={classNames('letter-status', {
      mobile,
      published: value === NEWSLETTER_STATUSES.published
    })}>
      {STATUS_TO_LABEL[value]}
    </div>
  )
};
