import { createSlice } from '@reduxjs/toolkit';

import {
  changeActiveFilter,
  addUsers,
  changeInitialValues
} from '../actions';
import { ManageUsersStore } from '../types';
import { ADMIN_USERS_FILTER, INVITE_USER_FORM } from '../constants';

const initialState = {
  activeFilter: ADMIN_USERS_FILTER,
  initialValues: INVITE_USER_FORM,
  users: []
} as ManageUsersStore;

const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    changeActiveFilter,
    addUsers,
    changeInitialValues
  }
});

export const manageUsersReducer = manageUsersSlice.reducer;
export const manageUsersActions = manageUsersSlice.actions;
