import moment from 'moment';
import RichTextEditor from 'react-rte';
import { AnyObject } from 'src/types';

import { CONTENT_ITEMS } from '../constants';
import { generateHTML } from './mailMapper';

export const mapDataToForm = (data: AnyObject) => {
  const code = JSON.parse(data.news_letter.data.attributes.code || '[]');
  const fields = code.map((item: { name: string, type: string }) => ({
    name: item.name,
    type: item.type
  }));
  const initialFormValues = code.reduce((result: AnyObject, value: AnyObject) => {
    return {
      ...result,
      [value.name]: transformToFormValue(value.type, value.value)
    }
  }, {});
  initialFormValues.title = data.news_letter.data.attributes.title;

  return {
    ...data.news_letter.data.attributes,
    id: data.news_letter.data.id,
    fields,
    initialFormValues
  };
};

export const mapDataFrom = (fields: { name: string, type: string }[], data: AnyObject) => {
  const code = fields
    .map(item => ({
      ...item,
      value: getExactProperty(item.name, item.type, data)
    }))
    .filter(item => item.value);
  const body = generateHTML(code);

  return {
    title: data.title,
    code: JSON.stringify(code),
    body
  }
};

const getExactProperty = (name: string, type: string, storage: AnyObject) => {
  switch (type) {
    case CONTENT_ITEMS[1].id:
      return storage[name] && storage[name].toString('html');

    case CONTENT_ITEMS[2].id:
      if (!storage[name]?.value) return null;

      return {
        ...storage[name],
        value: storage[name].value.toString('html')
      };

    case CONTENT_ITEMS[3].id:
      return storage[name]?.id;

    case CONTENT_ITEMS[13].id:
      return 'required';
    default:
      return storage[name];
  }
};

const transformToFormValue = (type: string, value: any) => {
  switch(type) {
    case CONTENT_ITEMS[1].id:
      return RichTextEditor.createValueFromString((value || ''), 'html');

    case CONTENT_ITEMS[2].id:
      return {
        ...value,
        value: RichTextEditor.createValueFromString((value?.value || ''), 'html')
      };
      
    case CONTENT_ITEMS[3].id:
      return { id: value };

    case CONTENT_ITEMS[11].id:
      return value.map((item: AnyObject) => ({
        ...item,
        date: moment(item.date),
        time: item.time ? moment(item.time) : null,
      }));

    // case CONTENT_ITEMS[13].id:
    //   return value.map((item: AnyObject) => ({
    //     ...item,
    //     date: moment(item.date),
    //     time: moment(item.time)
    //   }));

    default:
      return value
  }
};
