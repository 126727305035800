import * as React from 'react';

import { modalActions } from 'src/stores/modal';
import { store } from 'src/stores';

export const toggleModal = (trigger: boolean) => {
  store.dispatch(modalActions.toggleModal(trigger))
};

export const useModal = () => {
  React.useEffect(() => {
    toggleModal(true);

    return () => {
      toggleModal(false)
    }
  }, [])
};
