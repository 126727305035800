import * as React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { Show } from 'src/components/conditions';

import { onLogout } from '../handlers';
import { onSavePdf } from 'src/modules/editor/handlers';

type Props = {
  withEditorButtons?: boolean,
  onPublish?: (...args: any[]) => void
};

export const HeaderAdditionalBlock: React.FC<Props> = (props) => {
  const { withEditorButtons, onPublish } = props;

  return (
    <div className="header-additional-block">
      <Show if={Boolean(withEditorButtons)}>
        {/* <button type="button" className="app-button blue" onClick={onSavePdf}>
          Download PDF
        </button> */}
        <Link to={ROUTES.newsletters} className="app-button red hide-mobile">
          Exit/discard
        </Link>
        <button type="submit" className="app-button blue hide-mobile">
          Save draft
        </button>
        <button type="submit" className="app-button green hide-mobile" onClick={onPublish}>
          Publish
        </button>
      </Show>
      <button type="button" className="app-button blue" onClick={onLogout}>
        Logout
      </button>
    </div>
  )
};
