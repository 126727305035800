import * as React from 'react';

import { BillingInfo } from '../Subscription';
import { handleUpdateBillingInfo } from '../../handlers';

export const BillingInfoUpdate = () => {
  return (
    <BillingInfo submit={handleUpdateBillingInfo} saveBtn={(
      <button className="app-button green">
        Save
      </button>
    )}/>
  )
};
