import * as React from 'react';

import SearchIcon from 'src/assets/img/search-icon.png';
import { useAppSelector } from 'src/stores';

import { onChangeSearch } from '../handlers';

export const Search = () => {
  const value = useAppSelector(state => state.newsletter.controls.search_string);

  return (
    <div className="search-input-wrap">
      <img className="search-icon" src={SearchIcon} alt="search"/>
      <input placeholder="Search newsletters" onChange={onChangeSearch} value={value}/>
    </div>
  )
};
