import axios from 'axios';

import { API_SCHOOLS, ROUTES } from 'src/constants';
import { history, loadingWrapper, requestWrapper } from 'src/utils';

import { ON_SUBMIT_ON_BOARDING } from '../constants';
import { FormValuesType } from '../types';
import { serialize } from 'object-to-formdata';

const createSchoolRequest = requestWrapper((payload: FormValuesType) => {
  const data = serialize(payload);
  return axios.post(API_SCHOOLS, data, {
    headers: {
      'Content-Type': `multipart/form-data;`
    }
  });
});

export const createSchool = loadingWrapper(
  ON_SUBMIT_ON_BOARDING,
  async (values: FormValuesType) => {
    const { success } = await createSchoolRequest(values);

    if (success) {
      history.push(ROUTES.newsletters);
    }

    return success
  }
);
