import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import { Input } from 'src/components/fields';
import { Button } from 'src/components/button';

import { STEPPER_ITEMS, ON_SUBMIT_ON_BOARDING } from '../constants';
import { Form, Formik } from 'formik';
import { onSubmitMyDetails } from '../handlers';
import { onChangeStep } from '../handlers';


export const SetEmail = () => {
  const currentStep = useAppSelector(state => state.onBoarding.currentStep);
  const { values } = useFormikContext<{ email: string }>();

  if (currentStep?.id !== STEPPER_ITEMS[2].id) return null;

  const handleConfirm = () => {
    onSubmitMyDetails(values, onChangeStep(STEPPER_ITEMS[3]));
  }

  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">3</div>
        {STEPPER_ITEMS[2].question}
      </div>
      <Field name="email" className="school-input" label="School email" component={Input}/>

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="submit"
        className="app-button green"
        onClick={onChangeStep(STEPPER_ITEMS[3])}
        disabled={!values.email}
      >
        Confirm
      </Button>
    </>
  )
};
