import * as React from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import { handlePayment } from '../../handlers';

export const CardFields = () => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <form className="payment-form" onSubmit={handlePayment(stripe, elements)}>
      <div className="form-input full-size">
        <CardElement
          options={{ hidePostalCode: true }}
          className="cards-block input-styles"
        />
      </div>
      <div className="button-holder full-size">
        <button
          className="app-button green"
          type="submit"
          disabled={!stripe}
        >
          Save
        </button>
      </div>
    </form>
  )
};
