import * as React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import { useFormikContext, FieldAttributes } from 'formik';

type OptionType = {
  id: string | number,
  value: string | number | null | React.ReactNode
};

type Props = FieldAttributes<any> & {
  options: Array<OptionType>,
  defaultValue?: string | number | null | React.ReactNode
}

export const Dropdown = (props: Props) => {
  const { options, defaultValue, className, label, id } = props;
  const { name } = props.field;

  const context = useFormikContext();

  const handleChange = (id: string | number) => {
    const value = options.find((item: OptionType) => item.id === id);
    context.setFieldValue(name, value.id);
  };

  return (
    <div className={classNames('form-input', className)}>
      <div className="label-block">
        <label htmlFor={id}>{label}</label>
        <Select
          className={classNames('form-dropdown', className)}
          onChange={handleChange}
          defaultValue={defaultValue ? defaultValue : null}
        >
          {options.map((option: OptionType) => (
            <Select.Option
              key={option.id}
              value={option.id}
            >
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
};
