import * as React from 'react';
import { Form, Formik, Field } from 'formik';
import { Button } from 'antd';
import { Button as CustomButton } from 'src/components/button';

import { ROUTES } from 'src/constants';
import AuthBlob from 'src/assets/img/landing/auth-blob.svg';
import { WhiteCross } from 'src/components/icons';

import { InitialAuthFormValue, forgotPasswordValidationSchema } from '../../form';
import { Input, Checkbox } from '../../components';
import { handleToggleAuth, onSubmitForgotPassword } from '../../handlers';
import { ON_FORGOT } from '../../../settings/constants'

export const ForgotPassword = () => {
  return (
    <div className="auth-container">
      <img className="img-blob" src={AuthBlob} alt="AuthBlob" />
      <div className="heading-block">
        <Button
          className="question-button"
          type="text"
          onClick={handleToggleAuth(true, 'register')}
        >
          Not a user?
        </Button>
        <Button
          type="text"
          shape="circle"
          icon={WhiteCross}
          onClick={handleToggleAuth(false)}
        />
      </div>
      <h1>Forgot Password</h1>
      <div className="divider"/>

      <Formik
        initialValues={InitialAuthFormValue}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={onSubmitForgotPassword}
      >
        <Form>
          <Field
            name="email"
            label="EMAIL ADDRESS"
            component={Input}
          />

          <CustomButton
            className="log-btn"
            htmlType="submit"
            shape="round"
            loaderId={ON_FORGOT}
          >
            Submit
          </CustomButton>
        </Form>
      </Formik>
    </div>
  );
};
