import * as React from 'react';
import { useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import ArrowIcon from 'src/assets/img/chevron@2x.png';

import { onBackToEventList, onBackToLetter, onDiscard, onSaveField } from '../handlers';
import { Popconfirm } from 'antd';

export const FieldControls = () => {
  const {editingType, isEditing, fields, newsletterDefault} = useAppSelector(state => state.editor);
  const prevValue = useAppSelector(state => state.editor?.prevValue);
  const isEditEvent = useAppSelector(state => Boolean(state.editor.event));
  const {footerContent} = newsletterDefault;
  const {
    setFieldValue,
    submitForm,
    // touched,
    isValid,
    values,
  } = useFormikContext();

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSave = () => {
    onSaveField();
    submitForm();
  };

  if (isEditEvent) {
    return (
      <div className="field-desktop-controls">
        <div className="cancel-button" onClick={onBackToEventList}>
          <img className="arrow-left" src={ArrowIcon} alt="ArrowIcon"/>
          Back to event list
        </div>
      </div>
    )
  }
  // const touchedKeys = Object.keys(touched);
  // const notValidate = ['divider', 'footer'];
  // console.log(touchedKeys, touchedKeys.includes(isEditing), isEditing, (!touchedKeys[0] && notValidate.indexOf(editingType) < 0), notValidate.indexOf(editingType), isValid)
  // console.log(touchedKeys, isEditing, editingType, isValid, values[isEditing]);
  // console.log(values[isEditing], isValid);
  const selectedField = fields.filter(field => field.name === isEditing)[0];
  const isFooter = selectedField?.type === 'footer';

  return (
    <div className="field-desktop-controls-container">
      <h2 className="field-desktop-headline">{ capitalizeFirstLetter(editingType.split('-').join(' ')) }</h2>
      <div className="field-desktop-controls">
        {prevValue ? (
          <Popconfirm placement="topLeft" onConfirm={onBackToLetter(setFieldValue)} title="Are you sure you want to discard changes?" okText="Yes" cancelText="No">
            <div className="cancel-button">
              <img className="arrow-left" src={ArrowIcon} alt="ArrowIcon"/>
              Cancel
            </div>
          </Popconfirm>
        ) : (
          <div className="cancel-button" onClick={onBackToLetter(setFieldValue)}>
            <img className="arrow-left" src={ArrowIcon} alt="ArrowIcon"/>
            Cancel
          </div>
        )}
        <div className="buttons-holder">
          <button type='button' className="app-button blue" onClick={onDiscard(setFieldValue)}>
            Discard changes
          </button>
          <button
            type="submit"
            className="app-button green"
            onClick={handleSave}
            // disabled={(((!touchedKeys[0] || !touchedKeys.includes(isEditing)) && !values[isEditing]) && notValidate.indexOf(editingType) < 0) || !isValid}
            // disabled={(notValidate.indexOf(editingType) < 0 && !isValid) || (notValidate.indexOf(editingType) < 0 && !values[isEditing])} // main
            // disabled={!isValid}

            // disabled={!isValid || (notValidate.indexOf(editingType) < 0 && !touched[isEditing])} // last!
            disabled={!isValid || (!isFooter && !values[isEditing]) || (isFooter && !footerContent)}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  )
};
