import { createSlice } from '@reduxjs/toolkit';

import { toggleHeader, changeSelectedTab } from '../actions';

const initialState = {
  withHeader: true,
  withFooter: true,
  selectedTab: null
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleHeader,
    changeSelectedTab
  }
});

export const layoutReducer = layoutSlice.reducer;
export const layoutActions = layoutSlice.actions;
