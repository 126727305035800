import { createSlice } from '@reduxjs/toolkit';

import { startLoad, success, failed } from './actions';
import { LoaderState } from './types';

const initialState = {} as LoaderState;

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    startLoad,
    success,
    failed
  }
});

export const loaderReducer = loaderSlice.reducer;
export const loaderActions = loaderSlice.actions;
