import * as React from 'react';

export const BurgerMenu = (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="43" fill="none" viewBox="0 0 50 43">
    <path fill="#2f3283" fillRule="evenodd" d="M46.092.002H40.34c-1.001.014-1.958.412-2.674 1.113-.715.7-1.134 1.649-1.17 2.649v4.1h9.6c1.028-.023 2.006-.448 2.724-1.182.719-.735 1.121-1.722 1.121-2.75s-.402-2.015-1.12-2.75C48.102.448 47.124.023 46.097 0l-.006.002zm.05 34.056h-5.75c-1.001.014-1.958.412-2.674 1.113-.715.7-1.134 1.648-1.17 2.649v4.1h9.6c1.028-.023 2.006-.448 2.724-1.183.719-.734 1.121-1.721 1.121-2.749s-.402-2.015-1.12-2.75c-.719-.734-1.697-1.159-2.724-1.182l-.006.002zM3.883 17.089h5.752c1 .014 1.958.412 2.673 1.113.716.7 1.135 1.648 1.17 2.649v4.1h-9.6c-1.028-.023-2.005-.448-2.724-1.183-.718-.734-1.12-1.721-1.12-2.749s.402-2.015 1.12-2.75c.719-.734 1.696-1.159 2.724-1.182l.006.002zm42.17 7.898h-22.2c-1.036-.023-2.023-.452-2.748-1.193-.725-.742-1.131-1.738-1.131-2.775s.406-2.033 1.131-2.775c.725-.741 1.712-1.17 2.749-1.193h22.206c1.037.023 2.024.452 2.749 1.193.725.742 1.13 1.738 1.13 2.775s-.405 2.033-1.13 2.775c-.725.741-1.712 1.17-2.75 1.193h-.005zM26.125 8.015H3.917c-1.037-.023-2.024-.452-2.749-1.193C.443 6.08.037 5.084.037 4.047s.406-2.033 1.131-2.775C1.893.531 2.88.102 3.917.08h22.207c1.037.023 2.024.452 2.749 1.193.725.742 1.13 1.738 1.13 2.775s-.405 2.033-1.13 2.775c-.726.741-1.712 1.17-2.75 1.193zm-.038 33.987H3.88c-1.037-.023-2.024-.452-2.749-1.193C.406 40.067 0 39.07 0 38.034s.406-2.033 1.131-2.775c.725-.741 1.712-1.17 2.749-1.193h22.206c1.037.023 2.024.452 2.749 1.193.725.742 1.13 1.738 1.13 2.775s-.405 2.033-1.13 2.775c-.726.741-1.712 1.17-2.75 1.193z" clipRule="evenodd"/>
  </svg>
);

export const WhiteCross = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <rect width="23" height="3" fill="#fff" rx="1.5" transform="scale(-1 1) rotate(-45 12.778 35.97)"/>
    <rect width="23" height="3" x="3.308" y="19.571" fill="#fff" rx="1.5" transform="rotate(-45 3.308 19.571)"/>
  </svg>
);
