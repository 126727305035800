import { PayloadAction } from '@reduxjs/toolkit';
import { LoaderState } from './types';

export const startLoad = (state: LoaderState, action: PayloadAction<string>) => {
  state[action.payload] = 'pending';
};

export const success = (state: LoaderState, action: PayloadAction<string>) => {
  state[action.payload] = 'successful';
};

export const failed = (state: LoaderState, action: PayloadAction<string>) => {
  state[action.payload] = 'failure';
};
