import * as React from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import {getPrimaryTextColor} from '../utils';

import ArrowIcon from 'src/assets/img/chevron@2x.png';
// import PointerIcon from 'src/assets/img/editor/pionter.png';
// import MailIcon from 'src/assets/img/editor/email.png';
// import PhoneIcon from 'src/assets/img/editor/phone.png';

export const LetterHeading = () => {
  const {
    name,
    // phone,
    // email,
    // website,
    logo,
    primary_color
  } = useAppSelector(state => state.auth.school) || {};
  // const { email } = useAppSelector(state => state.auth.user?.attributes || { email: '' });

  const [open, changeOpen] = React.useState(false);
  const { values } = useFormikContext<{ title: string }>();
  const textColor = getPrimaryTextColor();

  const onOpen = () => changeOpen(!open);

  return (
    <>
      <div className={classNames('letter-school-info', { active: open })}>
        <div className="logo-row">
          <img className="logo" src={logo?.url} alt="school-logo"/>
          {/* <img className="arrow-up" src={ArrowIcon} alt="ArrowIcon" onClick={onOpen}/> */}
        </div>
        {/* <div className="school-media-info">
          <img src={PointerIcon} alt="media" />
          <a href={website} target="_blank" rel="noreferrer">{website}</a>
          <img src={MailIcon} alt="media" />
          <a href={`mailto:${website}`}>{email}</a>
          <img src={PhoneIcon} alt="media" />
          <a href={`tel:${phone}`}>{phone}</a>
        </div> */}
      </div>
      <div className={classNames('letter-heading', { active: !open })} style={{ backgroundColor: primary_color, color: textColor }}>
        {name}
        <span className="newsletter-name">{values.title}</span>
        <img className="arrow-down" src={ArrowIcon} alt="ArrowIcon" onClick={onOpen}/>
      </div>
    </>
  )
};
