import * as React from 'react';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { useAppSelector } from 'src/stores';
import ArrowIcon from 'src/assets/img/chevron@2x.png';

import { onBackToLetter, onBackToEventList, onPublish } from '../handlers';

export const ControlsSubHead = () => {
  const isEditEvent = useAppSelector(state => Boolean(state.editor.event));
  const showCancel = useAppSelector(
    state => state.editor.isEditing || state.editor.isOpenContent
  );

  const { setFieldValue } = useFormikContext();

  if (isEditEvent) {
    return (
      <div className="editor-sub-head">
        <div onClick={onBackToEventList}>
          <img className="arrow-left" src={ArrowIcon} alt="ArrowIcon"/>
          Back to event list
        </div>
      </div>
    )
  }

  if (showCancel) {
    return (
      <div className="editor-sub-head">
        <div onClick={onBackToLetter(setFieldValue)}>
          <img className="arrow-left" src={ArrowIcon} alt="ArrowIcon"/>
          Cancel
        </div>
      </div>
    )
  }

  return (
    <div className="editor-sub-head">
      <Link to={ROUTES.newsletters} className="delete">Exit/discard</Link>
      <span onClick={onPublish}>Publish</span>
    </div>
  )
};
