import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_LOGOUT } from 'src/constants';
import { requestWrapper, logoutFromSystem } from 'src/utils';

const logoutRequest = requestWrapper(() => {
  return axios.put(API_LOGOUT);
});

export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    const { success } = await logoutRequest();

    if (success) {
      logoutFromSystem();
    }
  }
);
