import * as React from 'react';
import { StripeElements, Stripe } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';

import { history, toast } from 'src/utils';
import { ROUTES } from 'src/constants';
import { getSchool } from 'src/services';
import { store } from 'src/stores';
import { logStart, logSuccess } from 'src/components/loader';

import { BillingInfoType } from '../types';
import { updateBillingInfo, createSubscription, cancelSubscription } from '../services';
import { mapBillingInfo } from '../utils';
import { subscriptionActions } from '../reducers';
import { MOUNT_PAYMENT_INFO } from '../constants';

export const handleChoosePlan = (plan: string) => () => {
  createSubscription(plan);
};

export const handleChangeBillingInfo = async (data: BillingInfoType) => {
  const mappedData = mapBillingInfo(data);
  const success = await updateBillingInfo(mappedData);

  if (success) {
    history.push(ROUTES.cardSettings);
    getSchool();
  }
};

export const handleUpdateBillingInfo = async (data: BillingInfoType) => {
  const mappedData = mapBillingInfo(data);
  const success = await updateBillingInfo(mappedData);

  if (success) history.push(ROUTES.invoices);
};

export const handlePayment = (stripe: Stripe | null, elements: StripeElements | null) =>
  async (e: React.SyntheticEvent) => {
    e.preventDefault();


    const { clientSecret } = store.getState().subscription;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    logStart(MOUNT_PAYMENT_INFO);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;

    // Use your card Element with other Stripe.js APIs
    // paymentMethod maybe will be useful
    const { error } = await stripe.confirmCardPayment(clientSecret!, {
      payment_method: {
        card: cardElement,
        billing_details: {}
      }
    });

    if (error) {
      toast(error?.message || '', 'error');
      logSuccess(MOUNT_PAYMENT_INFO);
      return;
    }

    setTimeout(() => {
      store.dispatch(subscriptionActions.setClientSecret(null));
      getSchool();
      history.push(ROUTES.subscription);
      toast('Success', 'success');
      logSuccess(MOUNT_PAYMENT_INFO);
    }, 10000)
  };

export const mountCardSettings = () => {
  const { clientSecret } = store.getState().subscription;

  if (!clientSecret) history.push(ROUTES.settings);
};

export const onCancelSubscription = async () => {
  const success = await cancelSubscription();
  if (success) {
    toast('Successfully canceled', 'success');
    history.push(ROUTES.settings);
    getSchool();
  } else {
    toast('Error', 'error');
  }
};
