import axios from 'axios';
import { store } from 'src/stores';

import { API_EDIT_SUBSCRIBER, API_INVITE, API_MANAGERS, API_SUBSCRIBERS } from 'src/constants';
import { requestWrapper, loadingWrapper } from 'src/utils';

import { GET_MANAGE_USERS, MOUNT_MANAGE_USERS, ADMIN_USERS_FILTER, MOUNT_EDIT_ADMIN } from '../constants';
import { manageUsersActions } from '../reducers';
import { mapToEditAdmin } from '../utils';

const getUsersRequest = requestWrapper(() => {
  return axios.get(API_INVITE);
});

const getSubscribersRequest = requestWrapper(() => {
  return axios.get(API_SUBSCRIBERS);
});

const getAdminRequest = requestWrapper((id: number) => {
  return axios.get(`${API_MANAGERS}/${id}`);
});

const getSubRequest = requestWrapper((id: number) => {
  return axios.get(`${API_MANAGERS}/${id}`);
});

const deleteAdminRequest = requestWrapper((id: number) => {
  return axios.delete(`${API_MANAGERS}/${id}`);
});

const deleteSubRequest = requestWrapper((id: number) => {
  return axios.delete(`${API_SUBSCRIBERS}/${id}`);
});

export const getUsers = loadingWrapper(GET_MANAGE_USERS, async () => {
  const isManagers = store.getState().manageUsers.activeFilter === ADMIN_USERS_FILTER;
  const requestHandler = isManagers ? getUsersRequest : getSubscribersRequest;
  const { success, response } = await requestHandler();

  if (success) {
    if(response.data?.owner) {
      const data = response.data.invites || response.data.subscribers;
      store.dispatch(manageUsersActions.addUsers({ users: data.data, owner: response.data?.owner?.data?.attributes }));
    } else {
      const data = response.data.invites || response.data.subscribers;
      store.dispatch(manageUsersActions.addUsers({ users: data.data }));
    }
  }

  return success;
});

export const mountUsers = loadingWrapper(
  MOUNT_MANAGE_USERS,
  async () => {

    await getUsers();

    return true;
  }
);

export const getAdmin = loadingWrapper(
  MOUNT_EDIT_ADMIN,
  async (id: number) => {
    const { success, response } = await getAdminRequest(id);

    if (success) {
      const data = mapToEditAdmin(response.data);
      store.dispatch(manageUsersActions.changeInitialValues(data))
    }
  }
);

export const getSub = loadingWrapper(
  MOUNT_EDIT_ADMIN,
  async (id: number) => {
    const { success, response } = await getSubRequest(id);

    if (success) {
      const data = mapToEditAdmin(response.data);
      store.dispatch(manageUsersActions.changeInitialValues(data))
    }
  }
);

export const deleteAdmin = async (id: number) => {
  const { success } = await deleteAdminRequest(id);

  if (success) {
    await getUsers();
  }
};

export const deleteSub = async (id: number) => {
  const { success } = await deleteSubRequest(id);

  if (success) {
    await getUsers();
  }
};
