import { TEMPLATE_TYPES } from 'src/constants';
import Template1Svg from 'src/assets/img/templates/template1.svg';
import Template2Svg from 'src/assets/img/templates/template2.svg';
import Template3Svg from 'src/assets/img/templates/template3.svg';
import Template4Svg from 'src/assets/img/templates/template4.svg';

export const INITIAL_VALUES = {
  name: '',
  title: '',
  template: ''
};

export const ON_SUBMIT_ON_BOARDING = 'ON_SUBMIT_ON_BOARDING';

export const LAYOUT_TYPES = [{
  id: '1column',
  value: TEMPLATE_TYPES.one_column,
  img: Template1Svg,
  title: '1 column'
}, {
  id: '2columns',
  value: TEMPLATE_TYPES.two_column,
  img: Template2Svg,
  title: '2 column',
  subTitle: '(center)'
}, {
  id: 'left_big_right',
  value: TEMPLATE_TYPES.two_columns_left,
  img: Template3Svg,
  title: '2 column',
  subTitle: '(right)'
}, {
  id: 'right_big_left',
  value: TEMPLATE_TYPES.two_columns_right,
  img: Template4Svg,
  title: '2 column',
  subTitle: '(left)'
}
];

export const LAYOUT_TYPES_WITH_LATEST = [{
  id: '1column',
  value: TEMPLATE_TYPES.one_column,
  img: Template1Svg,
  title: '1 column'
}, {
  id: '2columns',
  value: TEMPLATE_TYPES.two_column,
  img: Template2Svg,
  title: '2 column',
  subTitle: '(center)'
}, {
  id: 'left_big_right',
  value: TEMPLATE_TYPES.two_columns_left,
  img: Template3Svg,
  title: '2 column',
  subTitle: '(right)'
}, {
  id: 'right_big_left',
  value: TEMPLATE_TYPES.two_columns_right,
  img: Template4Svg,
  title: '2 column',
  subTitle: '(left)'
},
{
  id: 'latest_template',
  value: TEMPLATE_TYPES.latest_template,
  img: Template4Svg,
  title: 'latest template',
  subTitle: ''
},
];

export const INITIAL_NEWSLETTER_VALUES = {
  title: '',
  template: LAYOUT_TYPES[0].id
};


export const INVITE_USER_FORM = {
  first_name: '',
  last_name: '',
  role: '',
  email: ''
};

export const PASSWORDS_FORM = {
  existing_password: '',
  password: '',
  confirm_password: ''
};

export const BILLING_INFO_FORM = {
  first_name: '',
  last_name: '',
  address: '',
  telephone: '',
  email: ''
};

export const MONTHLY_PLAN = 'monthly';
export const YEARLY_PLAN = 'yearly';

export const ON_SUBMIT_INVITE = 'ON_SUBMIT_INVITE';
export const GET_MANAGE_USERS = 'GET_MANAGE_USERS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_BILLING_INFO = 'GET_BILLING_INFO';
export const ON_CHOOSE_PLAN = 'ON_CHOOSE_PLAN';
export const ON_SUBMIT_MY_DETAILS = 'ON_SUBMIT_MY_DETAILS';
export const ON_SUBMIT_PASSWORDS = 'ON_SUBMIT_PASSWORDS';
export const ON_SUBMIT_SCHOOL_SETTINGS = 'ON_SUBMIT_SCHOOL_SETTINGS';
export const ON_SUBMIT_BILLING_INFO = 'ON_SUBMIT_BILLING_INFO';
export const ON_EDIT_ADMIN = 'ON_EDIT_ADMIN';

export const MOUNT_MANAGE_USERS = 'MOUNT_MANAGE_USERS';
export const MOUNT_MY_DETAILS = 'MOUNT_MY_DETAILS';
export const MOUNT_PASSWORD_UPDATE = 'MOUNT_PASSWORD_UPDATE';
export const MOUNT_SCHOOL_SETTINGS = 'MOUNT_SCHOOL_SETTINGS';
export const MOUNT_BILLING_INFO = 'MOUNT_BILLING_INFO';
export const MOUNT_PAYMENT_INFO = 'MOUNT_PAYMENT_INFO';
export const MOUNT_EDIT_ADMIN = 'MOUNT_EDIT_ADMIN';
