import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { changeAuthState, setUserData, setProfileData, setSchoolData } from './actions';
import { AUTH_STATES } from 'src/constants';
import { AnyObject } from 'src/types';

export interface AuthState {
  value: 0 | 1 | 2,
  accessToken?: string,
  school: AnyObject,
  user?: {
      id: string,
      attributes: {
        first_name: string,
        last_name: string,
        email: string,
        role?: string
        need_change_password?: boolean,
      },
      type: string
  }
}

const authValues = JSON.parse(localStorage.getItem('session') || '{}');
axios.defaults.headers.common['Authorization'] = authValues.accessToken;

const initialState = {
  value: AUTH_STATES.unauthorized,
  ...authValues,
  user: {
    ...({ ...authValues.user?.data, need_change_password: false } || {})
  }
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthState,
    setUserData,
    setProfileData,
    setSchoolData
  }
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
