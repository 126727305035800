import * as React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import { useAppSelector } from 'src/stores';
import { onChangeFilter, onClickInvite } from '../../handlers';
import { ADMIN_USERS_FILTER, SUBS_USERS_FILTER } from '../../constants';

export const Controls = () => {
  const activeFilter = useAppSelector(state => state.manageUsers.activeFilter);

  const isActive = (filter: string) => activeFilter === filter;

  return (
    <div className="controls-block">
      {/* <div
        className={classNames('filter-button', { active: isActive(ADMIN_USERS_FILTER) })}
        onClick={onChangeFilter(ADMIN_USERS_FILTER)}
      >
        Administration users
      </div> */}
      {/* <div
        className={classNames('filter-button', { active: isActive(SUBS_USERS_FILTER) })}
        onClick={onChangeFilter(SUBS_USERS_FILTER)}
      >
        Subscribe users
      </div> */}
      <div />
      <Button className="invite-button" onClick={onClickInvite}>
        Invite users
      </Button>
    </div>
  )
};
