import { store } from 'src/stores';
import { landingActions } from '../reducers';

export const changeHeaderType = (trigger: boolean) => {
  const { simplifyHeader } = store.getState().landing;
  if (simplifyHeader === trigger) return;

  store.dispatch(landingActions.changeHeaderState(trigger))
};

export const changeMobileHeaderType = (trigger: boolean) => {
  const { simplifyMobileHeader } = store.getState().landing;
  if (simplifyMobileHeader === trigger) return;

  store.dispatch(landingActions.changeMobileHeaderState(trigger))
};
