import * as React from 'react';
import { Field, useFormikContext } from 'formik';
import { useAppSelector } from 'src/stores';

import { DatePicker, TimePicker, Input, TextArea } from 'src/components/fields';
import { onSaveEvent } from '../../handlers';

type Props = {
  name: string,
  index: number
};

export const EditEvent: React.FC<Props> = (props) => {
  const { name, index } = props;
  const {isEditing} = useAppSelector(state => state.editor);
  const {
    isValid,
    // touched,
    values,
  } = useFormikContext();

  const required = (value: any) => {
    return !value || value === '' ? 'Required' : undefined;
  };

  // const touchedKeys = Object.keys(touched);
  const isTouched = values[isEditing][index].title && values[isEditing][index].date && values[isEditing][index].description;

  return (
    <div className="edit-event-field">
      <div className="date-time-wrap">
        <Field name={`${name}[${index}].date`} label="Date" component={DatePicker} validate={required} />
        <Field name={`${name}[${index}].time`} label="Time" component={TimePicker} />
      </div>
      <Field
        name={`${name}[${index}].title`}
        placeholder="This is event name"
        label="Title"
        component={Input}
        validate={required}
      />
      <Field
        name={`${name}[${index}].description`}
        placeholder="This is event description"
        label="Description"
        component={TextArea}
        validate={required}
      />
      <button className="app-button green" onClick={onSaveEvent} disabled={!isTouched || !isValid}>
        Save event
      </button>
    </div>
  )
};
