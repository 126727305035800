import * as React from 'react';
import { Field } from 'formik';

import { Input } from 'src/components/fields';

export const PasswordFields = () => {
  return (
    <>
      <Field name="existing_password" type="password" label="Existing password" className="full-size" component={Input}/>
      <Field name="password" type="password" label="New password" className="full-size" component={Input}/>
      <Field name="confirm_password" type="password" label="Confirm new password" className="full-size" component={Input}/>
    </>
  )
};
