import React from 'react'
import { useAppSelector } from 'src/stores';
import {getPrimaryTextColor} from '../utils';
// import ArrowIcon from 'src/assets/img/chevron@2x.png';
// import PointerIcon from 'src/assets/img/editor/pionter.png';
// import MailIcon from 'src/assets/img/editor/email.png';
// import PhoneIcon from 'src/assets/img/editor/phone.png';
import PointerIcon from 'src/assets/img/editor/pionter_white.png';
import MailIcon from 'src/assets/img/editor/email_white.png';
import PhoneIcon from 'src/assets/img/editor/phone_white.png';

const LetterFooter = () => {
  const footerContent = useAppSelector(state => state.editor.newsletterDefault.footerContent);
  const primaryColor = useAppSelector(state => state.auth?.school?.primary_color);
  const {
    // name,
    phone,
    email,
    website,
    // logo,
    // primary_color
  } = useAppSelector(state => state.auth.school) || {};
  const textColor = getPrimaryTextColor();

  return !(!footerContent && !email && !website && !phone) ? (
    <div 
      style={{
        backgroundColor: primaryColor ? primaryColor : '#34c3ee',
      }}
      className="letter-footer"
    >
      <p style={{color: textColor}}>{ footerContent }</p>
      <ul className="school-media-info">
        { website && 
          <li>
            <img src={PointerIcon} alt="media" />
            <a href={website} style={{color: textColor}} target="_blank" rel="noreferrer">{website}</a>
          </li> 
        }
        { email && 
          <li>
            <img src={MailIcon} alt="media" />
            <a style={{color: textColor}} href={`mailto:${website}`}>{email}</a>
          </li> 
        }
        {phone && 
          <li>
            <img src={PhoneIcon} alt="media" />
            <a style={{color: textColor}} href={`tel:${phone}`}>{phone}</a>
          </li>
        }
        </ul>
    </div>
  ) :  null;
}

export default LetterFooter
