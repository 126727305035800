import * as React from 'react';
import { Switch, useParams, Route } from 'react-router-dom';

import { PublicRoute } from 'src/components/router';
import { ROUTES } from 'src/constants';

import { Header, LandingPageWrap } from '../components';
import { mount, unmount, handleToggleAuth } from '../handlers';

import { HomePage, HowItWorks, Pricing, Terms, Privacy, Cookies } from './Pages';
import { AuthWrap } from './Auth';
import { SidebarMenu } from './SidebarMenu';
import {ChangePassword} from '../../changePassword/ChangePassword';
import {NotFound} from 'src/modules/notFound';

export const LandingPage = () => {
  const {token} : any = useParams();

  React.useEffect(() => {
    if(token) {
      handleToggleAuth(true, 'register')();
    } else {
      mount();
      return () => {
        unmount();
      }
    }
    
  }, []);


  return (
    <LandingPageWrap>
      <AuthWrap />
      <Header/>
      <SidebarMenu/>
      <div className="landing-page-content">
        <Switch>
          <PublicRoute exact path={ROUTES.main} component={HomePage} />
          <PublicRoute exact path={ROUTES.home} component={HomePage}/>
          <PublicRoute exact path={ROUTES.homeInvited} component={HomePage}/>
          <PublicRoute exact path={ROUTES.howItWorks} component={HowItWorks}/>
          <PublicRoute exact path={ROUTES.pricing} component={Pricing}/>
          <PublicRoute exact path={ROUTES.forgotPassword} component={ChangePassword} />
          <PublicRoute exact path={ROUTES.terms} component={Terms} />
          <PublicRoute exact path={ROUTES.privacyPolicy} component={Privacy} />
          <PublicRoute exact path={ROUTES.cookies} component={Cookies} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </LandingPageWrap>
  );
};
