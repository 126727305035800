import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_LOGIN, AUTH_STATES, API_FORGOT, API_CHANGE_PASSWORD, API_USERS, ROUTES } from 'src/constants';
import { history, requestWrapper, toast, loadingWrapper } from 'src/utils';
import { store } from 'src/stores';
import { authActions } from 'src/stores/auth';

import { UserType, ChangePasswordType } from '../types';
import { ON_LOGIN, ON_REGISTER, ON_FORGOT, ON_CHANGE_PASSWORD } from '../../settings/constants';

const loginRequest = requestWrapper((payload: UserType) => {
  return axios.post(API_LOGIN, payload);
});

const registerRequest = requestWrapper((payload: UserType) => {
  return axios.post(API_USERS, payload);
});

const forgotPasswordRequest = requestWrapper((email: string) => {
  return axios.put(API_FORGOT, {email});
});

const changePasswordRequest = requestWrapper((passwords: ChangePasswordType) => {
  const {token, password, password_confirmation} = passwords;
  return axios.put(`${API_CHANGE_PASSWORD}${token}`, {password, password_confirmation});
});

// export const onSubmitLogin =
//   loadingWrapper(ON_LOGIN, async (data: UserType) => {
//     const success = await login(data);
//     return true
//   });

export const login = createAsyncThunk(
  'auth/login',
  loadingWrapper(ON_LOGIN, async (userData: UserType) => {
    const { success, response } = await loginRequest(userData);

    if (success) {
      const data = {
        accessToken: response.data.token.auth_token,
        user: response.data.user,
        value: 0
      };

      switch (response.data.user.data.type) {
        case 'user':
          data.value = AUTH_STATES.authorized;
          break;

        case 'admin':
          data.value = AUTH_STATES.admin;
          break;
      }

      axios.defaults.headers.common['Authorization'] = response.data.token.auth_token;
      store.dispatch(authActions.setUserData(data));
      localStorage.setItem('session', JSON.stringify(data))
      history.push(ROUTES.newsletters);
    }
    return success
  }),
);

export const register = createAsyncThunk(
  'auth/register',
  loadingWrapper(ON_REGISTER, async (userData: UserType) => {
    const { success } = await registerRequest(userData);

    if (success) {
      toast('Account successfully created. Check your email!', 'success')
    }
    return success;
  }),
);


export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  loadingWrapper(ON_FORGOT, async (email: string) => {
    const { success, response } = await forgotPasswordRequest(email);

    if (success) {
      toast(response.data.message || 'Check your email!', 'success');

      history.push(ROUTES.home);
    }
    return success
  }),
);

export const changePassword = createAsyncThunk(
  'auth/change-password',
  loadingWrapper(ON_CHANGE_PASSWORD, async (passwords: ChangePasswordType) => {
    const { success, response } = await changePasswordRequest(passwords);

    if (success) {
      toast(response.data.message || 'Password successfully changed.', 'success');

      history.push(ROUTES.home);
    }
    return success
  }),
)
