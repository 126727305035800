import { createSlice } from '@reduxjs/toolkit';

import {
  setInvoicesData,
} from '../actions';
import { InvoicesStore } from '../types';

const initialState = {
  list: []
} as InvoicesStore;

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesData
  }
});

export const invoicesReducer = invoicesSlice.reducer;
export const invoicesActions = invoicesSlice.actions;
