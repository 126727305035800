import * as React from 'react';
import moment, { Moment } from 'moment';
import { DatePicker as DatePickerAntd } from 'antd';

import { onChangeYear } from '../../handlers';

export const YearDropdown = () => {
  const [year, setValue] = React.useState<Moment | null>(moment());

  const handleChange = (value: Moment | null) => {
    setValue(value);
    onChangeYear(value);
  };

  return (
    <DatePickerAntd
      value={year}
      onChange={handleChange}
      picker="year"
    />
  )
};
