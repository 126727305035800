import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { CONTENT_ITEMS } from '../constants';
import { onCreateField } from '../handlers';

export const ChooseContent = () => {
  const { isOpenContent, isEditing, event } = useAppSelector(state => state.editor);
  const conditions = {
    'hide-mobile': !isOpenContent,
    'hide-desktop': isEditing || event
  };

  return (
    <div className={classNames('content-to-choose', conditions)}>
      {CONTENT_ITEMS.filter(item => item.id !== 'empty-space').map(item => (
        <div
          className="content-item-wrap"
          key={`content-item-${item.id}`}
          onClick={onCreateField(item.id)}
        >
          <div className="content-item">
            <img src={item.img} alt="img" />
          </div>
          <div className="content-item-title">
            {item.name}
          </div>
        </div>
      ))}
    </div>
  )
};
