import * as React from 'react';
import { FieldAttributes, useFormikContext } from 'formik';
import { Moment } from 'moment';
import { DatePicker as DatePickerAntd } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  additionalText?: string,
  textLink?: string,
  label: string,
};

export const DatePicker = (props: Props) => {
  const { id, additionalText, textLink, label, form, field, className, ...rest } = props;
  const error = form.errors[props.field.name];

  const { setFieldValue } = useFormikContext();

  const handleChange = (value: Moment) => {
    setFieldValue(field.name, value)
  };

  return (
    <div className={classNames('form-input', className)}>
      <div className="label-block">
        <label htmlFor={id}>{label}</label>
        <Show if={Boolean(additionalText)}>
          <Link to={textLink || ''} className="link">{additionalText}</Link>
        </Show>
      </div>
      <DatePickerAntd {...rest} {...field} value={field.value || ''} onChange={handleChange} />
      <div className="form-error">
        {error}
      </div>
    </div>
  );
};
