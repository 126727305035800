import * as React from 'react';

import { InviteUser } from './InviteUser';
import { onInviteSubscriber } from '../../handlers';

export const SubscriberWrapper = () => {
  return (
    <InviteUser
      onSubmit={onInviteSubscriber}
    />
  )
};
