import axios from 'axios';

import { API_PASSWORD_UPDATE, ROUTES } from 'src/constants';
import { history, loadingWrapper, requestWrapper, toast } from 'src/utils';

import { ON_SUBMIT_PASSWORDS } from '../constants';
import { PasswordsBEType } from '../types';

import { getProfileInfo } from './myDetails'

const changePasswordRequest = requestWrapper((payload: PasswordsBEType) => {
  return axios.patch(API_PASSWORD_UPDATE, payload);
});

export const changePassword = loadingWrapper(
  ON_SUBMIT_PASSWORDS,
  async (values: PasswordsBEType) => {
    const { success } = await changePasswordRequest(values);

    if (success) {
      toast('Password successfully updated', 'success');
      history.push(ROUTES.settings);
    }

    await getProfileInfo();

    return success
  }
);
