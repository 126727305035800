import axios from 'axios';
import { matchPath } from 'react-router-dom';

import { requestWrapper, history } from 'src/utils';
import { API_NEWSLETTER_PUBLIC, ROUTES } from 'src/constants';

export const getPublicEmailRequest = requestWrapper(async (slug: string) => {
  return axios.get(`${API_NEWSLETTER_PUBLIC}/${slug}`);
});

export const getPublicEmail = async () => {
  const { params } = matchPath(history.location.pathname, ROUTES.published) || {} as any;

  const { success, response } = await getPublicEmailRequest(params?.slug);

  if (success) return response.data.news_letter.data.attributes.body;

  history.push(ROUTES.home);
  return '';
};
