import * as React from 'react';
import classNames from 'classnames';
import { FieldAttributes, useFormikContext } from 'formik';
import { ChromePicker, ColorResult } from 'react-color';
import rgbHex from "rgb-hex";

import { Show } from 'src/components/conditions';

import PipetteIcon from 'src/assets/img/pipette@2x.png';

type Props = FieldAttributes<any> & {
  className?: string,
  label: string,
};

export const ColorPicker = (props: Props) => {
  const {
    className,
    label,
    form,
    field
  } = props;
  const error = form.errors[props.field.name];
  const { setFieldValue } = useFormikContext();
  const [open, setOpen] = React.useState(false);

  const handleChange = (color: ColorResult) => {
    setFieldValue(field.name, "#" + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classNames('form-input', className)}>
      <div className="label-block">
        <label>{label}</label>
      </div>
      <div className="color-input" onClick={toggle}>
        <div className="color-input-block" style={{ backgroundColor: field.value || '#ffc408' }}/>
        <div className="color-hex">
          {field.value || '#ffc408'}
        </div>
        <img className="pipette-icon" src={PipetteIcon} alt="PipetteIcon" />
      </div>
      <Show if={open}>
        <div className="overlay-cover" onClick={toggle}/>
        <ChromePicker color={field.value || '#ffc408'} onChangeComplete={handleChange} disableAlpha/>
      </Show>
      <div className="form-error">
        {error}
      </div>
    </div>
  )
};
