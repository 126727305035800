import * as React from 'react';
import _ from 'lodash';
import { FieldAttributes, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  additionalText?: string,
  textLink?: string,
  label: string,
};

export const Input = (props: Props) => {
  const { id, additionalText, textLink, label, form, field, className, inputType, ...rest } = props;
  const error = form.errors[field.name];

  const { setFieldValue } = useFormikContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    if (Number(value) > 100 && !_.isNil(rest.max)) value = '100';
    if (Number(value) < 0 && !_.isNil(rest.min)) value = '0';
    if (inputType === 'phone') value = value.replace(/[^\d.-]/g, '')

    setFieldValue(field.name, value)
  };

  const subName = (field.name || '').split('.');
  const touchetSubName = form.touched && form.touched[subName[0] || ''] && subName[1] ? form.touched[subName[0] || ''][subName[1] || ''] : {};

  return (
    <div className={classNames('form-input', className)}>
      <div className="label-block">
        <label htmlFor={id}>{label}</label>
        <Show if={Boolean(additionalText)}>
          <Link to={textLink || ''} className="link">{additionalText}</Link>
        </Show>
      </div>
      <input {...rest} {...field} value={field.value || ''} onChange={onChange}/>
      {((form.touched[field.name] || touchetSubName) && !!error) && (
        <div className="form-error">
          {error}
        </div>
      )}
    </div>
  );
};
