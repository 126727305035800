import * as React from 'react';
import { FieldAttributes, useFormikContext } from 'formik';
import { UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
// import axios from 'axios';

import UploadImg from 'src/assets/img/upload@2x.png';
import ScraperImg from 'src/assets/img/scraper@2x.png';
// import { API_ATTACHMENTS } from 'src/constants';
import { AnyObject } from 'src/types';
import { Show } from 'src/components/conditions';

type Props = FieldAttributes<any> & {
  label?: string,
  aspect?: number,
};

export const ImageField = (props: Props) => {
  const { className, label, field } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext<AnyObject>();

  const tipText = label || 'Drop file here to upload';

  const handleChange = async (info: UploadChangeParam) => {
    setFieldValue(field.name, info.file?.originFileObj);
    setFieldTouched(field.name, true);
  };

  const fileUrl = field.value?.uid ? URL.createObjectURL(field.value) : field.value;

  return (
    <ImgCrop
      aspect={props?.aspect ? props.aspect : 16/7}
      cropperProps={{restrictPosition: false}}
      minZoom={0}
    >
      <Upload
        className={classNames('form-uploader branding-uploader', className)}
        showUploadList={false}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: percent => `${parseFloat(percent.toFixed(2))}%`,
        }}
        onChange={handleChange}
      >
        <Show if={field.value}>
          <img className="preview-img" src={fileUrl} alt="preview"/>
        </Show>
        <Show if={!field.value}>
          <img className="upload-icon" src={UploadImg} alt="UploadImg"/>
          <div className="upload-text">{tipText}</div>
          <div className="upload-text small">or</div>
          <button className="app-button green" type="button">
            <img className="scraper-icon" src={ScraperImg} alt="ScraperImg"/>
            Select file
          </button>
        </Show>
      </Upload>
    </ImgCrop>
  );
};
