import { matchPath } from 'react-router-dom';

import { store } from 'src/stores';
import { history } from 'src/utils';
import { ROUTES } from 'src/constants';
import { AnyObject } from 'src/types';

import { ADMIN_USERS_FILTER, SUBS_USERS_FILTER, INVITE_USER_FORM } from '../constants';
import { manageUsersActions } from '../reducers';
import { getUsers, getAdmin, getSub, deleteAdmin, deleteSub } from '../services';

export const onChangeFilter = (filter: string) => () => {
  store.dispatch(manageUsersActions.changeActiveFilter(filter));
  getUsers();
};

export const onClickInvite = () => {
  const { activeFilter } = store.getState().manageUsers;

  if (activeFilter === ADMIN_USERS_FILTER) {
    history.push(ROUTES.inviteUser);
  } else {
    history.push(ROUTES.inviteSubscriber);
  }
};

export const mountAdminEdit = async () => {
  const { params } = matchPath(history.location.pathname, ROUTES.editUser) || {} as AnyObject;

  if (params?.id) {
    getAdmin(Number(params.id));
  }
};

export const mountSubsEdit = async () => {
  const { params } = matchPath(history.location.pathname, ROUTES.editSubs) || {} as AnyObject;

  if (params?.id) {
    getSub(Number(params.id));
  }
};

export const unmountSubsEdit = async () => {
  store.dispatch(manageUsersActions.changeInitialValues(INVITE_USER_FORM));
};

export const onDeleteUser = (id: number) => () => {
  const { activeFilter } = store.getState().manageUsers;

  if (activeFilter === ADMIN_USERS_FILTER) {
    deleteAdmin(id);
  }

  if (activeFilter === SUBS_USERS_FILTER) {
    deleteSub(id);
  }
};
