import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import { Input } from 'src/components/fields';
import { Button } from 'src/components/button';

import { CREATE_LETTER_STEPPER, CREATE_FIRST_LETTER_STEPPER, ON_SUBMIT_ON_BOARDING } from '../constants';
import { onChangeStep } from '../handlers';

export const NewsletterName = () => {
  const {currentStep, list} = useAppSelector(({onBoarding, newsletter}) => ({...onBoarding, ...newsletter}));
  const { values } = useFormikContext<{ name: string, title: string, template: string }>();

  if (currentStep?.id !== CREATE_LETTER_STEPPER[0].id) return null;

  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">3</div>
        {list.length > 0 ? CREATE_LETTER_STEPPER[0].question : CREATE_FIRST_LETTER_STEPPER[0].question}
      </div>
      <Field
        name="title"
        className="school-input"
        placeholder="Newsletter Title"
        component={Input}
      />

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="button"
        className="app-button green"
        disabled={!values.title}
        onClick={onChangeStep(CREATE_LETTER_STEPPER[1])}
      >
        Confirm
      </Button>
    </>
  )
};
