import * as React from 'react';
import { useFormikContext } from 'formik';

import { store } from 'src/stores';

import { editorActions } from '../reducers';

export const usePrevValue = (fieldName: string | null) => {
  const { values } = useFormikContext<{[key in string]: any}>();

  React.useEffect(() => {
    if (fieldName) {
      store.dispatch(editorActions.setPrevValue(values[fieldName]));
    }

    return () => {
      store.dispatch(editorActions.setPrevValue(null));
    }
  }, [fieldName]);
};
