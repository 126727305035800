import * as React from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';

import ArrowSvg from 'src/assets/img/landing/arrow-left.svg';

import GetStartedImg from 'src/assets/img/landing/get-started-feature.svg';
import Create from 'src/assets/img/landing/CREATE.png';
import Build from 'src/assets/img/landing/BUILD.png';
import Share from 'src/assets/img/landing/SHARE_B.png';

import { changeActiveSlide, moveSlide } from '../handlers';
import { CarouselDots } from '../components';

export const CarouselBlock = () => {
  const carouselRef = React.useRef<CarouselRef>(null);

  return (
    <>
      <div className="carousel-wrap">
        <img
          className="arrow-btn hide-mobile"
          onClick={moveSlide(-1, carouselRef)}
          src={ArrowSvg}
          alt="ArrowSvg"
        />
        <Carousel ref={carouselRef} dots={false}>
          <div>
            <div className="step first-step">
              <div className="info-block">
                <h1>How it works</h1>
                <h3><span>1:</span>Create</h3>
                <div className="info">
                  It’s quick and simple to create a newsletter… we help guide you through the initial steps to ensure you have everything you need, we even provide suggested layouts!
                </div>
              </div>

              <img className="image-block" src={Create} alt="GetStartedImg"/>
            </div>
          </div>
          <div>
            <div className="step first-step">
              <div className="info-block">
                <h1>How it works</h1>
                <h3><span>2:</span>Build</h3>
                <div className="info">
                  Our tools will help you to build simple yet eye-catching newsletters. Use any of our easy to use tools, from simple text editors to videos, plus numerous other features designed specifically for schools.
                </div>
              </div>

              <img className="image-block" src={Build} alt="GetStartedImg"/>
            </div>
          </div>
          <div>
            <div className="step first-step">
              <div className="info-block">
                <h1>How it works</h1>
                <h3><span>3:</span>Share</h3>
                <div className="info">
                  In just a few clicks you can share your newsletter with parents, pupils and school staff, or link to it from your school website. It will be easily readable on any device - desktop, tablet or mobile.
                </div>
              </div>

              <img className="image-block" src={Share} alt="GetStartedImg"/>
            </div>
          </div>
          </Carousel>
        <img
          className="arrow-btn rotated hide-mobile"
          onClick={moveSlide(1, carouselRef)}
          src={ArrowSvg}
          alt="ArrowSvg"
        />
      </div>

      <div className="divider">
        <div className="left"/>
        <div className="right"/>
      </div>

      <CarouselDots onChange={changeActiveSlide(carouselRef)}/>
    </>
  )
};
