import * as React from 'react';

import { EditUser } from './EditUser';
import { onEditAdmin, mountAdminEdit } from '../../handlers';

export const AdminEditWrapper = () => {
  React.useEffect(() => {
    mountAdminEdit()
  }, []);

  return (
    <EditUser
      onSubmit={onEditAdmin}
      isAdmin
    />
  )
};
