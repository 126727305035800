import axios from 'axios';

import { loadingWrapper, requestWrapper } from 'src/utils';
import { API_NEWSLETTERS, API_NEWSLETTERS_UNPUBLISH } from 'src/constants';
import { store } from 'src/stores';

import { newsletterActions } from '../reducers';
import {
  DIRECTION_ORDER_TYPES,
  FILTER_TYPES,
  GET_NEWSLETTERS,
  ORDER_TYPES,
  DELETE_NEWSLETTERS,
  UNPUBLISH_NEWSLETTERS,
  PAGINATION,
} from '../constants';
import { mapNewsletters } from '../utils';

const getNewslettersRequest = requestWrapper((params: {
  search_string: '',
  filter: FILTER_TYPES | '',
  order: ORDER_TYPES,
  direction: DIRECTION_ORDER_TYPES
}) => {
  return axios.get(API_NEWSLETTERS, { params });
});

const deleteNewsletterRequest = requestWrapper((id: number) => {
  return axios.delete(`${API_NEWSLETTERS}/${id}`);
});

const unpublishNewsletterRequest = requestWrapper((id: number) => {
  return axios.put(`${API_NEWSLETTERS_UNPUBLISH}/${id}`);
});

export const getNewsletters = loadingWrapper(
  GET_NEWSLETTERS,
  async (withControls?: boolean) => {
    let params = store.getState().newsletter.controls;
    let list = store.getState().newsletter.list;

    if (withControls) params = {
      search_string: '',
      page: PAGINATION.page,
      per_page: PAGINATION.per_page,
      total_pages: PAGINATION.total_pages,
      filter: FILTER_TYPES.all,
      order: ORDER_TYPES.created_at,
      direction: DIRECTION_ORDER_TYPES.desc,
    };

    const { success, response } = await getNewslettersRequest(params);

    if (success) {
      let data = mapNewsletters(response.data.news_letter.data);
      if (withControls) data = [data[0], data[1], data[2]];

      if (params.page === 1) {
        store.dispatch(newsletterActions.setNewsletters(data));
      } else {
        store.dispatch(newsletterActions.setNewsletters([...list, ...data]));
      }
      store.dispatch(newsletterActions.setControl({total_pages: response.data.total_pages}));
    }
  }
);

export const deleteNewsLetter = loadingWrapper(
  DELETE_NEWSLETTERS,
  async (id: number) => {
    const { success } = await deleteNewsletterRequest(id);

    if (success) {
      await getNewsletters();
    }
  }
);

export const unpublishNewsLetter = loadingWrapper(
  UNPUBLISH_NEWSLETTERS,
  async (id: number) => {
    const { success } = await unpublishNewsletterRequest(id);

    if (success) {
      await getNewsletters();
    }
  }
);
