import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { TAB_LIST } from '../constants';
import { handleChangeTab } from '../handlers';

type Props = {
  hideMobile?: boolean
};

export const TabsBlock = (props: Props) => {
  const selectedTab = useAppSelector(state => state.layout.selectedTab);
  const selectedKeys = selectedTab ? [selectedTab] : [];

  const items = TAB_LIST.map(item => (
    <Menu.Item key={item.id}>
      <Link to={item.path}>
        {item.name}
      </Link>
    </Menu.Item>
  ));

  return (
    <Menu
      className={classNames({ 'hide-mobile': props.hideMobile })}
      onClick={handleChangeTab}
      mode="horizontal"
      selectedKeys={selectedKeys}
    >
      {items}
    </Menu>
  )
};
