import * as React from 'react';
import { TabsBlock } from './TabsBlock';

export const MobileNavigation = () => {
  return (
    <div className="mobile-tabs">
      <TabsBlock/>
    </div>
  );
};
