import * as React from 'react';
import { Form, Formik, Field } from 'formik';
import { Button } from 'antd';
import { Button as CustomButton } from 'src/components/button';

import { Input } from '../landing/components';
import { changeHeaderType, changeMobileHeaderType, onSubmitChangePassword } from '../landing/handlers';

import { InitialChangePasswordFormValue, changePasswordValidationScheme } from '../landing/form';
import { ON_CHANGE_PASSWORD } from '../settings/constants'

export const ChangePassword = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(false);
  }, []);

  return (
    <>
      <Formik
        initialValues={InitialChangePasswordFormValue}
        onSubmit={onSubmitChangePassword}
        validationSchema={changePasswordValidationScheme}
      >
        <Form>
          <Field
            name="password"
            label="PASSWORD"
            type="password"
            component={Input}
          />
          <Field
            name="password_confirmation"
            label="CONFIRM PASSWORD"
            type="password"
            component={Input}
          />


          <Button
            // onClick={handleToggleAuth(true, 'login')}
            className='change-password-submit'
            shape="round"
            size="large"
            htmlType='submit'
          >
            Submit
          </Button>
          {/* <CustomButton
            className="log-btn"
            htmlType="submit"
            shape="round"
            loaderId={ON_CHANGE_PASSWORD}
          >
            Submit
          </CustomButton> */}
        </Form>
      </Formik>
    </>
  )
};
