import * as React from 'react';

import { MobileNavigation } from 'src/modules/layout/components';
import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';

import { Search, Filters, NewsletterList } from '../components';
import { BREADCRUMBS_NEWSLETTERS, GET_NEWSLETTERS } from '../constants';
import { mount } from '../services';
import { onCreateLetter } from '../handlers';
import { useAppSelector } from 'src/stores';
import { handleShowMore } from '../handlers';

export const Newsletters = () => {
  const {page, total_pages} = useAppSelector(state => state.newsletter.controls);

  const loadMore = () => {
    handleShowMore(page + 1);
  };

  React.useEffect(() => {
    mount();
  }, []);

  return (
    <div className="page-content newsletters">
      <MobileNavigation/>
      <Breadcrumbs list={BREADCRUMBS_NEWSLETTERS}/>
      <div className="page-content-body">
        <div className="header-block">
          <h1 className="hide-mobile">Newsletters</h1>
          <div className="search-wrapper">
            <button onClick={onCreateLetter} className="app-button green">
              Create new
            </button>
            <Search />
          </div>
          <Filters />
        </div>
        <Loader id={GET_NEWSLETTERS}>
          <NewsletterList />
          {total_pages > page && (
            <button onClick={loadMore} className="app-button green" style={{margin: "16px auto"}}>
              Load More
            </button>
          )}
        </Loader>
      </div>
    </div>
  )
};
