import * as React from 'react';
import { Button } from 'antd';

import { TryItButton } from '../TryItButton';
import { handleToggleAuth } from '../../handlers';

export const ButtonGroup = () => {

  return (
    <div className="button-group">
      <Button
        onClick={handleToggleAuth(true, 'login')}
        shape="round"
        size="large"
      >
        Login
      </Button>

      <TryItButton />
    </div>
  )
};
