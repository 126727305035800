import { PayloadAction } from '@reduxjs/toolkit';
import { AnyObject } from 'src/types';
import { NewsletterStore, NewsletterType } from '../types';

export const setNewsletters = (state: NewsletterStore, action: PayloadAction<NewsletterType[]>) => {
  state.list = action.payload;
};

export const setControl = (state: NewsletterStore, action: PayloadAction<AnyObject>) => {
  state.controls = {
    ...state.controls,
    ...action.payload
  }
};
