import * as React from 'react';
import { useAppSelector } from 'src/stores';
import { Show } from 'src/components/conditions';

import { CONTENT_ITEMS } from '../constants';
import {
  HeadingField,
  TextEditorField,
  BorderedTextEditor,
  Divider,
  ImageField,
  MultipleImagesField,
  SocialsField,
  ButtonField,
  StarOfWeek,
  AttendanceField,
  CodeField,
  DownloadField,
  EventField,
  VideoField,
  EditEvent,
  FooterField
} from '../components/fields';
import { FieldControls } from '../components';
import { usePrevValue } from '../handlers';

export const ActiveField = () => {
  const name = useAppSelector(state => state.editor.isEditing);
  const event = useAppSelector(state => state.editor.event);
  const type = useAppSelector(
    state => state.editor.fields.find(item => item?.name === name)?.type
  );

  usePrevValue(name);

  if (!name) return null;

  const getEvent = () => {
    if (event) {
      return <EditEvent name={name} index={event.index}/>
    }

    return null;
  };

  const getFieldComponent = () => {
    switch (type) {
      case CONTENT_ITEMS[0].id:
        return <HeadingField name={name}/>;

      case CONTENT_ITEMS[1].id:
        return <TextEditorField name={name}/>;

      case CONTENT_ITEMS[2].id:
        return <BorderedTextEditor name={name}/>;

      case CONTENT_ITEMS[3].id:
        return <Divider name={name}/>;

      case CONTENT_ITEMS[4].id:
        return <ImageField name={name}/>;

      case CONTENT_ITEMS[5].id:
        return <MultipleImagesField name={name}/>;

      case CONTENT_ITEMS[6].id:
        return <SocialsField name={name}/>;

      case CONTENT_ITEMS[7].id:
        return <ButtonField name={name}/>;

      // case CONTENT_ITEMS[8].id:
      //   return <StarOfWeek name={name}/>;

      case CONTENT_ITEMS[8].id:
        return <AttendanceField name={name}/>;

      case CONTENT_ITEMS[9].id:
        return <VideoField name={name}/>;

      case CONTENT_ITEMS[10].id:
        return <DownloadField name={name}/>;

      case CONTENT_ITEMS[11].id:
        return <EventField name={name}/>;

      case CONTENT_ITEMS[12].id:
        return <FooterField name={name}/>;

      default:
        return null;
    }
  };

  const field = getFieldComponent();
  const eventComponent = getEvent();

  return (
    <div className="field-container-wrap">
      <FieldControls />
      <Show if={!eventComponent}>
        {field}
      </Show>
      {eventComponent}
    </div>
  )
};
