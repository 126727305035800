import * as React from 'react';

import { store } from 'src/stores';
import { layoutActions } from '../reducers';

export const handleChangeTab = (tab: { key: React.Key }) => {
  store.dispatch(layoutActions.changeSelectedTab(tab.key.toString()))
};

export const toggleHeader = (trigger: boolean) => {
  store.dispatch(layoutActions.toggleHeader(trigger));
};
