import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';

// import { useAppSelector } from 'src/stores';
// import { ROUTES } from 'src/constants';

type Props = {
  exact?: boolean,
  path?: string,
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  children?: React.ReactNode
};

export const PublicRoute = (props: Props) => {
  const { exact, path, component, children } = props;
  // const isAuth = useAppSelector(state => Boolean(state.auth.value));

  // if (isAuth) return (
  //   <Redirect to={ROUTES.newsletters}/>
  // );

  return (
    <Route exact={exact} path={path} component={component} children={children} />
  )
};
