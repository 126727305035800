import * as React from 'react';
import { Upload } from 'antd';
import axios from 'axios';
import { Field, useFormikContext } from 'formik';

// import { WhiteCross } from 'src/components/icons';
import { Input } from 'src/components/fields';
import { Show } from 'src/components/conditions';
import { API_ATTACHMENTS } from 'src/constants';
import { AnyObject } from 'src/types';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { UploadChangeParam } from 'antd/lib/upload';
import { Popconfirm } from 'antd';
import UploadImg from 'src/assets/img/upload@2x.png';
import ScraperImg from 'src/assets/img/scraper@2x.png';

type Props = {
  name: string
};

export const DownloadField: React.FC<Props> = (props) => {
  const { name } = props;
  const { setFieldValue, values, setFieldTouched } = useFormikContext<AnyObject>();

  // const splittedName = values[name]?.url && values[name].url.split('/');
  // const fieldName = splittedName ? splittedName[splittedName.length - 1] : '';
  const tipText = 'Drop file here to upload';

  const handleChange = async (info: UploadChangeParam) => {
    if (info.file?.response?.attachment?.data?.attributes?.url) {
      const url = info.file?.response?.attachment?.data?.attributes?.url || '';
      const valueName = url.slice(0, url.lastIndexOf('/'));
      const clearedName = url.replace(valueName, '');
      setFieldValue(`${name}.url`, url);
      setFieldTouched(`${name}.name`, true);
      setFieldValue(`${name}.name`, clearedName.replace('/', ''));
    }
  };

  const required = (value: string) => {
    return !value ? 'Required' : undefined;
  };

  return (
    <div className="field-container">
      <div>
        <Upload
          className="form-uploader"
          action={API_ATTACHMENTS}
          // showUploadList={false}
          headers={axios.defaults.headers.common}
          onChange={handleChange}
        >
          {/* <div className="docs-uploader-head">
            <label>Document</label>
            <div className="delete-button">
              {WhiteCross}
            </div>
          </div> */}
          <Show if={!values[name]?.url}>
            <img className="upload-icon" src={UploadImg} alt="UploadImg"/>
            <div className="upload-text">{tipText}</div>
            <div className="upload-text small">or</div>
            <button className="app-button green" type="button">
              <img className="scraper-icon" src={ScraperImg} alt="ScraperImg"/>
              Select file
            </button>
          </Show>
          {/* <Show if={values[name]?.url}>
            <input value={fieldName} readOnly/>
          </Show> */}
        </Upload>
        <Show if={values[name]?.url}>
          <a href={values[name]?.url} target="_blank" className="app-button red" rel="noreferrer">
            View
          </a>
        </Show>
        <Field name={`${name}.name`} label="Button text" component={Input} validate={required} />
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};
