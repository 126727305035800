import { RESOLUTIONS } from 'src/constants';

type AvailableResolutions = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

export const resolutionIsLessThen = (value: AvailableResolutions) => {
  const currentWindowWidth = window.innerWidth;
  const max = RESOLUTIONS[value];

  return currentWindowWidth <= max;
};

export const resolutionIsGreaterThan = (value: AvailableResolutions) => {
  const currentWindowWidth = window.innerWidth;
  const max = RESOLUTIONS[value];

  return currentWindowWidth <= max;
};
