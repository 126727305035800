import * as React from 'react';

import { SettingsMenuItem } from '../components';
import { ROUTES } from 'src/constants';
import { Breadcrumbs } from 'src/components/breadcrumbs';
import { MobileNavigation } from 'src/modules/layout/components';
import { useAppSelector } from 'src/stores';

import MyDetailsPng from 'src/assets/img/settings/my-details.png';
import PasswordPng from 'src/assets/img/settings/password.png';
import SettingsPng from 'src/assets/img/settings/settings.png';
import UsersPng from 'src/assets/img/settings/users.png';
import PalettePng from 'src/assets/img/settings/palette.png';
import BillingPng from 'src/assets/img/settings/billing.png';
import SubscriptionSvg from 'src/assets/img/settings/subscription.svg';

import { BREADCRUMBS_SETTINGS } from '../constants';

export const SettingsMenu = () => {
  const role = useAppSelector(state => state.auth.user.attributes?.role);
  const {school} = useAppSelector(state => state.auth);

  return (
    <div className="settings-container">
      <MobileNavigation/>
      <Breadcrumbs list={BREADCRUMBS_SETTINGS}/>
      <div className="settings-content">
        <h1>My settings</h1>
        <div className="settings-block">
          <SettingsMenuItem
            image={MyDetailsPng}
            title="My details"
            description="Update personal details"
            to={ROUTES.myDetails}
          />
          <SettingsMenuItem
            image={PasswordPng}
            title="Password settings"
            description="Update your logininformation"
            to={ROUTES.passwords}
          />
          {(role !== 'owner' && role !== 'editor') && (
            <SettingsMenuItem
              image={BillingPng}
              title="Billing"
              description="Update eSchools News account billing information"
              to={ROUTES.invoices}
            />
          )}
          {(role !== 'owner' && role !== 'editor') && (
            <SettingsMenuItem
              image={SubscriptionSvg}
              title="Subscription"
              description="Subscription account information"
              to={ROUTES.subscription}
            />
          )}
        </div>
        {role === 'owner' && (
          <h1>School settings</h1>
        )}
        {role === 'owner' && (
          <div className="settings-block">
            <SettingsMenuItem
              image={SettingsPng}
              title="School details"
              description="Update school contactdetails and social media"
              to={ROUTES.schoolSettings}
            />
            {/* {role === 'owner' ? */}
              <SettingsMenuItem
                image={UsersPng}
                title="Manage users"
                description="Manage your eSchools News account users"
                to={ROUTES.manageUsers}
              />
            {/* } */}
            <SettingsMenuItem
              image={PalettePng}
              title="Newsletter branding"
              description="Update school logo, fonts and colour scheme"
              to={ROUTES.newsletterBranding}
            />
            {school.subscription_plan !== 'trial' && (
              <SettingsMenuItem
                image={BillingPng}
                title="Billing"
                description="Update eSchools News account billing information"
                to={ROUTES.invoices}
              />
            )}
            <SettingsMenuItem
              image={SubscriptionSvg}
              title="Subscription"
              description="Subscription account information"
              to={ROUTES.subscription}
            />
          </div>
        )}
      </div>
    </div>
  );
};
