import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { changeAuthContainerState } from '../handlers';

type Props = {
  children: React.ReactNode
};

export const LandingPageWrap = (props: Props) => {
  const showAuth = useAppSelector(state => state.landing.showAuth);
  React.useEffect(() => {
    changeAuthContainerState();
  }, [showAuth]);

  return (
    <div className={classNames('landing-page', { 'active-auth': Boolean(showAuth) })}>
      {props.children}
      <div className="shadow-block" />
    </div>
  );
};
