import * as React from 'react';
import { Form, Formik, Field } from 'formik';
import { Button } from 'antd';
import { Button as CustomButton } from 'src/components/button';

import { ROUTES } from 'src/constants';
import AuthBlob from 'src/assets/img/landing/auth-blob.svg';
import { WhiteCross } from 'src/components/icons';

import { InitialAuthFormValue, loginValidationSchema } from '../../form';
import { Input, Checkbox } from '../../components';
import { handleToggleAuth, onSubmitLogin } from '../../handlers';
import { ON_LOGIN } from '../../../settings/constants'

export const Login = () => {
  return (
    <div className="auth-container">
      <img className="img-blob" src={AuthBlob} alt="AuthBlob" />
      <div className="heading-block">
        <Button
          className="question-button"
          type="text"
          onClick={handleToggleAuth(true, 'register')}
        >
          Not a user?
        </Button>
        <Button
          type="text"
          shape="circle"
          icon={WhiteCross}
          onClick={handleToggleAuth(false)}
        />
      </div>
      <h1>Log in</h1>
      <div className="divider"/>

      <Formik
        initialValues={InitialAuthFormValue}
        validationSchema={loginValidationSchema}
        onSubmit={onSubmitLogin}
      >
        <Form>
          <Field
            name="email"
            label="EMAIL ADDRESS"
            component={Input}
            type='email'
          />
          <Field
            name="password"
            label="PASSWORD"
            additionalTextButton="Forgot password?"
            textLink={ROUTES.forgotPassword}
            onClickAdditional={handleToggleAuth(true, 'forgotPassword')}
            type="password"
            component={Input}
          />

          <CustomButton
            className="log-btn"
            htmlType="submit"
            shape="round"
            loaderId={ON_LOGIN}
          >
            Log in
          </CustomButton>

          <Field
            name="rememberMe"
            component={Checkbox}
          >
            Remember me
          </Field>
        </Form>
      </Formik>
    </div>
  );
};
