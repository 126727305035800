import { TEMPLATE_TYPES } from 'src/constants';
import { getWidthPercents } from '../utils';


export const Column = (leftColumn, rightColumn, layoutType) => `
<mj-section padding="0" background-color="#fff">
  <mj-column width="${getWidthPercents(0)}" background-color="#ffffff" padding-left="15px" border-right="4px solid rgba(114, 114, 114, 0.15)" padding-right="11px">
    ${leftColumn}
  </mj-column>
  <mj-column width="${getWidthPercents(1)}" background-color="#ffffff" padding-left="15px" padding-right="15px">
    ${rightColumn}
  </mj-column>
</mj-section>
`;
