import * as React from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'src/stores';

import { CAROUSEL_ITEMS } from '../constants';

type Props = {
  onChange: Function
};

export const CarouselDots = (props: Props) => {
  const currentSlide = useAppSelector(state => state.landing.currentSlide);

  const slideNumbers = CAROUSEL_ITEMS.map(item => (
    <div
      key={`${item}-slide`}
      onClick={props.onChange(item)}
      className={classNames('number-btn', { active: currentSlide === item })}
    >
      {item}
    </div>
  ));

  return (
    <div className="slide-numbers">
      {slideNumbers}
    </div>
  )
};
