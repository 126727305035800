import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Here we have css configurations
import 'antd/dist/antd.css';
import 'src/assets/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';

// Here we have modules/components
import { Router as Routes } from 'src/routes';
import { Overlay } from 'src/components/overlay';
import { Modal } from 'src/components/modal';
import { CookiesPolicyToast } from 'src/components/cookies';

// React Drag and Drop
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { store } from './stores';
import { history } from './utils';

// Here is stripe initialization
// pk_test_51JSSRuKbNLvGM4LQUynJHsZx5EWHGD04dUe0kGvPoQPoMMbToRUdB1aKZpdg4ZP6xTHZlEf4hYVfBLH1gzFO226w00YDSQm6KB
// pk_live_51NGLVDAs0gaRIy49rjKiV2Huo3xTcXtasQMfDVEJxXIKjq0ZxM40E7eFZivnecv8LxpVFER5jdCcMwrZJzk0qEGg002ujr42ZO
const stripePromise = loadStripe('pk_live_51NGLVDAs0gaRIy49rjKiV2Huo3xTcXtasQMfDVEJxXIKjq0ZxM40E7eFZivnecv8LxpVFER5jdCcMwrZJzk0qEGg002ujr42ZO');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
  <Router history={history}>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Elements stripe={stripePromise}>
          <Overlay />
          <Modal />
          <ToastContainer />
          <Routes />
          <CookiesPolicyToast />
        </Elements>
      </DndProvider>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
