import { createSlice } from '@reduxjs/toolkit';

import { toggleOverlay } from './actions';

export interface OverlayState {
  value: boolean
}

const initialState = {
  value: false
} as OverlayState;

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    toggleOverlay
  }
});

export const overlayReducer = overlaySlice.reducer;
export const overlayActions = overlaySlice.actions;
