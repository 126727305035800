import { matchPath } from 'react-router';

import { store } from 'src/stores';
import { history } from 'src/utils';
import { ROUTES } from 'src/constants';
import { AnyObject } from 'src/types';

import { inviteUser, inviteSubscriber, editAdmin, editSubs } from '../services';
import { InviteUserFormType } from '../types';
import { inviteUserMapper, inviteSubsMapper } from '../utils';

export const onInvite = (values: InviteUserFormType) => {
  store.dispatch(inviteUser(inviteUserMapper(values)));
};

export const onInviteSubscriber = (values: InviteUserFormType) => {
  store.dispatch(inviteSubscriber(inviteSubsMapper(values)));
};

export const onEditAdmin = (values: InviteUserFormType) => {
  const { params } = matchPath(history.location.pathname, ROUTES.editUser) || {} as AnyObject;

  if (params?.id) {
    editAdmin(Number(params?.id), inviteSubsMapper(values));
  }
};

export const onEditSubs = (values: InviteUserFormType) => {
  const { params } = matchPath(history.location.pathname, ROUTES.editSubs) || {} as AnyObject;

  if (params?.id) {
    editSubs(Number(params?.id), inviteSubsMapper(values));
  }
};
