import queryString from 'query-string';

import { history } from 'src/utils';

import { handleChangeNavigation } from './menu';
import { MENU_ITEMS } from '../constants';
import { changeSidebarState } from './sidebar';
import { handleToggleAuth } from './auth';

export const mount = () => {
  const query = queryString.parse(history.location.search);

  if (query.login === 'true') {
    handleToggleAuth(true, 'login')()
  }

  if (query.register === 'true') {
    handleToggleAuth(true, 'register')()
  }

  const neededMenuItem = MENU_ITEMS.find(
    item => item.path === history.location.pathname
  ) || { id: MENU_ITEMS[0].id };
  handleChangeNavigation({ key: neededMenuItem.id });
};

export const unmount = () => {
  handleToggleAuth(false)();
  changeSidebarState(false);
};

export const unmountSidebar = () => {
  changeSidebarState(false);
};
