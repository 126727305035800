import { PayloadAction } from '@reduxjs/toolkit';
import { HeaderType } from '../types';

export const setAuthType = (
  state: HeaderType,
  action: PayloadAction<{ show: boolean, type?: 'login' | 'register' | 'forgotPassword' }>
) => {
  state.showAuth = action.payload.show;
  if (action.payload.type) state.authType = action.payload.type;
};
