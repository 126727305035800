import * as React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES, TRIAL_SUBSCRIPTION } from 'src/constants';
import { useAppSelector } from 'src/stores';

// import { onCancelSubscription } from '../../handlers';
import { handleChoosePlan } from '../../handlers';
import { YEARLY_PLAN } from '../../constants';

import moment from 'moment';

const PlanLabels = {
  monthly: 'Monthly',
  yearly: 'Annually'
};

export const SubsCanceled = () => {
  const isTrial = useAppSelector(state => state.auth?.school?.subscription_status === TRIAL_SUBSCRIPTION);
  const plan: 'monthly' | 'yearly'  = useAppSelector(state => state.auth?.school?.subscription_plan);
  const { subscription_end_date, subscription_status }  = useAppSelector(state => state.auth?.school);
  const cookieAgreement = localStorage.getItem('cookieAgreement')

  if (isTrial) return null;
  if (subscription_status !== 'canceled') return null;

  const onDetailsExit = () => window.history.back();

  return (
    <>
      <div className="subs-details-wrapper">
        <div>
          <h1>My subscription</h1>
          {/*<h5>Go Premium and enjoy the benefits of being Premium</h5>*/}

          <div className="subs-information">
            <div>
              <p className="subs-info-title">Subscription plan</p>
              <p className="subs-info-data">{PlanLabels[plan]}</p>
            </div>
            <div>
              <p className="subs-info-title">Card</p>
              <p className="subs-info-data">**** **** **** ****</p>
            </div>
          </div>
          { subscription_end_date ? 
            <p>Subscription ends {moment(subscription_end_date).format('DD/MM/YYYY')}. 
              Subscription renewal is disabled</p> 
            : null 
          }
          {/* <div className="control-buttons"> */}
          {cookieAgreement === 'true' && (
            <div onClick={handleChoosePlan(YEARLY_PLAN)} className="link">
              Re-enable subscription
            </div>
          )}
          {/* </div> */}
        </div>
        {/* <div className="control-buttons">
          <Link to={ROUTES.choosePlan} className="app-button orange">
            Change plan
          </Link>
        </div> */}
      </div>
      <div className="save-button-wrap">
        <button onClick={onDetailsExit} className="app-button green-button">
          Save
        </button>
      </div>
    </>
  );
};
