import { NewsletterType, NewsLetterBEResponse } from '../types';

export const mapNewsletters = (values: NewsLetterBEResponse[]): NewsletterType[] => {
  return values.map(item => ({
    id: Number(item.id),
    title: item.attributes.title,
    body: item.attributes.body,
    layout_type: item.attributes.layout_type,
    status: item.attributes.status,
    updated_at: item.attributes.updated_at,
  }))
};
