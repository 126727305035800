import * as React from 'react';
import { Formik, Form } from 'formik';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Button } from 'src/components/button';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';
import { useAppSelector } from 'src/stores';

import { BREADCRUMBS_NEWSLETTER_BRANDING, MOUNT_SCHOOL_SETTINGS, ON_SUBMIT_SCHOOL_SETTINGS } from '../../constants';
import { BrandingFields } from '../../components';
import { mountSchoolSettings } from '../../services';
import { onSubmitSchoolSettings } from '../../handlers';

export const NewsletterBranding = () => {
  const {
    logo,
    primary_color,
    secondary_color
  } = useAppSelector(state => state.school.school);

  React.useEffect(() => {
    mountSchoolSettings();
  }, []);

  const initValues = {
    logo: logo,
    primary_color: primary_color || '#aaa',
    secondary_color: secondary_color || '#aaa',
  };

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="Newsletter branding" to={ROUTES.settings} list={BREADCRUMBS_NEWSLETTER_BRANDING}/>
      <div className="page-content-body color-picker-container">
        <h1>Newsletter branding</h1>
        <Loader id={MOUNT_SCHOOL_SETTINGS}>
          <Formik
            initialValues={initValues}
            enableReinitialize
            onSubmit={onSubmitSchoolSettings}
          >
            <Form>
              <BrandingFields />
              <div className="button-block full-size">
                <Button
                  loaderId={ON_SUBMIT_SCHOOL_SETTINGS}
                  htmlType="submit"
                  className="green-button"
                >
                  Save changes
                </Button>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  );
};
