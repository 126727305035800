import { getWidthPercents } from '../../utils';

export const SocialMedia = (fb: string, im: string, tw: string, ln: string, yt: string) => (index: number) => `
<mj-column width="${getWidthPercents(index)}" css-class="social-container">
    <mj-social font-size="15px" icon-size="35px" mode="horizontal" padding="0" align="left">
        ${fb ? `
        <mj-social-element icon-padding="5px" name="facebook-noshare" href="${fb}"
                           background-color="#1977f2" border-radius="50%" padding="0 16px 16px 0">
        </mj-social-element>
        ` : ''}
        ${tw ? `
        <mj-social-element icon-padding="5px" name="twitter-noshare" href="${tw}"
                           background-color="#1da1f1" border-radius="50%" padding="0 16px 16px 0">
        </mj-social-element>
        ` : ''}
        ${im ? `
        <mj-social-element icon-padding="5px" name="instagram" href="${im}"
                           background-color="#ef5d33" border-radius="50%" padding="0 16px 16px 0">
        </mj-social-element>
        ` : ''}
        ${ln ? `
        <mj-social-element icon-padding="5px" name="linkedin-noshare" href="${ln}"
                           background-color="#0078b6" border-radius="50%" padding="0 16px 16px 0">
        </mj-social-element>
        ` : ''}
        ${yt ? `
        <mj-social-element icon-padding="5px" name="youtube" href="${yt}"
                           background-color="#FF0000" border-radius="50%" padding="0 16px 16px 0">
        </mj-social-element>
        ` : ''}
    </mj-social>
</mj-column>
`;
