export const STEPPER_ITEMS = [
  {
    id: 'school-name',
    question: 'How would you like your school name to appear?'
  }, {
    id: 'upload-logo',
    question: 'Upload your school logo'
  },
  {
    id: 'set-email',
    question: 'Set your school email'
  },
  {
    id: 'school-phone',
    question: 'Set your school phone'
  }
];

export const CREATE_FIRST_LETTER_STEPPER = [
  {
    id: 'newsletter-title',
    question: 'What would you like to call your first newsletter?'
  }, {
    id: 'layout',
    question: 'Please choose a layout'
  }
];

export const CREATE_LETTER_STEPPER = [
  {
    id: 'newsletter-title',
    question: 'What would you like to call your newsletter?'
  }, {
    id: 'layout',
    question: 'Please choose a layout'
  }
];
