import { store } from 'src/stores';
import { TEMPLATE_TYPES } from 'src/constants';
import hexRgb from 'hex-rgb';

const LAYOUT_TO_PERCENTS = {
  [TEMPLATE_TYPES.one_column]: [100, 100, 100, 100],
  [TEMPLATE_TYPES.two_column]: [50, 50, 100, 100],
  [TEMPLATE_TYPES.two_columns_left]: [60, 40, 100, 100],
  [TEMPLATE_TYPES.two_columns_right]: [40, 60, 100, 100],
};
const EVENT_TEXT_LAYOUT_PERCENTS = {
  [TEMPLATE_TYPES.one_column]: [88, 88, 88, 88],
  [TEMPLATE_TYPES.two_column]: [75, 75, 75, 75],
  [TEMPLATE_TYPES.two_columns_left]: [80, 70, 80, 70],
  [TEMPLATE_TYPES.two_columns_right]: [70, 80, 70, 80],
};

const getTextColor = (bgColor: string) => {
  let { red, green, blue } = hexRgb(bgColor);
  let targetColor = `rgb(${red},${green},${blue})`;
  const colors = targetColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  // console.log('getTextColor ---> ', targetColor, colors, r, g, b)
  const brightness = 1;

  const R: any = colors[1] || 0;
  const G: any = colors[2] || 0;
  const B: any = colors[3] || 0;

  const ir = Math.floor((255 - R) * brightness);
  const ig = Math.floor((255 - G) * brightness);
  const ib = Math.floor((255 - B) * brightness);
  return `rgb(${ir}, ${ig}, ${ib})`;
};

export const isOneColumnLayout = () => {
  return store.getState().editor.newsletterDefault.layout_type === TEMPLATE_TYPES.one_column
};

export const getWidthPercents = (index: number) => {
  return getWidthPercentsNumber(index) + '%'
};

export const getWidthPercentsNumber = (index: number) => {
  const { layout_type } = store.getState().editor.newsletterDefault;

  return LAYOUT_TO_PERCENTS[layout_type][index]
};

export const getWidthEventPercents = (index: number) => {
  return getWidthPercentsEventNumber(index) + '%';
};

export const getWidthPercentsEventNumber = (index: number) => {
  const { layout_type } = store.getState().editor.newsletterDefault;

  return EVENT_TEXT_LAYOUT_PERCENTS[layout_type][index];
};

export const getPrimaryColor = () => {
  return store.getState().auth.school.primary_color;
};

export const getPrimaryTextColor = () => {
  const color = store.getState().auth?.school?.primary_color || '#34c3ee';
  const secondary_color = store.getState().auth?.school?.secondary_color;
  const textColor = secondary_color || getTextColor(color);
  return textColor;
};

export const getSecondaryColor = () => {
  return store.getState().auth.school.secondary_color;
};

const getFileType = (url: string): string => {
  if (url) {
    if(url.match('.pdf')) return 'pdf'
    else if(url.match('.doc')) return 'doc'
    else return 'img'
  } else return '';
};

export const getIcon = (url: string): string => {
  const type = getFileType(url);

  return type;
};
