import * as React from 'react';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { ROUTES } from 'src/constants';

import {
  BREADCRUMBS_SUBSCRIPTION,
  MOUNT_PAYMENT_INFO,
} from '../../constants';
import { CardFields } from '../../components';
import { mountCardSettings } from '../../handlers';

import poweredByStripeImg from 'src/assets/img/stripe.svg';


export const CardSettings = () => {
  React.useEffect(() => {
    mountCardSettings();
  }, []);

  return (
    <div className="page-content invite-user settings-subscription">
      <Breadcrumbs title="Passwords settings" to={ROUTES.settings} list={BREADCRUMBS_SUBSCRIPTION}/>
      <div className="page-content-body">
        <h1>Payment Information</h1>
        <Loader id={MOUNT_PAYMENT_INFO}>
          <CardFields/>
        </Loader>
        <a href="https://stripe.com/" target="_blank" style={{margin: '20px auto'}}>
          <img style={{ width: '150px' }} src={poweredByStripeImg} alt="powered by Stripe" />
        </a>
      </div>
    </div>
  )
};
