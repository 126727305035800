import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { authReducer } from './auth';
import { overlayReducer } from './overlay';
import { modalReducer } from './modal';

import { layoutReducer } from 'src/modules/layout';
import { landingReducer } from 'src/modules/landing/reducers';
import { manageUsersReducer, schoolReducer, subscriptionReducer, invoicesReducer } from 'src/modules/settings/reducers';
import { onBoardingReducer } from 'src/modules/onBoarding/reducers';
import { newsletterReducer } from 'src/modules/newsletters/reducers';
import { editorReducer } from 'src/modules/editor/reducers';

import { loaderReducer } from 'src/components/loader/reducer';
import logger from 'redux-logger'

const reducer = {
  auth: authReducer,
  layout: layoutReducer,
  loader: loaderReducer,
  landing: landingReducer,
  overlay: overlayReducer,
  manageUsers: manageUsersReducer,
  school: schoolReducer,
  onBoarding: onBoardingReducer,
  newsletter: newsletterReducer,
  editor: editorReducer,
  subscription: subscriptionReducer,
  invoices: invoicesReducer,
  modal: modalReducer,
};

const middleware = [...getDefaultMiddleware(), logger];

export const store = configureStore({ reducer, middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger) });

export * from './config';
