export const ROUTES = {
  // Landing Routes
  main: '/',
  home: '/home',
  homeInvited: '/home/:token',
  howItWorks: '/how-it-works',
  pricing: '/pricing',
  contact: '/contact',

  // Auth Routes (unused)
  login: '/login',
  register: '/home/:token',
  forgotPassword: '/forgot-password',

  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  cookies: '/cookies-policy',

  // App Routes
  app: '/app',
  published: '/published/:slug',
  newsletters: '/app/newsletters',
  dashboard: '/app/dashboard',
  settings: '/app/settings',

  // Settings
  manageUsers: '/app/settings/manage-users',
  inviteUser: '/app/settings/manage-users/invite-user',
  inviteSubscriber: '/app/settings/manage-users/invite-subscriber',
  editUser: '/app/settings/manage-users/edit-user/:id',
  editSubs: '/app/settings/manage-users/edit-subscriber/:id',
  myDetails: '/app/settings/my-details',
  passwords: '/app/settings/change-password',
  schoolSettings: '/app/settings/school-settings',
  newsletterBranding: '/app/settings/newsletter-branding',
  subscription: '/app/settings/subscription',
  invoices: '/app/settings/invoice',
  choosePlan: '/app/settings/choose-plan',
  billingInfo: '/app/settings/billing-info',
  billingInfoUpdate: '/app/settings/billing-info/update',
  cardSettings: '/app/settings/card-settings',

  // Newsletters
  createLetter: '/app/newsletters/create',
  editor: '/app/newsletters/editor/:id',
  editorPublicUrl: '/app/newsletter/public-url/:id'
};
