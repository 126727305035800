import { store } from 'src/stores';
import { toggleOverlay } from 'src/components/overlay';

import { landingActions } from '../reducers';

export const toggleSidebar = () => {
  const showSidebar = store.getState().landing.showSidebar;

  toggleOverlay(!showSidebar);
  store.dispatch(landingActions.toggleSidebar(!showSidebar))
};

export const changeSidebarState = (trigger: boolean) => {
  store.dispatch(landingActions.toggleSidebar(trigger))
};
