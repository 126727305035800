import * as React from 'react';
import { Form, Formik } from 'formik';

import { Footer, GetStartedBlock, PricingInfo, PricingBlock } from '../../components';
import { changeHeaderType, changeMobileHeaderType } from '../../handlers';

import { InitialFormValue } from '../../form';

export const Pricing = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(false);
  }, []);

  return (
    <>
      <Formik initialValues={InitialFormValue} onSubmit={() => {}}>
        <Form>
          <div className="pricing-block">
            <PricingInfo/>
            <PricingBlock/>
          </div>
        </Form>
      </Formik>

      <GetStartedBlock/>
      <Footer/>
    </>
  )
};
