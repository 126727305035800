import * as React from 'react';

import { Footer } from '../../components';
import { changeHeaderType, changeMobileHeaderType } from '../../handlers';

export const Privacy = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(true);
  }, []);

  return (
    <>
      <div className='privacy-container'>
        <h1>Privacy Notice</h1>
        <p>Last Updated: May 2023</p>
        <p>
          At eSchools News we take privacy very seriously. We have prepared this Privacy Notice to ensure that we communicate to you, in the clearest way possible, how we treat your personal information. We encourage you to read this Privacy Notice carefully as it sets out how we will treat your personal information across our services.
        </p>
        <h2>1. BACKGROUND</h2>
        <p>1.1. We are committed to safeguarding the privacy of the users of our services. Our services include and will hereby be referred to as ‘Our Services’, specifically when you purchase our eSchools News service, where you are a prospective customer of our business, or where you are another type of business contact, such as a supplier or service provider to our business.</p>
        <p>1.2. You may also wish to visit our support websites, used to promote eSchools services and tutorial resources, specifically <a href='https://www.eschools.co.uk/' target='_blank'>https://www.eschools.co.uk/</a> and <a href='https://academy.eschools.co.uk/' target='_blank'>https://academy.eschools.co.uk/</a>), when doing so we advise consulting our eSchools Privacy Notice which can be found here: <a href='https://www.eschools.co.uk/policies' target='_blank'>https://www.eschools.co.uk/policies</a>.</p>
        <p>1.3. eSchools News can be used in collaboration with eSchools to send Newsletter content. If using communication tools provided by eSchools, we advise consulting our eSchools Privacy Notice which can be found here: <a href='https://www.eschools.co.uk/policies' target='_blank'>https://www.eschools.co.uk/policies</a>.</p>
        <p>1.4. This notice sets out what information we collect about you, what we use it for and whom we share it with. It also explains your rights under data protection laws and what to do if you have any concerns about your personal data.</p>
        <p>1.5. We may sometimes need to update this Privacy Notice, to reflect any changes to the way our services are provided or to comply with new business practices or legal requirements. You can find our latest notice on our websites or by asking us for a copy and encourage you to check it regularly to see whether any changes have occurred.</p>
        <h2>2. WHO WE ARE AND OTHER IMPORTANT INFORMATION</h2>
        <p>2.1. eSchools News is a product of ESCHOOLS LIMITED, registered in England and Wales with company number 06841441 with our registered address at Blake House 18 Blake Street, York, YO1 8QG (<b>we</b>, <b>us</b>, <b>our</b> and <b>eSchools</b>).</p>
        <p>2.2. We have appointed a Data Protection Officer who is responsible for overseeing questions in relation to this Privacy Notice. If you have any questions about this Privacy Notice, including any requests to exercise your legal rights, please contact the Data Protection Officer at our registered address or via email (<a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>)</p>
        <p>2.3. We are registered with the Information Commissioner’s Office (ICO), the UK regulator for data protection matters, under number Z2549500.</p>
        <p>2.4. We will only process personal information about you in accordance with the UK Data Protection Regulation Legislation which for the purposes of this Privacy Notice shall mean: all applicable data protection and privacy legislation in force From time to time in the UK including without limitation the UK GDPR; the Data Protection Act 2018 (and regulations made thereunder) (DPA 2018); and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended; and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications); and the guidance and codes of practice issued by the Commissioner or other relevant regulatory authority and which are applicable to a party (“Data Protection Legislation”).</p>
        <p>2.5. For the purposes of this notice ‘Data processor’ and ‘data controller’ shall be interpreted and construed by reference to the term ‘processor’ and ‘controller’ as defined in the Data Protection Legislation.</p>
        <p>2.6. eSchools News acts as a data processor, using the data in a way that supports the school to use our services. In these cases, the school will be the data controller and the entity responsible for deciding how and when your personal data is collected and used.</p>
        <p>2.7. When you contact our support services, we may need to take certain data directly from you, (such as your name and your school) in order to respond to your inquiry. For this limited data only, we will be the person responsible for deciding how and when your data is used, and we will be the data controller.</p>
        <p>2.8. We are a data controller when we collect personal information from suppliers and other third parties who interact with us.</p>
        <h2>3. CONTACT DETAILS</h2>
        <p>
          3.1. If you have any questions about this Privacy Notice or the way that we use information, please get in touch using the following details:
          <br />
          <b>Email address:</b> <a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>
          <br />
          <b>Postal address:</b> Blake House, 18 Blake Street, York, YO1 8QG
        </p>
        <h2>4. THE INFORMATION WE COLLECT ABOUT YOU</h2>
        <p>
          4.1. We may process or temporarily store the following kinds of personal information:
          <br />
          <ul>
            <li>information about your computer and about your visits to and use of Our Services and our support websites (including but not limited to, your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths)</li>
            <li>information contained in or relating to any communications that you send to us or send through our website (including, the communication content) via online forms</li>
            <li>any other personal information that you choose to send to us via online forms</li>
            <li>Data freely given to us when using our support services</li>
          </ul>
        </p>
        <p>4.2. Before you disclose to us the personal information of another person, you must obtain that person's consent to both the disclosure and the processing of that personal information in accordance with the terms of this policy.</p>
        <p>4.3. eSchools websites may request cookies to be set on your device. We use cookies to let us know when you visit our websites, how you interact with us and to enrich your user experience. You can adapt your cookie preferences, although please note that blocking some types of cookies may impact your experience on our websites and the services we are able to offer. We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing. For further information on how you can manage and set your cookie preferences, please see our Cookie Policy.</p>
        <p>4.4. Session IP addresses are retained to monitor and safeguard against improper usage of our services.</p>
        <p>4.5. Newsletter content is added directly by School Users. This information is only used in the way the user intends and is not used for any other purposes by eSchools. At a time when a school ceases to use our services the content is removed within a 28 day period as detailed in our agreement with the school.</p>
        <p>4.6. eSchools News understands our obligation to be mindful about the data we process and only process data that is required to fulfil our obligations in providing our services; to a school who enters into an agreement to use our services and you, the user.</p>
        <h2>5. HOW WE USE YOUR INFORMATION</h2>
        <p>5.1. We may use your personal information to:</p>
        <ul>
          <li>administer our services</li>
          <li>enable your use of our services</li>
          <li>Troubleshoot and problem solve</li>
          <li>send you email notifications that you have specifically requested. You can manage your preferences at any time.</li>
          <li>keep our services secure, safeguard against improper use of our services and prevent fraud.</li>
        </ul>
        <p>5.2. We will only use your personal data when the law allows us to. We (or third party data processors, agents and subcontractors acting on our behalf) may collect, store and use your personal information by way of different methods to collect data from and about you including through:</p>
        <ul>
          <li><b>Direct interactions.</b> This is information (including Identity and Contact Data) you consent to giving us about you when you fill in forms through our support websites or by corresponding with us (for example, by email or telephone). It includes information you provide when you register or subscribe to any of our Services, visit or use our Sites and finally when you report a problem with our Services. If you contact us, we will keep a record of that correspondence.</li>
          <li><b>Information we collect about you and your device either automated or otherwise.</b> Each time you visit our Site or use our Services, we will automatically collect personal data including Technical Log Data. We collect this data using cookies and other similar technologies including server logs. We may also receive technical data about you if you visit other Sites employing our cookies.</li>
          <li><b>Information we receive from other sources including third parties and publicly available sources.</b> We may receive personal data about you from various third parties such as government bodies, local authorities or schools, but we will only use and process the personal data where we are allowed to do so.</li>
        </ul>
        <p>5.3. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions, which include strict confidentiality and contractual terms.</p>
        <p>5.4. We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.</p>
        <p>5.5. We will only use your personal data when the law allows us to. Most commonly we will use your personal data in the following circumstances:</p>
        <ul>
          <li>Where you have consented before the processing.</li>
          <li>Where we need to perform a contract we are about to enter into, or have entered into with you.</li>
          <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
          <li>Where we need to comply with a legal or regulatory obligation</li>
          <li>Where it is a public task i.e. the processing is necessary for us to perform a task in the public interest or for any official functions, and the task or function has a clear basis in law.</li>
        </ul>
        <p>5.6. When we are acting as a data controller, we will use your personal data for the purposes set out in the table below. The law sets out a number of different reasons for which we can collect and use your data. The legal grounds on which we collect and use your data are also set out in the table below.</p>
        <table>
          <tr>
            <th>Purpose for using your data</th>
            <th>Legal ground for using your data for this purpose</th>
          </tr>
          <tr>
            <td>To allow you to access your account on our Site.</td>
            <td>Necessary for our legitimate interests (to allow Authorised Users on our Site to use it)</td>
          </tr>
          <tr>
            <td>To provide support to you when you contact us</td>
            <td>Necessary for our legitimate interests (to respond to support calls as our users would expect).</td>
          </tr>
          <tr>
            <td>To manage our relationship with you, which will include notifying you about changes to our Privacy Notice.</td>
            <td>To enter into and/or perform a contract with you. To comply with a legal obligation. Necessary for our legitimate interests (to provide important updates to our users). To enable a public task to be performed.</td>
          </tr>
          <tr>
            <td>To administer and protect our business and our Site, Services and/or our applications (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).</td>
            <td>Necessary for our legitimate interests (to protect our business and Site; to keep our Services updated).</td>
          </tr>
          <tr>
            <td>To use data analytics to improve our Site, products/services, marketing, customer relationships and experiences.</td>
            <td>Necessary for our legitimate interests (to continuously improve our Services for our customers and users).</td>
          </tr>
          <tr>
            <td>To create anonymous aggregated data, as set out below.</td>
            <td>Necessary for our legitimate interests (to provide additional benefits and functionality to our customers and users without disclosing personal data).</td>
          </tr>
          <tr>
            <td>To comply with applicable laws and regulatory obligations.</td>
            <td>To comply with a legal obligation.</td>
          </tr>
        </table>
        <p>5.7. Aggregated Data We may aggregate and use non-personally identifiable data we have collected from you and others. This data will in no way identify you or any other individual. We may use this aggregated non-personally identifiable data to:</p>
        <ul>
          <li>assist us to better understand how our users are using our Site and services;</li>
          <li>provide users with further information regarding the uses and benefits of our Site and services;</li>
          <li>enhance school productivity, including by creating useful school insights from that aggregated data and allowing benchmarking of performance against aggregated data; and</li>
          <li>otherwise to improve our Site and services.</li>
        </ul>
        <h2>6. WHO WE SHARE YOUR INFORMATION WITH</h2>
        <p>
          6.1. We share (or may share) your personal data with:
          <br />
          <b>Our personnel:</b> our employees (or other types of workers) who have contracts containing confidentiality and data protection obligations.
          <br />
          <b>Our supply chain:</b> other organisations that help us provide our goods. We ensure these organisations only have access to the information required to provide the support we use them and have a contract with them that contains confidentiality and data protection obligations.
        </p>
        <p>6.2. If we were asked to provide personal data in response to a court order or legal request (e.g. from the police), we would seek legal advice before disclosing any information and carefully consider the impact on your rights when providing a response</p>
        <p>6.3. We may also disclose your personal information to the school in question in the case of improper use on the platform by individuals.</p>
        <p>6.4. Where any of your data is required for such a purpose, we will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under the Data Protection Legislation.</p>
        <h2>7. WHERE YOUR INFORMATION IS LOCATED OR TRANSFERRED TO</h2>
        <p>7.1. We store your personal data on our servers in the EEA.</p>
        <p>
          7.2. Whenever we transfer your personal data outside of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
          <br />
          (a) the Personal Data is transferred to or processed in a territory which is subject to adequacy regulations under the Data Protection Legislation that the territory provides adequate protection for the privacy rights of individuals; or
          <br />
          (b) we participate in a valid cross-border transfer mechanism under Data Protection Legislation, so that we (and, where appropriate, the school) can ensure that appropriate safeguards are in place to ensure an adequate level of protection with respect to the privacy rights of individuals as required under the UK GDPR; or
          <br />
          (c) the transfer otherwise complies with Data Protection Legislation.
        </p>
        <p>7.3. If you access our Website or purchase our services whilst abroad then your personal data may be stored on servers located in the same country as you or your organisation. Personal information that an individual adds to our websites may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others.</p>
        <h2>8. HOW WE KEEP YOUR INFORMATION SAFE</h2>
        <p>8.1. We have implemented security measures to prevent your personal data from being accidentally or illegally lost, used or accessed by those who do not have permission. These measures include:</p>
        <ul>
          <li>access controls and user authentication (including multi-factor authentication);</li>
          <li>regular testing and review of our security measures;</li>
          <li>staff policies and training;</li>
          <li>incident and breach reporting processes;</li>
          <li>business continuity and disaster recovery processes.</li>
        </ul>
        <p>8.2. If there is an incident which has affected your personal data and we are the controller, we will notify the regulator and keep you informed (where required under data protection law). Where we act as the processor for the affected personal data, we notify the controller and support them with investigating and responding to the incident.</p>
        <p>8.3. We will store all the personal information you provide on our secure (password- and firewall-protected) servers. The web service we employ has a broad range of accreditations and certifications and the data centres used ensure the data stays within the EEA.</p>
        <p>8.4. eSchools use a Secure Sockets Layer (SSL) which creates a secure connection and uses two keys to encrypt data in transit (schools can also purchase an SSL for any masking domain). Despite this, you acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data.</p>
        <p>8.5. If you notice any unusual activity on the Website, please contact us <a href="mailto:hello@eschools.co.uk">hello@eschools.co.uk</a></p>
        <h2>9. HOW LONG WE KEEP YOUR INFORMATION</h2>
        <p>9.1. This section sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information. We will only retain your personal information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
        <p>9.2. To decide how long to keep personal data (also known as its retention period), we consider the volume, nature, and sensitivity of the personal data, the potential risk of harm to you if an incident were to happen, whether we require the personal data to achieve the purposes we have identified or whether we can achieve those purposes through other means (e.g. by using aggregated data instead), and any applicable legal requirements (e.g. minimum accounting records for HM Revenue & Customs).</p>
        <p>9.3. If you have asked for information from us or you have subscribed to our mailing list, we keep your details until you ask us to stop contacting you</p>
        <p>9.4. Notwithstanding the other provisions of this Section 9 we will retain documents (including electronic documents) containing personal data:</p>
        <ul>
          <li>To the extent that we are required to do so by law;</li>
          <li>If we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</li>
          <li>In order to establish, exercise or defend our legal rights (includingproviding information to others for the purposes of fraud prevention and reducing credit risk).</li>
        </ul>
        <h2>10. YOUR LEGAL RIGHTS</h2>
        <p>10.1. You have specific legal rights in relation to your personal data.</p>
        <p>10.2. We can decide not to take any action in relation to a request where we have been unable to confirm your identity (this is one of our security processes to make sure we keep information safe) or if we feel the request is unfounded or excessive. Usually there is no cost for exercising your data protection rights, but we may charge a fee where we decide to proceed with a request that we believe is unfounded, repetitive or excessive. If this happens we will always inform you in writing.</p>
        <p>10.3. We will respond to your legal rights request without undue delay, but within one month of us receiving your request or confirming your identity (whichever is later). We may extend this deadline by two months if your request is complex or we have received multiple requests at once. If we need to extend the deadline, we will let you know and explain why we need the extension.</p>
        <p>10.4. We do not respond directly to requests which relate to personal data for which we act as the processor. In this situation, we forward your request to the relevant controller and await their instruction before we take any action.</p>
        <p>10.5. If you wish to make any of the right requests listed below, you can reach us at <a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>.</p>
        <p>
          10.6. Your rights include:
          <br />
          Access: You must be told if your personal data is being used and you can ask for a copy of your personal data as well as information about how we are using it to make sure we are abiding by the law.
          <ul>
            <li>Correction: You can ask us to correct your personal data if it is inaccurate or incomplete. We might need to verify the new information before we make any changes.</li>
            <li>Deletion: You can ask us to delete or remove your personal data if there is no good reason for us to continue holding it or if you have asked us to stop using it (see below). If we think there is a good reason to keep the information you have asked us to delete (e.g. to comply with regulatory requirements), we will let you know and explain our decision.</li>
            <li>Restriction: You can ask us to restrict how we use your personal data and temporarily limit the way we use it.</li>
            <li>Objection: You can object to us using your personal data if you want us to stop using it. If we think there is a good reason for us to keep using the information, we will let you know and explain our decision.</li>
            <li>Portability: You can ask us to send you or another organisation an electronic copy of your personal data.</li>
            <li>Complaints: If you are unhappy with the way we collect and use your personal data, you can complain to the ICO or another relevant supervisory body, but we hope that we can respond to your concerns before it reaches that stage. Please contact us at <a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>.</li>
          </ul>
        </p>
      </div>
      <Footer hideFirstBg />
    </>
  )
};
