import { CONTENT_ITEMS } from '../constants';
import { validateURL } from '../helpers';

const required = (fields: any, name: string): object => {
  let errors = {};

  if (name && !fields[name]) errors[name] = 'Required';

  return errors;
};

const requiredSocial = (fields: any, name: string): object => {
  let errors = {};

  const keys = Object.keys(fields[name] || {});

  if (keys.length === 0) errors[name] = 'Required';

  keys.forEach((key: string) => {
    if (!fields[name][key]) errors[`${name}.${key}`] = 'Required';
    else {
      const isValidUrl = validateURL(fields[name][key]);
      if (!isValidUrl) errors[`${name}.${key}`] = 'Not valid - please add a valid link which includes http:// or https://';
    }
  });

  return errors;
};

const requiredButton = (fields: any, name: string): object => {
  let errors = {};

  const keys = Object.keys({btnText: '', url: ''});

  if (keys.length === 0) errors[name] = 'Required';

  keys.forEach((key: string) => {
    if (!(fields[name] || {})[key]) errors[`${name}.${key}`] = 'Required';
    else if (key === 'url') {
      const isValidUrl = validateURL((fields[name] || {})[key]);
      if (!isValidUrl) errors[`${name}.${key}`] = 'Not valid - please add a valid link which includes http:// or https://';
    }
  });

  return errors;
};

const requiredStar = (fields: any, name: string): object => {
  let errors = {};

  const keys = Object.keys({student: '', className: ''});

  if (keys.length === 0) errors[name] = 'Required';

  keys.forEach((key: string) => {
    if (!(fields[name] || {})[key]) errors[`${name}.${key}`] = 'Required';
  });

  return errors;
};

const attendanceValidate = (fields: any, name: string): object => {
  let errors = {};

  if (fields[name].length === 0) errors[fields[name]] = 'Required'

  fields[name].forEach(field => {
    console.log(field)
    const keys = Object.keys(field);
  
    if (keys.length === 0) errors[name][keys] = 'Required';
  
    keys.forEach((key: string) => {
      if (!(name || {})[key]) errors[`${name}.${key}`] = 'Required';
    });
  });

  console.log(errors);

  return errors;
};

export const getValidation = (editingType: string, field: string, values: any): object => {
  switch(editingType) {
    case CONTENT_ITEMS[0].id:
      return required(values, field);
    case CONTENT_ITEMS[1].id:
      return required(values, field);
    case CONTENT_ITEMS[2].id:
      return required(values, field);
    // case CONTENT_ITEMS[3].id:
    //   return {};
    // case CONTENT_ITEMS[4].id:
    //   return required(values, field);
    // case CONTENT_ITEMS[5].id:
    //   return required(values, field);
    case CONTENT_ITEMS[6].id:
      return requiredSocial(values, field);
    case CONTENT_ITEMS[7].id:
      return requiredButton(values, field);
    // case CONTENT_ITEMS[8].id: /// USE WHEN START WILL BACK!!!!!!!!!
    //   return requiredStar(values, field);
    case CONTENT_ITEMS[8].id:
      return {};
    // case CONTENT_ITEMS[10].id:
    //   return required(values, field);
    case CONTENT_ITEMS[12].id:
      return {};
    default:
      return {};
  }
};
