import * as React from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import shortId from 'shortid';
import classNames from 'classnames';

import { AnyObject } from 'src/types';
import ArrowDown from 'src/assets/img/chevron@2x.png';

import { onDiscard, onSaveField, onDelete, onEditEvent } from '../../handlers';
import { Popconfirm } from 'antd';

type Props = {
  name: string
};

export const EventField: React.FC<Props> = (props) => {
  const { name } = props;

  const { values, setFieldValue } = useFormikContext<AnyObject>();

  const handleAdd = () => {
    setFieldValue(name, [...(values[name] || []), { id: shortId() }]);
    onEditEvent(name, values[name]?.length || 0)()
  };

  const handleDeleteRow = (rowIndex: number) => () => {
    const list = _.cloneDeep(values[name]);
    _.remove(list, (item, index) => index === rowIndex);

    setFieldValue(name, list);
  };

  return (
    <div className="field-container">
      <div>
        {values[name]?.map((item: any, index: number) => (
          <FieldSet
            key={`event-${item.id}`}
            name={name}
            index={index}
            value={item}
            onDelete={handleDeleteRow(index)}
          />
        ))}
        <button className="add-row-btn app-button green" onClick={handleAdd}>
          Add event
        </button>
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};

type FieldSetProps = {
  name: string,
  index: number,
  value: { [key in string]: any }
  onDelete: () => void
};

const FieldSet: React.FC<FieldSetProps> = (props) => {
  const { name, index, onDelete, value = {} } = props;
  const [collapsed, toggle] = React.useState(false);

  const date = value.date?.format('DD.MM.YYYY');

  return (
    <div className={classNames('event-item', { collapsed })}>
      <div className="event-content">
        <div className="event-date">
          {date}
        </div>
        <div className="event-name">
          {value.title}
        </div>
        <img className="arrow-collapse" src={ArrowDown} alt="arrow-down" onClick={() => toggle(!collapsed)}/>
      </div>
      <div className="event-controls">
        <button className="app-button orange" onClick={onEditEvent(name, index)}>
          Edit
        </button>
        <button className="app-button red" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  )
};
