import { loadingWrapper } from 'src/utils';
import { MOUNT_APP } from 'src/constants';

import { getSchool } from './school';
import { getProfileInfo } from '../modules/settings/services';

export const mountApp = loadingWrapper(
  MOUNT_APP,
  async () => {
    await getSchool();
    await getProfileInfo();
    
    return true;
  }
);
