import axis from 'axios';

import { store } from 'src/stores';
import { API_NEWSLETTERS } from 'src/constants';
import { loadingWrapper, requestWrapper } from 'src/utils';

import { editorActions } from '../reducers';
import { GET_NEWSLETTER_PUBLIC } from '../constants';

export const getPublicUrlRequest = requestWrapper((id: number) => {
  return axis.get(`${API_NEWSLETTERS}/${id}`);
});

export const getPublicUrl = loadingWrapper(
  GET_NEWSLETTER_PUBLIC,
  async (id: number) => {
    const { success, response } = await getPublicUrlRequest(id);

    if (success) {
      store.dispatch(editorActions.setNewsletter({
        ...response.data.news_letter.data.attributes,
        initialFormValues: {},
        fields: []
      }));
    }
  }
);
