import { ROUTES } from 'src/constants';

export const TAB_LIST = [
  {
    id: 'newsletters-tab',
    name: 'Newsletters',
    path: ROUTES.newsletters
  }, {
    id: 'settings-tab',
    name: 'Settings',
    path: ROUTES.settings
  },
  {
    id: 'dashboard-tab',
    name: 'Dashboard',
    path: ROUTES.dashboard
  }, 
];
