import LinkedIn from 'src/assets/img/socials/linkedin.svg';
import Twitter from 'src/assets/img/socials/twitter.svg';
import Facebook from 'src/assets/img/socials/facebook.svg';
import Instagram from 'src/assets/img/socials/instagram.png';
import Youtube from 'src/assets/img/socials/youtube.svg';

// export const SOCIALS = [
//   {
//     id: 'linkedin',
//     img: LinkedIn,
//   }, {
//     id: 'twitter',
//     img: Twitter,
//   }, {
//     id: 'facebook',
//     img: Facebook,
//   }, {
//     id: 'instagram',
//     img: Instagram,
//   },
//   {
//     id: 'youtube',
//     img: Youtube,
//   },
// ];

export const SOCIALS = [
  {
    id: 'facebook',
    img: 'https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png',
    styles: {
      backgroundColor: '#1977f2',
      borderRadius: '50%',
      padding: '5px',
    },
  }, {
    id: 'twitter',
    img: 'https://www.mailjet.com/images/theme/v1/icons/ico-social/twitter.png',
    styles: {
      backgroundColor: '#1da1f1',
      borderRadius: '50%',
      padding: '5px',
    },
  }, {
    id: 'instagram',
    img: 'https://www.mailjet.com/images/theme/v1/icons/ico-social/instagram.png',
    styles: {
      backgroundColor: '#ef5d33',
      borderRadius: '50%',
      padding: '5px',
    },
  }, {
    id: 'linkedin',
    img: 'https://www.mailjet.com/images/theme/v1/icons/ico-social/linkedin.png',
    styles: {
      backgroundColor: '#0078b6',
      borderRadius: '50%',
      padding: '5px',
    },
  }, {
    id: 'youtube',
    img: 'https://www.mailjet.com/images/theme/v1/icons/ico-social/youtube.png',
    styles: {
      backgroundColor: '#FF0000',
      borderRadius: '50%',
      padding: '5px',
    },
  },
];
