import React from 'react';
import { Field, useFormikContext } from 'formik';

import { useAppSelector } from 'src/stores';
import { Input } from 'src/components/fields';
import { Button } from 'src/components/button';

import { STEPPER_ITEMS, ON_SUBMIT_ON_BOARDING } from '../constants';
import { Form, Formik } from 'formik';
import { onSubmitMyDetails } from '../handlers';
import { history } from 'src/utils';
import { ROUTES } from 'src/constants';
import { onSubmit } from '../handlers';


export const SetPhone = () => {
  const currentStep = useAppSelector(state => state.onBoarding.currentStep);
  const { values } = useFormikContext<{ phone: string }>();

  if (currentStep?.id !== STEPPER_ITEMS[3].id) return null;

  const handleConfirm = () => {
    onSubmitMyDetails(values, () => {});
  }
  
  return (
    <>
      <div className="question start-stepper">
        <div className="step-item active">4</div>
        {STEPPER_ITEMS[3].question}
      </div>

      <Field name="phone" className="school-input" label="School phone" component={Input}/>

      <Button
        loaderId={ON_SUBMIT_ON_BOARDING}
        htmlType="button"
        onClick={handleConfirm}
        className="app-button green"
        disabled={!values.phone}
      >
        Confirm
      </Button>
    </>
  )
};
