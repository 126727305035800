import Newsletter from 'src/assets/img/landing/newsletter.png';
import Interface from 'src/assets/img/landing/interface.png';
import Share from 'src/assets/img/landing/share.png';
import Send from 'src/assets/img/landing/send.png';

export const KEY_FEATURES_LIST = [{
  id: 'newsletter',
  img: Newsletter,
  title: 'Great looking newsletters',
  description: 'It’s simple to add your school logo and branding to your newsletters. Take advantage of pre-made templates to effortlessly promote your school and add that extra bit of sparkle.'
}, {
  id: 'interface',
  img: Interface,
  title: 'Simple <br/>content creator',
  description: 'Our wonderful editor allows you to easily add text, images, videos and downloads within a click of the button. It’s easy peasy to resize, edit and make your content look newsworthy!'
},
// {
//   id: 'share',
//   img: Share,
//   title: 'Share the <br/>workload',
//   description: 'Easily allow other staff in your school to add information into your newsletters. Create a section for them to complete and send them a quick link… we’ll also help you remind them with automated reminders!'
// },
{
  id: 'send',
  img: Send,
  title: 'Easily send <br/>to parents',
  description: 'Engage with your parents through easy to send newsletters. Simply add the link to your school website or send via email. The newsletters are super mobile friendly... parents can view on the go!'
}];

export const ICONS = ['message', 'letter', 'list', 'pdf', 'phone', 'list2', 'cloud'];
