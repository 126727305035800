import _ from 'lodash';

import { history } from 'src/utils';
import { ROUTES } from 'src/constants';

import { ProfileValueType } from '../types';
import { myDetailsMapper } from '../utils';
import { changeProfile } from '../services';

export const onSubmitMyDetails = async (values: ProfileValueType) => {
  const data = myDetailsMapper(values);
  if (_.isEmpty(data.user)) {
    history.push(ROUTES.settings);
    return;
  }

  changeProfile(data)
};
