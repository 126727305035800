import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { logStart, logSuccess } from 'src/components/loader';
import { API_INVITE, API_MANAGERS, ROUTES, API_SUBSCRIBERS } from 'src/constants';
import { requestWrapper, history, loadingWrapper } from 'src/utils';

import { InviteUserType, InviteSubsType } from '../types';
import { ON_EDIT_ADMIN, ON_SUBMIT_INVITE } from '../constants';

const inviteRequest = requestWrapper((payload: InviteUserType) => {
  return axios.post(API_INVITE, payload);
});

const inviteSubsRequest = requestWrapper((payload: InviteUserType) => {
  return axios.post(API_SUBSCRIBERS, payload);
});

const editAdminRequest = requestWrapper((id: number, payload: InviteUserType) => {
  return axios.patch(`${API_MANAGERS}/${id}`, payload);
});

const editSubsRequest = requestWrapper((id: number, payload: InviteUserType) => {
  return axios.patch(`${API_SUBSCRIBERS}/${id}`, payload);
});

export const inviteUser = createAsyncThunk(
  'invite/create',
  async (userData: InviteUserType) => {
    logStart(ON_SUBMIT_INVITE);
    const { success } = await inviteRequest(userData);

    if (success) {
      history.push(ROUTES.manageUsers)
    }
    logSuccess(ON_SUBMIT_INVITE);
  }
);

export const inviteSubscriber = createAsyncThunk(
  'invite/create-subs',
  async (userData: InviteSubsType) => {
    logStart(ON_SUBMIT_INVITE);
    const { success } = await inviteSubsRequest(userData);

    if (success) {
      history.push(ROUTES.manageUsers)
    }
    logSuccess(ON_SUBMIT_INVITE);
  }
);


export const editAdmin = loadingWrapper(ON_EDIT_ADMIN, async (id: number, data: InviteUserType) => {
  const { success } = await editAdminRequest(id, data);

  if (success) {
    history.push(ROUTES.manageUsers);
  }
});

export const editSubs = loadingWrapper(ON_EDIT_ADMIN, async (id: number, data: InviteUserType) => {
  const { success } = await editSubsRequest(id, data);

  if (success) {
    history.push(ROUTES.manageUsers);
  }
});
