import { loadingWrapper } from 'src/utils';

import { ON_MOUNT_NEWSLETTERS } from '../constants';
import { getNewsletters } from '../services';

export const mount = loadingWrapper(
  ON_MOUNT_NEWSLETTERS,
  async () => {
    await getNewsletters();
  }
);
