import * as React from 'react';
import classNames from 'classnames';

import { Show } from 'src/components/conditions';
import ArrowDownSvg from 'src/assets/img/chevron@2x.png';
import { useAppSelector } from 'src/stores';

import { TYPE_TO_LABEL } from '../../constants';
import { UserItemControls } from './UserItemControls';

type UserProps = {
  firstname: string,
  lastname: string,
  role: string,
  type: string,
  email: string
  userId: string
};

export const UserList = () => {
  const users = useAppSelector(state => state.manageUsers.users);
  const owner = useAppSelector(state => state.manageUsers?.owner);

  return (
    <div className="user-list">
      {users.map(user => (
        <UserItem
          key={`user-${user.id}`}
          userId={user.id}
          firstname={user.attributes.first_name}
          lastname={user.attributes.last_name}
          role={user.attributes.role}
          type={user.attributes.status}
          email={user.attributes.email}
        />
      ))}
      { owner ? 
         <UserItem
          userId={owner.id}
          firstname={owner.first_name}
          lastname={owner.last_name}
          role='owner'
          type={owner.status}
          email={owner.email}
       />
      : null  }
    </div>
  );
};

const UserItem = (props: UserProps) => {
  const {
    firstname,
    lastname,
    role,
    type,
    email,
    userId
  } = props;

  const [open, setOpen] = React.useState(false);

  const desktopItem = (
    <div className="hide-mobile">
      <div className="item-grid">
        <div className="name-info">
          <div className="full-name-info">{firstname} {lastname}</div>
          <Show if={Boolean(type)}>
            <div className={classNames('user-type-label', type)}>
              {TYPE_TO_LABEL[type]}
            </div>
          </Show>
        </div>

        <div className="user-role user-badge">{role}</div>

        <div className="user-role email">{email}</div>

        <Show if={type !== 'invited'}>
          <UserItemControls
            userId={userId}
            fisrstname={firstname}
            lastname={lastname}
            type={type}
            role={role}
          />
        </Show>
      </div>
    </div>
  );

  return (
    <div className={classNames('user-item', { open })}>
      <div className="hide-desktop">
        <div className="user-short-info">
          <div className='truncate-block'>
            <div className="full-name-info">{firstname} {lastname}</div>
            <div className="user-role">{role}</div>
          </div>
          <div className={classNames('user-type-label', type)}>
            {TYPE_TO_LABEL[type]}
          </div>
          <img
            className="arrow-collapse"
            src={ArrowDownSvg}
            alt="arrow-down"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="collapsible-info">
          <div className='truncate-block'>
            <div className="user-role">{email}</div>
          </div>

          <Show if={type !== 'invited'}>
            <UserItemControls
              userId={userId}
              fisrstname={firstname}
              lastname={lastname}
              type={type}
              role={role}
            />
          </Show>
        </div>
      </div>

      {desktopItem}
    </div>
  );
};
