import * as React from 'react';
import _ from 'lodash';

import { store } from 'src/stores';
import { TRIAL_SUBSCRIPTION, ROUTES } from 'src/constants';
import { toast, history } from 'src/utils';

import { getNewsletters, deleteNewsLetter, unpublishNewsLetter } from '../services';
import { newsletterActions } from '../reducers';
import { FILTER_TYPES, ORDER_TYPES, DIRECTION_ORDER_TYPES } from '../constants';

const debouncedNewsletters = _.debounce(getNewsletters, 500);

export const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  store.dispatch(newsletterActions.setControl({
    page: 1,
    search_string: e.target.value,
    direction: DIRECTION_ORDER_TYPES.asc,
    order: ORDER_TYPES.title,
  }));

  debouncedNewsletters();
};

export const handleShowMore = (page: number) => {
  const isCreated = store.getState().newsletter.controls.order === ORDER_TYPES.created_at;
  store.dispatch(newsletterActions.setControl({
    page,
    direction: isCreated ? DIRECTION_ORDER_TYPES.desc : DIRECTION_ORDER_TYPES.asc,
  }));

  getNewsletters();
};

export const onSelectControl = (name: 'filter' | 'order', value: FILTER_TYPES | ORDER_TYPES) => () => {
  store.dispatch(newsletterActions.setControl({
    [name]: value,
    page: 1,
    total_pages: 1,
  }));
  if (name === 'order' && value === ORDER_TYPES.title) {
    store.dispatch(newsletterActions.setControl({
      direction: DIRECTION_ORDER_TYPES.asc
    }));
  } else {
    store.dispatch(newsletterActions.setControl({
      direction: DIRECTION_ORDER_TYPES.desc
    }));
  }
  // console.log(name, value)

  getNewsletters();
};

export const onDeleteNewsletter = (id: number) => () => {
  deleteNewsLetter(id)
};

export const onUnpublish = (id: number) => () => {
  unpublishNewsLetter(id)
};

export const onCreateLetter = () => {
  // const noLetters = _.isEmpty(store.getState().newsletter.list);
  // const isTrial = store.getState().auth.school.subscription_status === TRIAL_SUBSCRIPTION;

  // if (isTrial && !noLetters) {
  //   toast('In trial mode you can create only one letter', 'error');
  //   return;
  // }

  history.push(ROUTES.createLetter);
};
