import React from 'react';
import { Formik, Form } from 'formik';

import { MobileNavigation } from 'src/modules/layout/components';
import { Breadcrumbs } from 'src/components/breadcrumbs';

import {
  BREADCRUMBS_ON_BOARDING_START,
  INITIAL_VALUES,
  STEPPER_ITEMS,
  ONBOARDING_VALIDATION,
} from '../constants';
import { GetStarted, Stepper, SchoolName, UploadLogo, SetEmail, SetPhone } from '../components';
import { onSubmit } from '../handlers';

export const OnBoarding = () => {

  return (
    <div className="page-content start-screen">
      <MobileNavigation/>
      {/* <Breadcrumbs list={BREADCRUMBS_ON_BOARDING_START}/> */}
      <div className="page-content-body start-screen-body">
        <GetStarted />
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={onSubmit}
          validationSchema={ONBOARDING_VALIDATION}
        >
          <Form>
            <Stepper stepperList={STEPPER_ITEMS}/>
            <SchoolName />
            <UploadLogo />
            <SetEmail />
            <SetPhone />
          </Form>
        </Formik>
      </div>
    </div>
  )
};
