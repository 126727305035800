import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_NEWSLETTERS, API_LAST_NEWSLETTER, ROUTES } from 'src/constants';
import { history, loadingWrapper, requestWrapper } from 'src/utils';

import { ON_SUBMIT_ON_BOARDING } from '../constants';
import { NewsletterBEType } from '../types';

const createNewsletterRequest = requestWrapper((payload: NewsletterBEType) => {
  return axios.post(API_NEWSLETTERS, payload);
});

const createPreviousNewsletterRequest = requestWrapper((payload) => {
  return axios.get(API_LAST_NEWSLETTER, payload);
})

export const createNewsletter = loadingWrapper(
  ON_SUBMIT_ON_BOARDING,
  async (values: NewsletterBEType) => {

    if(values?.news_letter?.layout_type == 'latest_template') {
      const { success: previousSuccess, response: previousResponse } = await createPreviousNewsletterRequest({ news_letter: { title: values.news_letter.title } })

      if(previousSuccess) {
        const { success, response } =  await createNewsletterRequest({ news_letter: {
          title: values?.news_letter?.title,
          body: previousResponse?.data?.news_letter?.data?.attributes?.body,
          layout_type: previousResponse?.data?.news_letter?.data?.attributes?.layout_type,
          code: previousResponse?.data?.news_letter?.data?.attributes?.code
        } });

        if (success) {
          const path = generatePath(ROUTES.editor, {
            id: response.data.news_letter.data.id
          });
          history.push(path);
        }
  
        return success;
      }

    } else {
      const { success, response } =  await createNewsletterRequest(values);

      if (success) {
        const path = generatePath(ROUTES.editor, {
          id: response.data.news_letter.data.id
        });
        history.push(path);
      }

      return success;
    }
    
    // const { success, response } = await createPreviousNewsletterRequest({ news_letter: { title: values.news_letter.title } });

  }
);
