import * as React from 'react';

import { Footer, GetStartedBlock, CarouselBlock } from '../../components';
import { changeHeaderType, changeMobileHeaderType } from '../../handlers';

export const HowItWorks = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(true);
  }, []);

  return (
    <>
      <CarouselBlock />
      <GetStartedBlock/>
      <Footer/>
    </>
  )
};
