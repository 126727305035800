import { matchPath, generatePath } from 'react-router-dom';

import { loadingWrapper, history, toast } from 'src/utils';
import { ROUTES } from 'src/constants';

import { ON_MOUNT_PUBLIC } from '../constants';
import { getPublicUrl } from '../services';

export const mountPublicUrlPage = loadingWrapper(
  ON_MOUNT_PUBLIC,
  async () => {
    const { params } = matchPath(
      history.location.pathname,
      ROUTES.editorPublicUrl
    ) || {} as any;

    await getPublicUrl(params?.id);
  }
);

export const getLinkToEditor = () => {
  const { params } = matchPath(
    history.location.pathname,
    ROUTES.editorPublicUrl
  ) || {} as any;

  return generatePath(ROUTES.editor, { id: params?.id })
};

export const afterCopy = () => {
  toast('Copied to clipboard', 'success')
};
