export const MainLayout = (head: string, body: string, footer: string) => `
<mjml>
    <mj-head>
        <mj-font href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap"/>
        <mj-attributes>
          <mj-all font-family="'Averta'" />
        </mj-attributes>
        <mj-style>
          p {
            margin: 0;
          }
          .bordered-text-container {
            margin: 6px 0;
          }
          .download-button-container {
            padding: 0;
            margin: 0 0 8px;
          }
          .image-group-container {
            margin: 0 0 8px !important;
            max-width: none !important;
          }
          .social-container {
            margin: 8px 0;
          }
          .event-time {
            text-align: center;
            padding: 0 0 0 8px !important;
          }
          .event-bottom-wrapper {
            padding: 8px 0 14px 12px !important;
          }
          .footer-links {
            display: flex !important;
          }
          @media (max-width: 539px) {
            .event-container {
              margin: 0 !important;
            }

            .event-time {
              text-align: left;
            }

            .event-bottom-wrapper {
              padding: 0 !important;
            }
            .event-title {
              padding: 0 !important;
            }
          }
        </mj-style>
    </mj-head>
    <mj-body background-color="#e9e9f3" color="#55575d" font-family="'Averta'">
      <mj-section padding="14px 0">
        ${head}
        ${body}
        ${footer}
      </mj-section>
    </mj-body>
</mjml>
`;
