import * as React from 'react';
import { Formik, Form } from 'formik';

import { MobileNavigation } from 'src/modules/layout/components';
import { Breadcrumbs } from 'src/components/breadcrumbs';

import {
  BREADCRUMBS_CREATE_LETTER,
  INITIAL_NEWSLETTER_VALUES,
  CREATE_LETTER_STEPPER
} from '../constants';
import { Stepper, NewsletterName, Template } from '../components';
import { onCreateLetter, mountCreateLetter } from '../handlers';

export const CreateNewsletter = () => {
  React.useEffect(() => {
    mountCreateLetter();
  }, []);

  return (
    <div className="page-content start-screen">
      <MobileNavigation/>
      <Breadcrumbs list={BREADCRUMBS_CREATE_LETTER}/>
      <div className="page-content-body start-screen-body">
        <Formik
          initialValues={INITIAL_NEWSLETTER_VALUES}
          onSubmit={onCreateLetter}
        >
          <Form>
            <Stepper stepperList={CREATE_LETTER_STEPPER}/>
            <NewsletterName />
            <Template />
          </Form>
        </Formik>
      </div>
    </div>
  )
};
