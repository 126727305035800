import * as React from 'react';
import { Form, Formik } from 'formik';

import { Breadcrumbs } from 'src/components/breadcrumbs';
import { Loader } from 'src/components/loader';
import { Show } from 'src/components/conditions';
import { ROUTES } from 'src/constants';
import { Button } from 'src/components/button';
import { useAppSelector } from 'src/stores';

import {
  BREADCRUMBS_BILLING,
  BILLING_INFO_VALIDATION,
  ON_SUBMIT_BILLING_INFO,
  GET_BILLING_INFO,
  BILLING_INFO_FORM
} from '../../constants';
import { BillingInfoFields } from '../../components';
import { handleChangeBillingInfo } from '../../handlers';
import { getBillingInfo } from '../../services';

type Props = {
  saveBtn?: React.ReactNode,
  submit?: (...args: any[]) => any
};

export const BillingInfo = (props: Props) => {
  const { saveBtn, submit } = props;
  const initialValues = useAppSelector(state => state.subscription.billingInfo);
  const {subscription_status}  = useAppSelector(state => state.auth?.school);

  React.useEffect(() => {
    getBillingInfo();
  }, []);

  return (
    <div className="page-content invite-user">
      <Breadcrumbs title="Passwords settings" to={ROUTES.settings} list={BREADCRUMBS_BILLING}/>
      <div className="page-content-body">
        <h1>Billing</h1>
        <Loader id={GET_BILLING_INFO}>
          <Formik
            initialValues={initialValues || BILLING_INFO_FORM}
            validationSchema={BILLING_INFO_VALIDATION}
            enableReinitialize
            onSubmit={submit || handleChangeBillingInfo}
          >
            <Form>
              <BillingInfoFields />
              <div className="button-block full-size">
                <Show if={Boolean(saveBtn)}>
                  {saveBtn}
                </Show>
                <Show if={!saveBtn}>
                  <Button
                    loaderId={ON_SUBMIT_BILLING_INFO}
                    htmlType="submit"
                    className="green-button"
                  >
                    {subscription_status === 'canceled' ? 'Confirm' : 'Go to payment'}
                  </Button>
                </Show>
              </div>
            </Form>
          </Formik>
        </Loader>
      </div>
    </div>
  )
};
