import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from 'src/components/router';
import { ROUTES } from 'src/constants';

import { SettingsMenu } from './SettingsMenu';
import { ManageUsers, InviteUser, AdminEditWrapper, SubscriberEditWrapper, SubscriberWrapper } from './ManageUsers';
import { MyDetails } from './MyDetails';
import { ChangePassword } from './ChangePassword';
import { SchoolSettings } from './School';
import { NewsletterBranding } from './NewsletterBranding';
import { SubscriptionSettings, ChoosePlan, BillingInfo, CardSettings } from './Subscription';
import { Invoices, BillingInfoUpdate } from './Invoices';
import {NotFound} from 'src/modules/notFound';

export const Settings = () => {
  return (
    <Switch>
      <PrivateRoute exact path={ROUTES.inviteUser} component={InviteUser}/>
      <PrivateRoute exact path={ROUTES.inviteSubscriber} component={SubscriberWrapper}/>
      <PrivateRoute exact path={ROUTES.subscription} component={SubscriptionSettings}/>
      <PrivateRoute exact path={ROUTES.invoices} component={Invoices}/>
      <PrivateRoute exact path={ROUTES.choosePlan} component={ChoosePlan}/>
      <PrivateRoute exact path={ROUTES.billingInfo} component={BillingInfo}/>
      <PrivateRoute exact path={ROUTES.billingInfoUpdate} component={BillingInfoUpdate}/>
      <PrivateRoute exact path={ROUTES.cardSettings} component={CardSettings}/>
      <PrivateRoute exact path={ROUTES.editUser} component={AdminEditWrapper}/>
      <PrivateRoute exact path={ROUTES.editSubs} component={SubscriberEditWrapper}/>
      <PrivateRoute exact path={ROUTES.manageUsers} component={ManageUsers}/>
      <PrivateRoute exact path={ROUTES.settings} component={SettingsMenu}/>
      <PrivateRoute exact path={ROUTES.myDetails} component={MyDetails}/>
      <PrivateRoute exact path={ROUTES.passwords} component={ChangePassword}/>
      <PrivateRoute exact path={ROUTES.newsletterBranding} component={NewsletterBranding}/>
      <PrivateRoute exact path={ROUTES.schoolSettings} component={SchoolSettings}/>
      <Route component={NotFound} />
    </Switch>
  );
};
