import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  image: string,
  title: string,
  description: string,
  to: string
};

export const SettingsMenuItem = (props: Props) => {
  const {
    image,
    title,
    description,
    to
  } = props;
  return (
    <Link to={to} className="settings-menu-item">
      <div className="icon-block">
        <img src={image} alt="settings-item"/>
      </div>
      <div className='info-block'>
        <h2>{title}</h2>
        <div className="setting-info">{description}</div>
      </div>
    </Link>
  );
};
