import * as React from 'react';
import { Button as ButtonAntd } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { useAppSelector } from 'src/stores';

type ButtonType = {
  loaderId: string
} & ButtonProps;

export const Button = (props: ButtonType) => {
  const { loaderId, ...rest } = props;
  const isLoading = useAppSelector(state => state.loader[loaderId] === 'pending');
  return (
    <ButtonAntd
      {...rest}
      loading={isLoading}
    />
  )
};
