import * as React from 'react';
import { store } from 'src/stores';
import { useAppSelector } from 'src/stores';
import { editorActions } from '../../reducers';

type Props = {
  name: string
};

export const FooterField: React.FC<Props> = () => {
  // const { name } = props;
  const footerContent = useAppSelector(state => state.editor.initialFormValues?.footerContent);

  return (
    <div className="field-container">
      <div>
        <div className="form-input">
          <div className="label-block">
            <label htmlFor="footer-content">Footer</label>
          </div>
          <input name="footer-content" 
          type="text"
          value={footerContent}
          onChange={e => store.dispatch(editorActions.setFooterContent({ content: e.target.value}))} />
        </div>
      </div>
    </div>
  )
};
