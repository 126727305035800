import * as React from 'react';
import { Switch, useHistory, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { mountApp } from 'src/services';

import { SidebarMenu } from 'src/modules/landing/containers/SidebarMenu';
import { AppLayout } from 'src/modules/layout';

import { NewUserRoute, PrivateRoute, PublicRoute } from '../components/router';
import { NewsletterRoutes } from 'src/modules/newsletters';
import { Dashboard } from 'src/modules/dashboard';
import { useAppSelector } from 'src/stores';
import { Settings } from 'src/modules/settings';
import { Editor, PublicUrl } from 'src/modules/editor';
import {NotFound} from 'src/modules/notFound';
import _ from 'lodash';

export const AppRoutes = () => {
  const isNewUser = useAppSelector(state => _.isEmpty(state.auth.school));
  
  React.useEffect(() => {
    mountApp();
  }, [isNewUser]);

  // React.useEffect(() => {
    
  // }, [need_change_password]);

  return (
    <Switch>
      <PrivateRoute exact path={ROUTES.editor} component={Editor} />
      <PrivateRoute exact path={ROUTES.editorPublicUrl} component={PublicUrl} />
      <NewUserRoute>
        <AppLayout>
          <SidebarMenu/>
          <Switch>
            {/* <NewUserRoute path={ROUTES.dashboard} component={Dashboard} /> */}
            <NewUserRoute path={ROUTES.newsletters} component={NewsletterRoutes} />
            <NewUserRoute path={ROUTES.settings} component={Settings} />
            <Route component={NotFound} />
          </Switch>
        </AppLayout>
      </NewUserRoute>
      <Route component={NotFound} />
    </Switch>
  )
};
