import * as Yup from 'yup';
import queryString from 'query-string';

import { history } from 'src/utils';

const getEmail = () => {
  const letter = queryString.parse(history.location.search).email;

  return letter ? letter.toString() : ''
};

export const InitialAuthFormValue = {
  email: getEmail(),
  password: '',
  rememberMe: true
};

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('A valid email address is required').required('A valid email address is required'), //Invalid email format  Field is required
  password: Yup.string().max(50, 'Password is to long').required('Password is required')
});
