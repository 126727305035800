import { createSlice } from '@reduxjs/toolkit';

import {
  setSchoolData
} from '../actions';
import { SchoolStoreType } from '../types';

const initialState = {
  school: {}
} as SchoolStoreType;

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    setSchoolData
  }
});

export const schoolReducer = schoolSlice.reducer;
export const schoolActions = schoolSlice.actions;
