export const API_BASE = '/api/v1';

export const API_LOGIN = `${API_BASE}/authorization/login`;
export const API_LOGOUT = `${API_BASE}/authorization/logout`;

export const API_FORGOT = `${API_BASE}/passwords/forgot`;
export const API_CHANGE_PASSWORD = `${API_BASE}/passwords/change/`;

export const API_INVITE = `${API_BASE}/invites`;
export const API_MANAGERS = `${API_BASE}/invites`;
export const API_SUBSCRIBERS = `${API_BASE}/subscribers`;
export const API_EDIT_SUBSCRIBER = `${API_BASE}/invites`;
export const API_USERS = `${API_BASE}/users`;
export const API_BILLING_INFO_UPDATE = `${API_BASE}/billing_informations`;
export const API_MY_PROFILE = `${API_BASE}/users/my_profile`;
export const API_MY_PROFILE_UPDATE = `${API_BASE}/users/update`;
export const API_PASSWORD_UPDATE = `${API_BASE}/passwords/update`;
export const API_BILLING_INFORMATION = `${API_BASE}/billing_informations/school_info`;
export const API_TRANSACTIONS = `${API_BASE}/transactions`;

export const API_SCHOOLS = `${API_BASE}/schools`;
export const API_SCHOOL = `${API_BASE}/schools/:id`;
export const API_MY_SCHOOL = `${API_BASE}/schools/my`;

export const API_NEWSLETTERS = `${API_BASE}/news_letters`;
export const API_LAST_NEWSLETTER = `${API_BASE}/news_letters/last/created`;
export const API_NEWSLETTERS_UNPUBLISH = `${API_BASE}/news_letters/unpublish`;
export const API_NEWSLETTER_PUBLIC = `${API_BASE}/news`;
export const API_ATTACHMENTS = `${API_BASE}/attachments`;
export const API_PUBLISH = `${API_BASE}/news_letters/publish`;

export const API_CREATE_SUBSCRIPTION = `${API_BASE}/stripe/charges`;
export const API_CANCEL_SUBSCRIPTION = `${API_BASE}/stripe/charges/cancel_subscription`;

// ----
// export const SERVER_URL = 'http://52.188.10.120';
export const SERVER_URL = 'https://eschoolsnews.co.uk';
export const MJML_URL = 'https://api.mjml.io/v1/render';
