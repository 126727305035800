import * as React from 'react';
import { Layout } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { AppFooter } from 'src/modules/layout/containers/AppFooter';
import PublicPageImg from 'src/assets/img/editor/public-page-img.png';
import DocIconPng from 'src/assets/img/editor/doc-icon.png';
import SchoolNewsIcon from 'src/assets/img/editor/school-news-icon.png';

import { ROUTES, SERVER_URL } from 'src/constants';
import { useAppSelector } from 'src/stores';

import { mountPublicUrlPage, getLinkToEditor, afterCopy } from '../handlers';

export const PublicUrl = () => {
  const { title, slug = '' } : any = useAppSelector(state => state.editor.newsletterDefault);

  React.useEffect(() => {
    mountPublicUrlPage();
  }, []);

  const publicLink = slug ? generatePath(`${SERVER_URL}${ROUTES.published}`, { ['slug' as '']: slug }) : SERVER_URL;

  return (
    <div className="newsletter-editor public-page">
      <Layout className="layout">
        <div className="editor-header">
          <img
            className="app-logo"
            src={SchoolNewsIcon}
            alt="SchoolNewsIcon"
          />
          <div className="letter-title">{title}</div>
        </div>
        <Layout.Content>
          <img
            className="public-page-img"
            src={PublicPageImg}
            alt="PublicPageImg"
          />
          <p className="page-title">Congratulations!</p>
          <p className="page-sub-title">Your newsletter has been published</p>
          <p className="page-text-content">To share this newsletter, copy the following URL into your email</p>
          <div className="copy-block">
            <input readOnly value={publicLink}/>
            <CopyToClipboard text={publicLink} onCopy={afterCopy}>
              <img
                src={DocIconPng}
                alt="DocIconPng"
              />
            </CopyToClipboard>
          </div>
          <div className="buttons-block">
            <Link to={getLinkToEditor()} className="app-button red">
              Go back & edit
            </Link>
            <Link to={ROUTES.newsletters} className="app-button green">
              Return to dashboard
            </Link>
          </div>
        </Layout.Content>
        <AppFooter />
      </Layout>
    </div>
  )
};
