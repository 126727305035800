import * as React from 'react';
import { useFormikContext } from 'formik';
import { Select } from 'antd';

import { AnyObject } from 'src/types';

import { onDiscard, onSaveField, onDelete } from '../../handlers';
import { Popconfirm } from 'antd';

type Props = {
  name: string
};

export const Divider: React.FC<Props> = (props) => {
  const { name } = props;

  const { setFieldValue, values } = useFormikContext<AnyObject>();

  React.useEffect(() => {
    if (!values[name]) {
      setFieldValue(name, DIVIDER_OPTIONS[0])
    }
  }, []);

  const handleChange = (id: string | number) => {
    setFieldValue(name, { id });
  };

  return (
    <div className="field-container">
      <div>
        <Select
          className="form-dropdown"
          value={values[name]?.id}
          onChange={handleChange}
        >
          {DIVIDER_OPTIONS.map(option => (
            <Select.Option
              key={option.id}
              value={option.id}
            >
              {option.value}
            </Select.Option>
          ))}
        </Select>
        <Popconfirm placement="topLeft" onConfirm={onDelete(name)} title="Are you sure you want to delete this component?" okText="Yes" cancelText="No">
          <button className="app-button red">
            Delete component
          </button>
        </Popconfirm>
      </div>
      <div className="field-buttons">
        <button className="app-button blue" onClick={onDiscard(setFieldValue)}>
          Discard changes
        </button>
        <button className="app-button green" onClick={onSaveField}>
          Save changes
        </button>
      </div>
    </div>
  )
};

const DIVIDER_OPTIONS = [
  {
    id: 'solid',
    value: <div className="normal-editor-divider"/>
  }, {
    id: 'dashed',
    value: <div className="dash-editor-divider"/>
  }
];
