import React, {useEffect} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { ROUTES } from 'src/constants';

import { useAppSelector } from 'src/stores';
import { toggleModal } from './handlers';

const Modal = ({location}) => {
  const active = useAppSelector(state => state.modal.value);
  const {school} = useAppSelector(state => state.auth);
  const handleCloseModal = () => {
    toggleModal(false);
  };
  const isEnded = () => {
    const isEndedDate = moment((school?.subscription_end_date || moment())).isBefore(moment());
    return isEndedDate;
  };

  useEffect(() => {
    if (
      school?.subscription_status === 'trial' &&
      location.pathname !== ROUTES.subscription &&
      location.pathname !== ROUTES.billingInfo &&
      location.pathname !== ROUTES.billingInfoUpdate &&
      location.pathname !== ROUTES.cardSettings &&
      isEnded()
    ) {
      toggleModal(true);
    }
  }, [location.pathname]);

  return (
    <div className={classNames('modal', { active })}>
      <div className='modal-content'>
        <h2>Your trial is over, please activate a plan to continue using eSchoolsNews</h2>
        <Link to={ROUTES.subscription} onClick={handleCloseModal} className="app-button green">Subscription</Link>
      </div>
    </div>
  );
};

const ModalWithRouter = withRouter(Modal);

export {ModalWithRouter as Modal};
