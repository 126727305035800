export enum NEWSLETTER_STATUSES {
  draft = 'draft',
  unpublished = 'unpublished',
  published = 'published'
}

export const STATUS_TO_LABEL = {
  [NEWSLETTER_STATUSES.draft]: 'Draft',
  [NEWSLETTER_STATUSES.unpublished]: 'unpublished',
  [NEWSLETTER_STATUSES.published]: 'Published'
};
