import * as React from 'react';

import { Footer } from '../../components';
import { changeHeaderType, changeMobileHeaderType } from '../../handlers';

export const Terms = () => {
  React.useEffect(() => {
    changeHeaderType(true);
    changeMobileHeaderType(true);
  }, []);

  return (
    <>
      <div className='terms-container'>
        <h1>TERMS OF SERVICE AGREEMENT</h1>
        <p>
          Welcome to eSchools News, a SaaS (Software as a Service) subscription product offered by eSchools Ltd, a UK-based company. This agreement outlines the terms and conditions that govern your use of the eSchools News product and services, as well as the use of our website. By accessing or using our product, you agree to comply with and be bound by these terms of service. If you do not agree to be bound by these terms, you may not access or use the product.
        </p>
        <h2>1. DEFINITIONS</h2>
        <ul>
          <li>"Company", "we", "us", or "our" refers to eSchools Ltd, the provider of the eSchools News product.</li>
          <li>"You" or "User" refers to any individual or entity that uses the eSchools News product or services.</li>
          <li>"Product" refers to the eSchools News SaaS subscription product and associated services.</li>
          <li>"Website" refers to the website located at <a href='https://www.eschoolsnews.co.uk/'>https://www.eschoolsnews.co.uk/</a>.</li>
          <li>"Content" refers to any data, text, graphics, images, or other material that is made available through the Product or Website.</li>
        </ul>
        <h2>2. USE OF PRODUCT</h2>
        <p>
          2.1 Subscription
          <br />
          The eSchools News Application is offered on an annual subscription basis. By subscribing to the SaaS Application, you agree to pay the applicable subscription fees in accordance with the plan you select. Subscription fees are billed annually in advance, and all fees are non-refundable except as otherwise provided in this Agreement.
        </p>
        <p>
          2.2 Access and Use
          <br />
          Upon purchasing a subscription, you will be granted access to the Product. You agree to use the Product only for lawful purposes and in accordance with these terms of service. You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activity that occurs under your account.
        </p>
        <p>
          2.3 Restrictions
          <br />
          You may not use the Product in any manner that could damage, disable, overburden, or impair our servers or networks, or interfere with any other party's use and enjoyment of the Product. You may not attempt to gain unauthorised access to the Product or any of its features. You may not modify, reproduce, distribute, display, or otherwise exploit any Content without our prior written permission.
        </p>
        <p>
          2.4 Termination
          <br />
          Your subscription will automatically renew on an annual basis, unless you cancel your renewal within the subscription area the application or the Company terminate this Agreement in accordance with the termination clause of this Agreement. You authorise the Company to charge your preferred payment method for the renewal of your subscription, including any applicable taxes, at the then-current subscription rate.
        </p>
        <h2>3. INTELLECTUAL PROPERTY</h2>
        <p>
          The Product and all Content contained therein are protected by copyright, trademark, and other laws of the United Kingdom and other countries. You acknowledge and agree that we own all right, title, and interest in and to the Product and Content, including all intellectual property rights. You may not use any of our trademarks, service marks, or logos without our prior written consent.
        </p>
        <h2>4. DISCLAIMERS AND LIMITATIONS OF LIABILITY</h2>
        <p>
          4.1 Disclaimer of Warranties
          <br />
          The Product and Content are provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the Product will be uninterrupted or error-free, that defects will be corrected, or that the Product is free of viruses or other harmful components.
        </p>
        <p>
          4.2 Limitation of Liability
          <br />
          To the fullest extent permitted by law, in no event will we be liable to you or any third party for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Product, including, without limitation, damages for lost profits, loss of data, or business interruption, even if we have been advised of the possibility of such damages. Our total liability to you for all claims arising out of or in connection with the Product will not exceed the amount paid by you for the subscription during the twelve-month period preceding the claim.
        </p>
        <h2>5. INDEMNIFICATION</h2>
        <p>
          You agree to indemnify, defend, and hold harmless the Company and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from or in connection with your use of the Product, any Content you submit, or your breach of these terms of service.
        </p>
        <h2>6. TERMINATION</h2>
        <p>
          6.1. Termination of the agreement
          <br />
          Either party may terminate this Agreement at any time, with or without cause, upon thirty (30) days written notice to the other party, unless otherwise specified in a separate order form or addendum to this Agreement.
        </p>
        <p>
          6.2. Company Rights
          <br />
          The Company reserves the right, in its sole discretion, to terminate or suspend your access to the eSchools News application and related services, with or without notice, if: (a) you breach any term or condition of this Agreement, (b) your use of the Application poses a security risk or threatens the integrity or availability of the Application, or (c) required by law, regulation, or a governmental or judicial order.
        </p>
        <p>
          6.3. Usage of application
          <br />
          Upon termination of this Agreement, you must cease all use of the Application and destroy all copies of the Application and related documentation in your possession or control.
        </p>
        <p>
          6.4. Rights, licences and obligations
          <br />
          Upon termination of this Agreement, all rights, licences, and obligations of the parties shall cease, except for those that by their nature should survive termination, including but not limited to payment obligations, intellectual property rights, confidentiality obligations, warranty disclaimers, indemnities, and limitations of liability.
        </p>
        <p>
          6.5. Deletion of data
          <br />
          Upon termination of this Agreement, the Company shall deactivate your account and delete all data submitted by you, including but not limited to user content, within thirty (30) days from the date of termination. It is your responsibility to retrieve and backup any data you wish to keep prior to termination.
        </p>
        <p>
          6.6. Refunds
          <br />
          No refund or credit will be provided for any fees paid or prepaid by you in the event of termination, regardless of whether the termination was initiated by you or the Company. You are responsible for paying any outstanding fees or charges owed to the Company up to and including the date of termination.
        </p>
        <h2>7. MODIFICATIONS TO TERMS</h2>
        <p>
          We may modify these terms of service at any time, at our sole discretion. If we make any material changes, we will provide notice on the Website or by email. Your continued use of the Product after any changes indicates your acceptance of the modified terms.
        </p>
        <h2>8. COPYRIGHT INFRINGEMENT</h2>
        <p>
          We respect the intellectual property rights of others and require that our Users do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please notify us immediately. We will promptly investigate any such claim and take appropriate action, including removing or disabling access to the allegedly infringing material. We may also terminate the accounts of repeat infringers. To make a copyright infringement claim, please contact us at <a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>.
        </p>
        <h2>9. GOVERNING LAW</h2>
        <p>
          These terms of service and any dispute arising out of or in connection with these terms shall be governed by and construed in accordance with the laws of the United Kingdom. Any legal action or proceeding arising out of or relating to these terms shall be brought exclusively in the courts of the United Kingdom.
        </p>
        <h2>10. PRIVACY NOTICE</h2>
        <p>
          Your use of the Product is subject to our Privacy Notice, which is incorporated into these terms of service by reference. By using the Product, you consent to our collection, use, and disclosure of your personal information as described in the Privacy Notice.
        </p>
        <h2>11. PROHIBITED ACTIONS</h2>
        <p>
          You agree not to use the Product for any unlawful purpose or in any way that violates these terms of service. Specifically, you agree not to:
        </p>
        <ul style={{listStyle: 'lower-alpha'}}>
          <li>use the Product to transmit or store any Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable;</li>
          <li>use the Product to transmit or store any Content that infringes the intellectual property rights of others;</li>
          <li>use the Product to transmit or store any Content that contains viruses, Trojan horses, worms, or other harmful computer code, files, or programs;</li>
          <li>use the Product to engage in any fraudulent or deceptive activities;</li>
          <li>use the Product to interfere with or disrupt the Product or servers or networks connected to the Product;</li>
          <li>use the Product to collect or harvest any personally identifiable information, including account names and email addresses, from other Users;</li>
          <li>use the Product to send unsolicited messages or promotions or for any other unauthorised commercial purposes;</li>
          <li>use the Product to engage in any activity that is illegal or promotes illegal activity.</li>
        </ul>
        <h2>12. MISCELLANEOUS</h2>
        <p>
          These terms of service, along with our Privacy Policy, constitute the entire agreement between you and the Company regarding the use of the Product. These terms shall be governed by and construed in accordance with the laws of the United Kingdom. Any dispute arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of the United Kingdom. If any provision of these terms of service is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. Our failure to enforce any right or provision under these terms shall not constitute a waiver of such right or provision.
        </p>
        <p>
          If you have any questions about these terms of service, please contact us at <a href='mailto:hello@eschools.co.uk'>hello@eschools.co.uk</a>.
        </p>
        <p>
          By using the Product, you agree to these terms of service.
        </p>
      </div>
      <Footer hideFirstBg />
    </>
  )
};
