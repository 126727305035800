import axios from 'axios';

import { store } from 'src/stores';
import { API_TRANSACTIONS } from 'src/constants';
import { loadingWrapper, requestWrapper } from 'src/utils';

import { GET_INVOICES } from '../constants';
import { invoicesActions } from '../reducers';
import { mountBillingInfo } from './';

const getInvoicesRequest = requestWrapper((year?: string) => {
  return axios.get(API_TRANSACTIONS, { params: { year } });
});

export const getInvoices = loadingWrapper(
  GET_INVOICES,
  async (year?: string) => {
    const { success, response } = await getInvoicesRequest(year);

    if (success) {
      store.dispatch(invoicesActions.setInvoicesData(response.data.transactions.data));
    }
  }
);

export const mountInvoices = loadingWrapper(
  GET_INVOICES,
  async () => {
    await Promise.all([
      mountBillingInfo(),
      getInvoices()
    ]);
  }
);
