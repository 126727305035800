import * as React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';

import { Show } from 'src/components/conditions';
import { ROUTES } from 'src/constants';
import ArrowDown from 'src/assets/img/arrow-down-white.png';

import { onDeleteNewsletter, onUnpublish } from '../handlers';
import { NEWSLETTER_STATUSES } from '../constants';

type Props = {
  letterId: number,
  status: string
};

export const Options: React.FC<Props> = (props) => {
  const { letterId, status } = props;
  const path = generatePath(ROUTES.editor, { id: letterId });

  const UnpublishItem = status === NEWSLETTER_STATUSES.published
    ? (
      <Menu.Item onClick={onUnpublish(letterId)}>
        Unpublish
      </Menu.Item>
    ) : null;

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={path}>
          Edit
        </Link>
      </Menu.Item>
      {UnpublishItem}
      <Menu.Item danger onClick={onDeleteNewsletter(letterId)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="newsletter-options">
      <Dropdown overlay={menu} trigger={['click']}>
        <button className="app-button orange">
          Options
          <img className="arrow-icon" src={ArrowDown} alt="ArrowDown" />
        </button>
      </Dropdown>
    </div>
  )
};
