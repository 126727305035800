import { SERVER_URL } from 'src/constants';
import { getWidthPercents, getWidthPercentsNumber } from '../../utils';

export const ImageGroup = (list: string[]) => (index: number) => `
  <mj-section padding="0" css-class="image-group-container">
    <mj-column padding="0"  width="50%">
        ${getImage(list[0], index)}
        ${getImage(list[2], index)}
    </mj-column>
    <mj-column padding="0"  width="50%">
        ${getImage(list[1], index)}
        ${getImage(list[3], index)}
    </mj-column>
  </mj-section>
`;

const getImageSize = (size: number, index: number) => {
  return (size * getWidthPercentsNumber(index)) / 50
};

// const getImage = (url: string, index: number) => url ? `
//   <mj-image src="${SERVER_URL}${url}" alt="image" width="${getImageSize(125, index)}px"
//                   border-radius="5px"
//                   padding="0"/>
// ` : '';

const getImage = (url: string, index: number) => url ? `
    <mj-text padding="2px">
      <div style="background-image: url(${SERVER_URL}${url}); width: 100%; height: 100px; background-size: cover; background-position: center; padding-top: 65%;"></div>
    </mj-text>
` : '';
