import * as React from 'react';
import { Button } from 'antd';

import HeaderBG from 'src/assets/img/landing/header-bg.svg';
import HeaderMobileBG from 'src/assets/img/mobile/home-bg-blob.svg';
import { BurgerMenu } from 'src/components/icons';

import SimplifiedHeaderBG from 'src/assets/img/landing/simplified-header.svg';
import SimplifiedMobileHeaderBG from 'src/assets/img/mobile/simplified-bg.svg';
import LogoAlt from 'src/assets/img/logo-alt.png';

import { useAppSelector } from 'src/stores';

import { NavigationMenu } from './NavigationMenu';
import { ButtonGroup } from './ButtonGroup';
import { toggleSidebar } from '../../handlers';

export const Header = () => {
  const { simplifiedHeader, simplifiedMobileHeader } = useAppSelector(state => ({
    simplifiedHeader: state.landing.simplifyHeader,
    simplifiedMobileHeader: state.landing.simplifyMobileHeader,
  }));

  const img = simplifiedHeader ? SimplifiedHeaderBG : HeaderBG;
  const imgMobile = simplifiedMobileHeader ? SimplifiedMobileHeaderBG : HeaderMobileBG;

  return (
    <div className="landing-page-header">
      <div className="header-block">
        <img className="logo" src={LogoAlt} alt="logo" />
        <NavigationMenu />
        <Button
          className="burger-menu"
          type="text"
          shape="circle"
          size="small"
          icon={BurgerMenu}
          onClick={toggleSidebar}
        />
        <ButtonGroup />
      </div>
      <img className="header-background desktop" src={img} alt="header-bg" style={simplifiedHeader ? {maxHeight: '140px'} : {}} />
      <img className="header-background mobile" src={imgMobile} alt="header-bg" />
    </div>
  )
};
