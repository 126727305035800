import { AnyObject } from 'src/types';
import { getWidthPercents } from '../../utils';

export const Attendance = (list: AnyObject[]) => (index: number) => {
  return `
  <mj-column width="${getWidthPercents(index)}" padding="4px 0">
      <mj-text color="#444444" padding="0">
          <p style="font-size: 19px; font-weight: bold; margin: 0;">Attendance</p>
      </mj-text>
      ${list.map(item => Item(item))}
  </mj-column>
  `
};

const Item = (item: AnyObject) => `
<mj-text padding="0" font-size="14px">
    <p padding="0" style="margin: 13px; margin-bottom: 5px; margin-left: 4px;">${item.name}</p>
</mj-text>
<mj-text padding="0">
    <span style="width: ${item.percents}%;
    padding:10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    border-radius: ${item.percents == 100 ? '6px' : '6px 0px 0px 6px'};
    display: table-cell;
    color: transparent;
    height: 43px;
    background-color: ${item.background || '#ffc408'}"
    >.</span>
    <span style="width: ${100 - item.percents}%;
    border-radius: 0 6px 6px 0;
    font-size: 14px;
    height: 43px;
    font-weight: lighter;
    color: transparent;
    display: table-cell;
    background-color: #f4f4f9">.</span>
    <span style="display: table-cell; width: 5%; text-align: center; word-break: keep-all; padding: 0 5px; font-size: 14px">
        ${item.percents}%
    </span>
</mj-text>
`;
