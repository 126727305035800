import React, {useState} from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { Button } from 'antd';
import { Button as CustomButton } from 'src/components/button';

import { ROUTES } from 'src/constants';
import AuthBlob from 'src/assets/img/landing/auth-blob.svg';
import ArrowImg from 'src/assets/img/mobile/arrow-in-create-block.svg';
import { WhiteCross } from 'src/components/icons';
import { ON_REGISTER } from '../../../settings/constants'

import { InitialRegisterFormValue, registerValidationSchema } from '../../form';
import { Input } from '../../components';
import { handleToggleAuth, onSubmitRegister } from '../../handlers';

export const Register = () => {
  const { pathname } = useLocation<{ pathname: string }>();
  const token = pathname.replace('/home', '');
  const isToken = token !== ROUTES.howItWorks && token !== ROUTES.pricing && token !== ROUTES.terms && token !== ROUTES.privacyPolicy && token.replace('/', '');
  const [showCheckEmail, setCheckEmail] = useState(false);
  const handleSubmit = (formData: any) => {
    setCheckEmail(true);
    onSubmitRegister(formData);
  };

  return (
    <div className="auth-container">
      <img className="img-blob" src={AuthBlob} alt="AuthBlob" />
      <div className="heading-block">
        <Button
          className="question-button"
          type="text"
          onClick={handleToggleAuth(true, 'login')}
        >
          Already registered?
        </Button>
        <Button
          type="text"
          shape="circle"
          icon={WhiteCross}
          onClick={handleToggleAuth(false)}
        />
      </div>
      {(!showCheckEmail && !isToken) && (
        <h1>Try <span>eSchools News</span> for 3 months</h1>
      )}
      <div className="divider"/>

      <div className="info">{showCheckEmail ? 'Check your email inbox.' : 'Immediate access. No payment details required.'}</div>

      <Formik
        initialValues={InitialRegisterFormValue}
        validationSchema={registerValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Field
            name="email"
            label="ENTER YOUR EMAIL ADDRESS"
            component={Input}
          />

          <CustomButton
            className="log-btn"
            htmlType="submit"
            shape="round"
            loaderId={ON_REGISTER}
          >
            Get access
          </CustomButton>

          <div className="tip-block">
            By signing up, you accept our{' '}
            <Link to={ROUTES.terms} onClick={handleToggleAuth(false)}>Terms of Service</Link> and{' '}
            <Link to={ROUTES.privacyPolicy} onClick={handleToggleAuth(false)}>Privacy Policy</Link>
          </div>

          <img className="arrow-img" src={ArrowImg} alt="ArrowImg" />
        </Form>
      </Formik>
    </div>
  );
};
